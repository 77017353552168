// MapLoaderProvider.tsx
import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";

interface MapLoaderProviderProps {
  children: React.ReactNode;
  apiKey: string;
}

const MapLoaderProvider: React.FC<MapLoaderProviderProps> = ({ children, apiKey }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script", // Ensure this ID remains the same
    googleMapsApiKey: apiKey,
    libraries: ["places"], // Ensure this library list matches exactly where needed
    language: "en", // Set your language preference
    region: "US", // Set your region preference
  });

  if (loadError) return <div>Error loading Google Maps API</div>;
  if (!isLoaded) return <div></div>;

  return <>{children}</>; // Render the children only when loaded
};

export default MapLoaderProvider;
