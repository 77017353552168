import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from "@stripe/react-stripe-js";
import Toast from "../../UiComponent/Toast/Toast";
import Loader from "../../UiComponent/Loader";
import React from "react";

const StripeCheckout = (props: any) => {
  const { handleOtpSend,handleCloseDialog } = props;
  const [activity, setActivity] = React.useState(false)

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    
     event.preventDefault();
 
    if (!stripe || !elements) {
     
      return;
    }
     if (!handleOtpSend) {
      const result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "https://www.v2internal.frankme.com/",
        },
        redirect: "if_required",
      });
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
 
       } else {
        Toast.showSuccess("Bank Added Succesfully");
        handleCloseDialog();
     }
  }

    if (handleOtpSend) {
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "https://www.v2internal.frankme.com/",
        },
        redirect: "if_required",
      });
 
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
 
       } else {
        Toast.showSuccess("Payment Succesfull");
        handleOtpSend();
 
    }

   
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
     <div style={{display:'flex',flexDirection:'column',padding:'20px'}}>
      <PaymentElement />
      <button
        onClick={handleSubmit}
        disabled={!stripe}
        style={{
          fontSize: "16px",
          fontWeight: "500",
          color: "#fff",
          fontFamily: "Raleway-Variable",
          background: "#ee6931",
          border: "none",
          borderRadius: "12px",
          padding: "10px 40px 10px 40px",
          marginTop: "10px",
          float: "right",
          cursor: "pointer",
        }}
      >
        Enregistrer
      </button>
      </div>
    </>
  );
};

export default StripeCheckout;
