import { Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import Header from "../../UiComponent/Header";
import loginImage from "../../assets/images/FrankIcons/login-image.png";
import InputField from "../../UiComponent/TextFeild";
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import {
  ArrowForwardIos,
  East,
  LockOpen,
  MailOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import CustomButton from "../../UiComponent/CustomButton";
import { useNavigate } from "react-router-dom";
import Toast from "../../UiComponent/Toast/Toast";
import apiServices from "../../services/RequestHandler";
import googlePlayBtn from "../../assets/mobileImages/french-google-play.png";
import footer from "../../assets/images/FrankIcons/download-mobile-app-banner@4x.png";

import { saveDataToLocalStorage } from "../../utils/LocalStore.util";
import {
  AppStoreFR,
  FaceBookWhiteIcon,
  FrankIcon,
  InstaWhiteIcon,
  LoginWithAppleIcon,
  LoginWithFacebookIcon,
  LoginWithGoogleIcon,
} from "../../assets/images/FrankIcons";
import { defaultLabelColors } from "../../utils/constants";
import planeImg from "../../assets/images/FrankIcons/planeImage.png";
import CustomFooter from "../../UiComponent/CustomFooter";
import AuthMobileScreen from "./AuthMobileScreen";
import NewHeader from "../../UiComponent/NewHeader";
import { strings } from "../../Store/Localization";
import OTPInput from "react-otp-input";
import PasswordStrengthBar from "react-password-strength-bar";
import ForgotPassword from "./ForgotPassword";
import AuthAction from "./AuthAction";
import { Helmet } from "react-helmet";

function LoginPage(props: any) {
  return (
    <>
      <Helmet>
        <title>Connexion à Frank | Accédez à Votre Compte</title>
        <link rel="canonical" href="https://www.v2internal.frankme.com/login" />
        <meta
          name="description"
          content="Connectez-vous à votre compte Frank pour gérer vos envois, consulter vos annonces et interagir avec la communauté. Rejoignez-nous et profitez de nos services de livraison collaborative."
        />
      </Helmet>
      <div style={{ background: "#F5C9B3" }} className="web-screen">
        <div style={{ zIndex: 1111 }} className="auth-background">
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>

          <Grid xs={12} item>
            <AuthAction />
          </Grid>

          <div
            className="main-container"
            style={{ background: "#f9c7b8", marginTop: "50px" }}
          >
            <CustomFooter />
          </div>
        </div>
      </div>

      <div className="mobile-screen">
        <AuthMobileScreen />
      </div>
    </>
  );
}

export default LoginPage;
