import { CSSProperties, useState } from "react";
import "./loader.css";  
import PropTypes from "prop-types";
import { FadeLoader, RingLoader } from "react-spinners";
import { defaultLabelColors } from "../../utils/constants";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red", 
  background: "transparent",  
};

function Loader(props: any) {
  const { loading } = props;
  const [color, setColor] = useState(defaultLabelColors.main_orange); // You can update the color dynamically if needed

  return (
    loading ? <div className="loader-overlay">
      {" "}
      {/* Transparent overlay */}
      <FadeLoader
        color={color}
        loading={loading}
        cssOverride={override}
        aria-label="Loading Spinner"
        data-testid="loader"
        height={30}
        width={10}
        margin={15}
        
      />
    </div> : null
  );
}

// Default prop values
Loader.defaultProps = {
  loading: true,
};

// PropTypes for validation
Loader.propTypes = {
  loading: PropTypes.any,
};

export default Loader;
