import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetApiParams } from "..";
import apiServices from "../../services/RequestHandler";

export interface profileState {
  profile: any;
  profile_data: any;
  activity: boolean;
  error: string | null;
}

const initialState: profileState = {
  profile: [],
  profile_data: [],
  activity: false,
  error: null,
};

export const getProfileInfo = createAsyncThunk(
  "profile/getProfileInfo",
  async (data: GetApiParams, { rejectWithValue }) => {
    try {
      const response = await apiServices.getFromApi(data.end_point, `me,""`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "API call failed");
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile_data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileInfo.pending, (state) => {
        state.activity = true;
        state.error = null;
      })
      .addCase(getProfileInfo.fulfilled, (state, action) => {
        state.profile_data = action.payload;
        state.activity = false;
      })
      .addCase(getProfileInfo.rejected, (state, action) => {
        state.activity = false;
        state.error = action.payload as string;
      });
  },
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;
