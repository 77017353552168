import React, { useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";

interface GoogleAutocompleteFieldProps {
  apiKey: string;
  placeholder?: string;
  inputValue: string;
  onPlaceSelected: (place: google.maps.places.PlaceResult | null) => void;
  onInputChange: (value: string) => void; // New prop for handling input changes
  styles?: React.CSSProperties;
}

const GoogleAutocompleteField: React.FC<GoogleAutocompleteFieldProps> = ({
  placeholder = "Search for a location",
  onPlaceSelected,
  inputValue,
  onInputChange, // Accepting the new prop
  styles = {
    background: "#F4F3F6",
    width: "100%",
    borderRadius: "16px",
    paddingRight: "10px",
    border: "none",
    fontSize: "16px",
    margin: "10px",
    fontFamily: "Raleway-Italic",
    padding: "10px",
    marginLeft: 9,
    zIndex: 1111,
    color: "#000",
    height: "41px",
  },
}) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry) {
        onPlaceSelected(place);
      } else {
        onPlaceSelected(null);
      }
    }
  };

  return (
    <Autocomplete
      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
      onPlaceChanged={handlePlaceChanged}
    >
      <input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => onInputChange(e.target.value)} // Updating state on input change
        style={styles}
      />
    </Autocomplete>
  );
};

export default GoogleAutocompleteField;
