import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AppRoute from "./routes";
import apiServices from "./services/RequestHandler";
import { getMessageTriger, setUpdateLanguage } from "./Store/MainAppReducer";
import ScrollToTop from "./UiComponent/ScrollToTop";
import { firebaseConfig } from "./utils/constants";
import { getDataFromLocalStorage } from "./utils/LocalStore.util";
import { getDeviceToken, requestPermission } from "./utils/notificationListner";
import { getProfileInfo } from "./Store/ProfileReducer";
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

function App() {
  const dispatch = useDispatch<any>();
  let user = getDataFromLocalStorage("user");

  const checkForPermission = async () => {
    try {
      const isNotifAvailable = "Notification" in window;
      if (isNotifAvailable) {
        const permissionGranted = await requestPermission();
        if (permissionGranted) {
          const token = await getDeviceToken();

          if (token && user?.id) {
            // Only proceed with the API call if a token was successfully retrieved

            await apiServices.postFromApi(
              "c2c/u-token",
              {
                device_token: token,
              },
              ""
            );
            console.log("Token saved successfully.");
          } else if (!token) {
            console.warn("Failed to retrieve device token.");
          }
        } else {
          console.log(
            "Notification permission denied. Please enable notifications in your browser settings to receive updates."
          );
        }
      } else {
        console.log("Notification not supported");
      }
    } catch (error) {
      console.error(
        "An error occurred while checking notification permission:",
        error
      );
    }
  };

  const fetchProfile = async () => {
    if (user) {
      const resp: any = { end_point: "c2c/me" };
      dispatch(getProfileInfo(resp) as any);
    } else {
      return;
    }
  };
  useEffect(() => {
    try {
      const subscribe = () => {
        fetchProfile();
        const isNotifAvailable = "Notification" in window;
        console.log("isNotifAvailable", isNotifAvailable);

        if (isNotifAvailable) {
          checkForPermission();
          const lang = getDataFromLocalStorage("lang") ?? "fr";
          dispatch(setUpdateLanguage(lang));
          onMessage(messaging, (payload: any) => {
            if (payload?.data?.is_message) {
              dispatch(
                getMessageTriger(
                  JSON.stringify({
                    payload,
                    date: new Date().getTime(),
                  })
                )
              );
            }
          });
        }
      };
      console.log("subscribe", subscribe);

      return subscribe;
    } catch (error) {
      console.log(error);
    }
  }, []);

  onMessage(messaging, (payload: any) => {
    if (payload?.data?.is_message) {
      console.log("payload", payload);

      dispatch(
        getMessageTriger(
          JSON.stringify({
            payload,
            date: new Date().getTime(),
          })
        )
      );
    }
  });
  return (
    <>
      <ScrollToTop />
      <ToastContainer limit={1} />
      <AppRoute />
    </>
  );
}

export default App;
