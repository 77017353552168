import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../../UiComponent/CustomButton";
import { Grid, Radio } from "@mui/material";
import {
  defaultLabelColors,
  PACKAGE_SHIPMENT_TYPE,
} from "../../utils/constants";
import GoogleAutocompleteField from "../../UiComponent/GoogleAutocompleteField";
import { CartIcon, HomeIconBlue } from "../../assets/images/FrankIcons";
// import GoogleMaps from "../../UiComponent/GoogleMaps";
import apiServices from "../../services/RequestHandler";
import SelectField from "../../UiComponent/SelectField";
import Toast from "../../UiComponent/Toast/Toast";
import MapForLocations from "../../UiComponent/MapForLocations";
import { handleLocalize } from "../../utils/helper";
import ActionSheet from "actionsheet-react";
import { Close } from "@mui/icons-material";
import ScrollToTop from "../../UiComponent/ScrollToTop";
import { strings } from "../../Store/Localization";
import FrankConstants from "../../utils/FrankConstants";
import { RootState } from "../../Store";
import { useSelector } from "react-redux";
const apiKey = "AIzaSyCHQclQrRUyxhjnamOPm4n1z09r-KWmcdQ";
function PickupInfo(props: any) {
  const ref = useRef<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
    address: string;
  } | null>(null);
  const [selectedValue, setSelectedValue] = React.useState("");
  const { handleUpadateState, handleUpadte } = props;
  const selector = useSelector((state: RootState) => state.mainApp);

  const [inputValue, setInputValue] = useState("");
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      anounces_datails: props?.items,
      pickupInfo: {},
    }
  );
 

  
  useEffect(() => {
    setState({
      anounces_datails: props?.items,
    });
  }, [JSON.stringify(props?.items)]);
   

  useEffect(() => {
    handlePlaceSelected(props?.pickup?.state?.originAddress);
    setSelectedValue(props.pickup?.deliveryType ?? "");
    setSelectedLocation({
      lat: props?.pickup?.location?.coordinates[0],
      lng: props?.pickup?.location?.coordinates[1],
      address: props?.pickup?.address,
    });
    
    setState({
      ...props.pickup?.state,
      anounces_datails: props?.items,
    });

    setInputValue(props?.pickup?.address);
  }, [props?.pickup]);

  const handleOpen = () => {
    if (ref.current) {
      ref.current.open();
    }
  };
  const handleClose = () => {
    if (ref.current) {
      ref.current.close();
      props?.handleClearAllFeils();
    }
  };
  const handlePlaceSelected = async (
    place: google.maps.places.PlaceResult | null
  ) => {
    if (place && place.geometry) {
      const lat = place.geometry.location?.lat() || 0;
      const lng = place.geometry.location?.lng() || 0;
      const address = place.formatted_address || "";
      setSelectedLocation({ lat, lng, address });
      setInputValue(address);

      const pickupLocation = await apiServices.getFromApi(
        `helper/get-address?lat=${lat}&lng=${lng}`,
        ""
      );
      setState({ pickupLocation, originAddress: place });
    } else {
      setSelectedLocation(null);
    }
  };
  const handleSelectForProduct = (name: any, event: any, value: any): void => {
    try {
      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  const handleSavePickupInfo = () => {
    try {
      if (!state?.pickupLocation) {
        Toast.Error("Please select Location");
        return;
      }
      if (!state?.pick_location) {
        Toast.Error("Mentionnez votre option de ramassage");
        return;
      }
      if (!selectedValue) {
        Toast.Error("Mentionnez le type de livraison");
        return;
      }
      const pickup = {
        location: {
          type: "Point",
          coordinates: [
            state?.pickupLocation?.result[0]?.latitude,
            state?.pickupLocation?.result[0]?.longitude,
          ],
        },
        address: state?.pickupLocation?.result[0]?.formattedAddress ?? "",
        digital_code: state?.pickupLocation?.result[0]?.zipcode,
        additional_info: state?.pick_location?.value,
        city: state?.pickupLocation?.result[0]?.city,
        country: state?.pickupLocation?.result[0]?.country,
        deliveryType: selectedValue,
        state,
      };
      handleUpadateState("pickup", pickup);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="web-screen">
        <ScrollToTop />

        <Grid container spacing={2} style={{ paddingBottom: "30px" }}>
          <Grid
            xs={12}
            md={6}
            item
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div style={{ width: "650px", maxWidth: "700px" }}>
              <div
                style={{
                  height: "100px",
                  display: "flex",
                  gap: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    background: defaultLabelColors.main_orange,
                    borderRadius: "32px",
                    width: "30%",
                    height: "10px",
                  }}
                ></div>
                <div
                  style={{
                    background: "#E3CFC7",
                    borderRadius: "32px",
                    width: "30%",
                    height: "10px",
                  }}
                ></div>
                <div
                  style={{
                    background: "#E3CFC7",
                    borderRadius: "32px",
                    width: "30%",
                    height: "10px",
                  }}
                ></div>
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Raleway-Variable",
                  }}
                >
                  <p style={{ display: "flex" }}>
                    {" "}
                    étape{" "}
                    <span
                      style={{
                        fontFamily: "Raleway-Extra-Bold",
                         paddingLeft: 5,
                      }}
                    >
                      {" "}
                      1/3{" "}
                    </span>
                  </p>
                </div>
              </div>
              <h1
                style={{
                  fontFamily: "Raleway-Bold",
                  fontSize: "32px",
                 }}
              >
                {strings?.pickup_address}
              </h1>

              <p style={{ fontFamily: "Raleway-Variable", fontSize: "18" }}>
                Insérez l’adresse de ramassage des colis à envoyer
              </p>
              <div style={{ height: "250px", marginLeft: 10 }}>
                <MapForLocations
                  handleGetLocation={(addressLoc) => {
                    setState({ pickupLocation: addressLoc });
                    setSelectedLocation({
                      lat: addressLoc?.result[0]?.latitude,
                      lng: addressLoc?.result[0]?.longitude,
                      address:
                        addressLoc?.result[0]?.latitude?.formattedAddress,
                    });
                    setInputValue(addressLoc?.result[0]?.formattedAddress);
                    setState({ originAddress: addressLoc?.result[0] });
                  }}
                  location={
                    selectedLocation
                      ? { lat: selectedLocation.lat, lng: selectedLocation.lng }
                      : undefined
                  }
                />
              </div>
              <p
                style={{
                  fontFamily: "Raleway-Bold",
                  fontSize: 22,
                   display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginTop: "20px",
                  marginLeft: 10,

                }}
              >
                <HomeIconBlue
                  fill={defaultLabelColors.main_blue}
                  height={26}
                  width={26}
                />{" "}
                {strings?.pickup_address}
              </p>

              <div style={{ width: "100%" }}>
                <GoogleAutocompleteField
                  apiKey={apiKey}
                  placeholder="Lieu de départ"
                  inputValue={inputValue}
                  onInputChange={setInputValue}
                  onPlaceSelected={handlePlaceSelected}
                  key={"selectedLocation"}
                  styles={{
                    background: "#F4F3F6",
                    width: "96.5%",
                    borderRadius: "16px",
                    border: "none",
                    fontSize: "16px",
                    margin: "10px",
                    fontFamily: "Raleway-Italic",
                    padding: "10px",
                    marginLeft: 9,
                    zIndex: 1111,
                    color: "#000",
                    height: "41px",
                  }}
                />
              </div>

              <div>
                <SelectField
                  placeholder={"Option de ramassage"}
                  textFeildBackground={"#F4F3F6"}
                  handleSelect={(name, event, value) =>
                    handleSelectForProduct(name, event, value)
                  }
                  options={[
                    { label: "Devant chez moi", value: "Devant chez moi" },
                    {
                      label: "Dans la pièce de mon choix",
                      value: "Dans la pièce de mon choix",
                    },
                  ]}
                  value={state?.pick_location}
                  name={"pick_location"}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "100%" }}>
                {/* {PACKAGE_SHIPMENT_TYPE?.map((i: any) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          setSelectedValue(i?.en_type);
                        }}
                        style={{
                          borderRadius: "16px",
                          background: "#F4F3F6",
                          padding: 15,
                          fontFamily: "Raleway-Variable",
                          cursor: "pointer",
                          marginTop: 5,
                          border: `2px solid ${
                            selectedValue === i?.en_type
                              ? defaultLabelColors.main_blue
                              : "#f3f3f3"
                          }`,
                        }}
                      >
                        <div
                          style={{
                             display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily:'Raleway-Extra-Bold'
                          }}
                        >
                          <div style={{ flex: 1 }}>{i?.type}</div>
                          <div>
                            <Radio
                              checked={selectedValue === i?.en_type}
                              onChange={handleChange}
                              value={i?.en_type}
                              name="type"
                              style={{ color: "#000" }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            fontWeight: "700",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontFamily: "Raleway-Italic",
                            }}
                          >
                            {i?.data}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })} */}
                 {FrankConstants?.deliveryTypes(selector?.lang)?.map(
                    (i: any) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              setSelectedValue(i?.key);
                              setState({openJobTypeDialog:false})
                            }}
                            style={{
                              borderRadius: "16px",
                              background: "#F4F3F6",
                              padding: 15,
                              fontFamily: "Raleway-Variable",
                              cursor: "pointer",
                              marginTop: 5,
                              border: `2px solid ${
                                selectedValue === i?.key
                                  ? defaultLabelColors.main_blue
                                  : "#f3f3f3"
                              }`,
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "800",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "100%" }}>{i?.label}</div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Radio
                                  checked={selectedValue === i?.key}
                                  onChange={handleChange}
                                  value={i?.key}
                                  name="type"
                                  style={{
                                    color: "#000",
                                    width: "10px",
                                    paddingRight: 20,
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                fontWeight: "700",
                                fontSize: "14px",
                                marginTop: "10px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontFamily: "Raleway-Italic",
                                }}
                              >
                                {i?.description}
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    }
                  )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                  gap: 10,
                }}
              >
                <CustomButton
                  classesNames={
                    state?.screenStep === 1
                      ? "outlined_button_disable"
                      : "outlined_button"
                  }
                  value={"Retour"}
                  style={{ width: "150px" }}
                  disable={state?.screenStep === 1 ? true : false}
                  onClick={() => {
                    if (props.handleUpadte) {
                      handleUpadte("step", props.step - 1);
                    }
                  }}
                />

                <CustomButton
                  classesNames={"contained_button"}
                  value={"Suivant"}
                  style={{ width: "150px" }}
                  onClick={handleSavePickupInfo}
                />
              </div>
            </div>
          </Grid>
          <Grid
            xs={12}
            md={6}
            item
            style={{
              display: "flex",
              justifyContent: "flex-end ",
              height: "100%",
            }}
          >
            <div
              style={{
                background: defaultLabelColors.white,
                padding: "20px 20px 20px 20px",
                borderRadius: "12px",
                marginTop: "40px",
                width: "400px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  background: defaultLabelColors.white,
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    fontFamily: "Raleway-Bold",
                    fontSize: "22px",
                  }}
                >
                  Récapitulatif
                </div>
                <div>
                  <CartIcon />
                </div>
              </div>
              <div>
                {state?.anounces_datails?.map((i: any) => {
                  return (
                    <div
                      className="card-container"
                      style={{ marginTop: "5px" }}
                    >
                      <img
                        src={i?.images[0].uri}
                        className="card-image"
                        alt="prod-img"
                      />
                      <div className="card-details">
                        <div className="card-title" style={{ fontSize: 17 }}>
                          {handleLocalize({
                            fr: i?.packageType?.item_name_fr,
                            en: i?.packageType?.item_name_en,
                          }) ?? ""}
                        </div>
                        <div className="card-dimensions">
                          {i?.productSize?.size ?? ""}
                        </div>
                      </div>
                      <div className="card-quantity">x{i?.prod_quantity}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        className="mobile-screen"
        style={{ width: "100%", paddingRight: 10 }}
      >
        <Grid container spacing={2} style={{ padding: "0px 10px" }}>
          <Grid xs={12} item style={{ display: "flex" }}>
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "32px",
                      fontWeight: "700",
                    }}
                  >
                    {strings?.pickup_address}
                  </p>

                  <p style={{ fontFamily: "Raleway-Variable", fontSize: "18" }}>
                    Insérez l’adresse de ramassage des objets à déménager
                  </p>
                </div>
                <div style={{ width: "10%", paddingTop: "50px" }}>
                  <div
                    onClick={handleOpen}
                    style={{
                      background: "#fff",
                      borderRadius: 100,
                      width: "50px",
                      height: "50px",
                      padding: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      float: "right",
                    }}
                  >
                    <CartIcon />
                  </div>
                </div>
              </div>

              <div style={{ height: "250px", marginLeft: 10 }}>
                <MapForLocations
                  handleGetLocation={(addressLoc) => {
                    setState({ selectedAddressFromMap: addressLoc });
                    setSelectedLocation({
                      lat: addressLoc?.result[0]?.latitude,
                      lng: addressLoc?.result[0]?.longitude,
                      address:
                        addressLoc?.result[0]?.latitude?.formattedAddress,
                    });
                    setInputValue(addressLoc?.result[0]?.formattedAddress);
                  }}
                  location={
                    selectedLocation
                      ? { lat: selectedLocation.lat, lng: selectedLocation.lng }
                      : undefined
                  }
                />
              </div>
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: 22,
                  fontWeight: 600,
                  display: "flex",
                }}
              >
                <div style={{ flex: 1, width: "80%", padding: "10px" }}>
                  {strings?.pickup_address}
                </div>
              </div>

              <div>
                <GoogleAutocompleteField
                  apiKey={apiKey}
                  placeholder="Enter a location"
                  inputValue={inputValue}
                  onInputChange={setInputValue}
                  onPlaceSelected={handlePlaceSelected}
                  key={"selectedLocation"}
                  styles={{
                    background: "#F4F3F6",
                    width: "100%",
                    borderRadius: "16px",
                    border: "none",
                    fontSize: "16px",
                    margin: "10px",
                    fontFamily: "Raleway-Italic",
                    padding: "10px",
                    marginLeft: 9,
                    zIndex: 1111,
                    color: "#000",
                    height: "61px",
                  }}
                />
              </div>

              <div>
                <SelectField
                  placeholder={"Lieu de prise en charge"}
                  textFeildBackground={"#F4F3F6"}
                  handleSelect={(name, event, value) =>
                    handleSelectForProduct(name, event, value)
                  }
                  options={[
                    { label: "Devant chez moi", value: "Devant chez moi" },
                    {
                      label: "Dans la pièce de mon choix",
                      value: "Dans la pièce de mon choix",
                    },
                  ]}
                  value={state?.pick_location}
                  name={"pick_location"}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "100%" }}>
                {FrankConstants?.deliveryTypes(selector?.lang)?.map((i: any) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          setSelectedValue(i?.key);
                        }}
                        style={{
                          borderRadius: "16px",
                          background: "#F4F3F6",
                          width: "100%",
                          padding: 15,
                          fontFamily: "Raleway-Variable",
                          cursor: "pointer",
                          marginTop: 5,
                          border: `2px solid ${
                            selectedValue === i?.key
                              ? defaultLabelColors.main_blue
                              : "#f3f3f3"
                          }`,
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "800",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>{i?.label}</div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <Radio
                              checked={selectedValue === i?.key}
                              onChange={handleChange}
                              value={i?.key}
                              name="type"
                              style={{
                                color: "#000",
                                width: "10px",
                                paddingRight: 20,
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            fontWeight: "700",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontFamily: "Raleway-Italic",
                            }}
                          >
                            {i?.description}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                  gap: 10,
                }}
              >
                <CustomButton
                  classesNames={
                    state?.screenStep === 1
                      ? "outlined_button_disable"
                      : "outlined_button"
                  }
                  value={"Retour"}
                  style={{ width: "150px" }}
                  disable={state?.screenStep === 1 ? true : false}
                  onClick={() => {
                    if (props.handleUpadte) {
                      handleUpadte("step", props.step - 1);
                    }
                  }}
                />

                <CustomButton
                  classesNames={"contained_button"}
                  value={"Suivant"}
                  style={{ width: "150px" }}
                  onClick={handleSavePickupInfo}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <React.Fragment>
          <ActionSheet
            ref={ref}
            sheetStyle={{
              backgroundColor: "#fff",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              padding: 10,
              height: "60vh",
              zIndex: 11111111,
            }}
            bgStyle={{
              backgroundColor: "rgba(1, 1, 1, 0.8)",
            }}
          >
            <div className="actionSheetContent">
              <Grid xs={12} item>
                <div
                  style={{
                    background: defaultLabelColors.white,
                    padding: "20px 20px 20px 20px",
                    borderRadius: "12px",
                    marginTop: "40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      background: defaultLabelColors.white,
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "22px",
                        fontWeight: "700",
                      }}
                    >
                      Récapitulatif
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Close onClick={handleClose} />
                    </div>
                  </div>
                  <div>
                    {state?.anounces_datails?.map((i: any) => {
                      return (
                        <div
                          className="card-container"
                          style={{ marginTop: "5px", width: 380 }}
                        >
                          <img
                            src={i?.images[0].uri}
                            className="card-image"
                            alt="prod-img"
                          />
                          <div className="card-details">
                            <div
                              className="card-title"
                              style={{ fontSize: 17, width: "100%", flex: 1 }}
                            >
                              {handleLocalize({
                                fr: i?.packageType?.item_name_fr,
                                en: i?.packageType?.item_name_en,
                              }) ?? ""}
                            </div>
                            <div className="card-dimensions">
                              {i?.productSize[0]?.size ?? ""}
                            </div>
                            x
                          </div>
                          <div className="card-quantity">
                            x{i?.prod_quantity}
                          </div>
                          {i?.prod_is_fragile ? (
                            <div
                              className="card-quantity"
                              style={{
                                fontSize: "14px",
                                fontFamily: "Raleway-Variable",
                                fontWeight: "500",
                                marginTop: 5,
                              }}
                            >
                              <span style={{ fontWeight: 600 }}>Fragile</span> :
                              Handle With Care
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
            </div>
          </ActionSheet>
        </React.Fragment>
      </div>
    </>
  );
}

export default PickupInfo;
