import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import downloadappStoreBanner from "../../assets/images/FrankIcons/download-app-mobiles@4x.png";
import Header from "../../UiComponent/Header";
import CustomSelectField from "../../UiComponent/ParcelSizeSelect";

import {
  Add,
  AddPhotoAlternateOutlined,
  Announcement,
  East,
  ExpandMore,
  Remove,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import classNames from "classnames";
import {
  AddAnounceicon,
  AddImageIcon,
  AppStoreFR,
  DeleteGreyIcon,
  FrankIcon,
  ImageGreyIcon,
  PlusPackageitemIcon,
  SendParcelTopIcon,
} from "../../assets/images/FrankIcons";
import bottomBanner from "../../assets/images/FrankIcons/bottom-banner.png";
import footerBanner from "../../assets/images/FrankIcons/footer-banner-anounces.png";
import playSore from "../../assets/images/FrankIcons/PlayStore-btn.png";
import apiServices from "../../services/RequestHandler";
import CustomButton from "../../UiComponent/CustomButton";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import JobCard from "../../UiComponent/JobCard";
import SelectField from "../../UiComponent/SelectField";
import InputField from "../../UiComponent/TextFeild";
import Toast from "../../UiComponent/Toast/Toast";
import {
  defaultLabelColors,
  item_sizes,
  SHIPMENT_TYPE,
  spacing,
} from "../../utils/constants";
import { toBase64 } from "../../utils/helper";
import CustomAnnouceCard from "../../UiComponent/CustomAnnouceCard";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../utils/LocalStore.util";
import { useNavigate } from "react-router-dom";
import NewHeader from "../../UiComponent/NewHeader";
import { log } from "console";
import LoginPage from "../Auth";
import AuthAction from "../Auth/AuthAction";
import { strings } from "../../Store/Localization";
import ParcelSizeSelect from "../../UiComponent/ParcelSizeSelect";

const steps = ["", "", ""];
interface ItemOption {
  label: string;
  value: string | number;
}
function SendParcel(props: any) {
  const { classes } = props;
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedDataValue, setSelectedDataValue] = useState<ItemOption | null>(
    null
  );

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      openLoginDialog: false,
      openJobDialog: false,
      jobScreen: 1,
      items: [],
      announcement_item: {
        images: [],
      },
      // fragile: false,
      isUpdate: false,
    }
  );

  useEffect(() => {
    handleGetRecentTransactions();
    handleGetItemList();
  }, []);

  const handleUpdateState = (state_name: String, data: any) => {
    setState({
      [`state_name`]: state_name,
      data: data,
    });
  };
  const _handleUpadate = () => {
    setState({
      
    })
  }

  const handleSelect = (value: any | null) => {
    setSelectedDataValue(value);
    
    let product_size = item_sizes?.filter(
      (i: any) => i?.weight === value?.weight
    )[0];

    setState({
      item_id: value?.id,
      prod_name: value?.label,
      prod_length: value?.length ?? 0,
      prod_width: value?.width ?? 0,
      prod_height: value?.height ?? 0,
      prod_size: product_size,
    });
  };

  const handleGetItemList = async () => {
    try {
      const items_list = await apiServices.getFromApi("item-lists/all", "");
      return items_list?.data?.map((item: any) => ({
        label: item?.item_name_fr,
        value: item?.id,
        ...item,
      }));
    } catch (error) {
      console.error("Error fetching items:", error);
      return [];
    }
  };
  const handleIncrement = () => {
    setCount((prevCount: any) => prevCount + 1);
  };
  const handleDecrement = () => {
    setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  const handleGetRecentTransactions = async () => {
    try {
      let resp = await apiServices.getFromApi(
        "c2c-shipments/get-recent-by-customer",
        ""
      );
      setState({
        recentTransactions: resp?.data,
      });
    } catch (error) {}
  };
  const handleOpenDialog = () => {
    try {
      setState({ openJobDialog: true });
    } catch (error) {}
  };
  const handleSelectForProduct = (name: any, event: any, value: any): void => {
    try {
      let { items } = state;
      setState({
        [name]: value,
      });
      let product_size = item_sizes?.filter(
        (i: any) => i?.weight === value?.weight
      )[0];

 
      setState({
        item_id: value?.obj?.id,
        prod_name: value?.label,
        prod_length: value?.obj?.length ?? 0,
        prod_width: value?.obj?.width ?? 0,
        prod_height: value?.obj?.height ?? 0,
        prod_size: product_size,
      });
    } catch (error) {}
  };
  const handleLaunchGallery2 = async (
    state_name: string,
    index: number,
    file: any
  ) => {
    try {
      let { announcement_item } = state;
      let item_obj = {
        ...announcement_item,
      };
      item_obj.images[index] = {
        file: file.target.files[0],
        uri: await toBase64(file.target.files[0]),
      };
      setState({
        announcement_item: item_obj,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const EditShipmentItem = (item: any) => {
    try {
      setState({
        openJobDialog: true,
        title: item?.packageType,
        prod_quantity: "",
        prod_length: item?.productLength,
        prod_width: item?.productWidth,
        prod_height: item?.productHeight,
        additional_info: item?.product_additionalInfo,
        isFragile: item?.is_fragile,
        selectedValue: "",
        announcement_item: {
          images: item?.images,
        },
        isUpdate: true,
      });
    } catch (error) {}
  };
  const handleClearAllFeils = () => {
    try {
 
      setCount(1);
      setState({
        openJobDialog: false,
        title: "",
        prod_quantity: "",
        prod_length: "",
        prod_width: "",
        prod_height: "",
        additional_info: "",
        isFragile: false,
        selectedValue: null,
        announcement_item: {
          images: [],
        },
      });
    } catch (error) {}
  };
  const handleAddShipment = () => {
    const { announcement_item } = state;
     const uniqueId = new Date().getTime();
    if (state?.announcement_item?.images?.length < 1) {
      Toast.Error(strings.product_image_error);
      return;
    }
    if (!selectedDataValue) {
      Toast.Error(strings?.item_name_error);
      return;
    }
    if (!selectedValue) {
      Toast.Error(strings?.select_size);
      return;
    }

    let shipment_size = SHIPMENT_TYPE?.filter((i) => i?.size === selectedValue);

    const updatedItem = {
      ...announcement_item,
      id: state?.item_id, // Add unique ID here
      packageType: selectedDataValue?.label,
      productQuantity: count,
      productLength: state?.prod_length,
      productWidth: state?.prod_width,
      productHeight: state?.prod_height,
      product_additionalInfo: state.additional_info ?? "",
      is_fragile: state?.isFragile ?? false,
      productSize: shipment_size,
    };

    state?.items.push(updatedItem);

    handleClearAllFeils();
  };
  const handleUpdateShipment = (id: any, updatedData: any) => {
    const updatedItems = state.items.map((item: any) =>
      item.id === id ? { ...item, ...updatedData } : item
    );

    setState({
      ...state,
      items: updatedItems,
      openJobDialog: false,
      isUpdate: false,
    });
    handleClearAllFeils();
  };
  const handleContinue = () => {
    try {
      const token = getDataFromLocalStorage("token");

      if (!token) {
        setState({ openLoginDialog: true });
        return;
      }

      saveDataToLocalStorage("AnouncesData", state?.items);
      navigate("/checkout-package");
    } catch (error) {}
  };
  const { recentTransactions } = state;
  return (
    <>
      <div className="web-screen">
        <div style={{ backgroundColor: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>

          <div style={{ padding: "0px 100px 0px 100px" }}>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <p className="heading-one">Envoyer un colis</p>
              </Grid>

              <Grid xs={12} md={9.5} item style={{}}>
                <Grid container style={{ columnGap: "20px" }}>
                  <Grid xs={12} item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SendParcelTopIcon />
                      <div style={{ flex: 1 }}>
                        <p className="heading-two" style={{ fontSize: "28px" }}>
                          Faites transporter un colis par un{" "}
                          <span style={{ color: "#3026f1" }}>
                            {" "}
                            particulier !
                          </span>
                        </p>
                        <p
                          className="paragraph-text"
                          style={{ marginTop: "-20px" }}
                        >
                          Gagnez de l'argent en transportant des colis entre
                          particuliers
                        </p>
                      </div>
                    </div>
                  </Grid>

                  <Grid xs={12} md={10} item>
                    <p className="heading-two" style={{ fontSize: "22px" }}>
                      Colis
                    </p>
                  </Grid>

                  <Grid xs={12} md={12} item>
                    <div
                      style={{
                        background: defaultLabelColors.white,
                        borderRadius: "12px",
                        padding: 16,
                      }}
                    >
                      <Grid container spacing={2}>
                        {state?.items?.map((i: any) => {
                          return (
                            <Grid xs={12} md={6} item>
                              {/* <div className="card-container">
                                <DeleteGreyIcon />
                                <img
                                  src={i?.images[0].uri}
                                  className="card-image"
                                />
                                <div className="card-details">
                                  <div className="card-title">
                                    {i?.packageType ?? ""}
                                  </div>
                                  <div className="card-dimensions">
                                    {i?.productLength} x {i?.productHeight} x{" "}
                                    {i?.productWidth}
                                  </div>
                                  <div className="card-images-count">
                                    <ImageGreyIcon /> x 
                                    {i?.images?.length ?? "0"}
                                  </div>
                                </div>
                                <div className="card-quantity">x1</div>
                                <div className="card-size" style={{fontWeight:600,textTransform:'capitalize'}}> {i?.productSize ?? ""} </div>
                              </div> */}
                              <CustomAnnouceCard
                                productImage={i?.images[0].uri}
                                imageQuantity={i?.images?.length}
                                productLength={i?.productLength}
                                productWidth={i?.productWidth}
                                productheight={i?.productHeight}
                                productSize={i?.productSize?.size}
                                productTitle={i?.packageType}
                                shipmentQuantity={i?.productQuantity}
                                handleEdit={() => EditShipmentItem(i)}
                              />
                            </Grid>
                          );
                        })}
                        <Grid
                          xs={12}
                          md={state?.items?.length % 2 ? 6 : 12}
                          item
                          onClick={handleOpenDialog}
                        >
                          <div
                            style={{
                              border: "1px dashed  #3026f1",
                              flexDirection: "column",
                              background: "#ede7f6",
                              cursor: "pointer",
                            }}
                            className="display-flex  card-container "
                          >
                            <AddAnounceicon />

                            <p
                              className="heading-one"
                              style={{
                                fontFamily: "Raleway-Italic",
                                color: defaultLabelColors.main_blue,
                                fontSize: "24px",
                              }}
                            >
                              Ajouter un colis
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid
                    xs={12}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      paddingTop: "20px",
                    }}
                  >
                    <CustomButton
                      classesNames={
                        state?.items?.length < 1
                          ? "outlined_button_disable"
                          : "contained_button"
                      }
                      value={"Suivant"}
                      disable={state?.items?.length < 1 ? true : false}
                      style={{ width: "200px" }}
                      onClick={handleContinue}
                      endIcon={<East />}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12} md={10} item>
                <p className="heading-two" style={{ fontSize: "22px" }}>
                  Transactions récentes
                </p>
              </Grid>

              <Grid
                xs={12}
                md={10}
                item
                style={{ display: "flex", columnGap: "20px" }}
              >
                {recentTransactions?.map((i: any, index: any) => {
                  return (
                    <JobCard
                      jobTitle={i?.shipment_items?.[0]?.prod_name}
                      jobStatus={i?.ship_status}
                      originLocation={`${i?.pickup?.city},${i?.pickup?.country}`}
                      destinationLocation={`${i?.dropoff?.city},${i?.dropoff?.country}`}
                    />
                  );
                })}
              </Grid>

              <Grid xs={12} md={9} item style={{ marginTop: "20px" }}>
                <div style={{ width: "96%" }}>
                  <img
                    src={bottomBanner}
                    style={{
                      width: "100%",
                      top: 0,
                      objectFit: "contain",
                    }}
                    alt="bottom-banner"
                  />
                </div>
              </Grid>

              <Grid xs={12} item>
                <p className="heading-two">Articles</p>
              </Grid>

              <Grid xs={9} item style={{ display: "flex", columnGap: "100px" }}>
                <div style={{ width: "100%" }} className="display-flex">
                  <Grid container spacing={1}>
                    <Grid xs={10} md={4} item>
                      <div className="actu" style={{ color: "#fff" }}>
                        <div className="img-wrapper-blue">
                          <div className="pic-26-1"></div>
                        </div>
                        <div className="text-f9">
                          <div style={{ display: "flex", width: "100%" }}>
                            <div
                              style={{
                                width: "70%",
                                color: "#080626",
                                fontFamily: "Raleway-Variable",
                                fontSize: 18,
                                fontWeight: 700,
                              }}
                            >
                              Du nouveau chez Frank
                              <p
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                  margin: 0,
                                }}
                              >
                                il y a 10 heures
                              </p>{" "}
                            </div>
                            <div style={{ width: "30%", textAlign: "right" }}>
                              <East
                                style={{
                                  background: "#fff",
                                  borderRadius: 100,
                                  color: defaultLabelColors.main_blue,
                                  padding: 5,
                                  fontSize: "16px",
                                }}
                              />{" "}
                            </div>
                          </div>
                          <span
                            className="lorem-ipsum-dolor-fc"
                            style={{ marginBottom: "10px" }}
                          >
                            Lorem ipsum dolor sit amet consectetur. Sapien
                            pharetra adipiscing odio et bibendum duis. Auctor
                            scelerisque tellus tortor euismod...
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={10} md={4} item>
                      <div className="actu">
                        <div className="img-wrapper-blue">
                          <div className="pic-26-2"></div>
                        </div>
                        <div className="text-f9">
                          <div style={{ display: "flex", width: "100%" }}>
                            <div
                              style={{
                                width: "70%",
                                color: "#080626",
                                fontFamily: "Raleway-Variable",
                                fontSize: 18,
                                fontWeight: 700,
                              }}
                            >
                              Du nouveau chez Frank
                              <p
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                  margin: 0,
                                }}
                              >
                                il y a 10 heures
                              </p>{" "}
                            </div>
                            <div style={{ width: "30%", textAlign: "right" }}>
                              <East
                                style={{
                                  background: "#fff",
                                  borderRadius: 100,
                                  color: defaultLabelColors.main_blue,
                                  padding: 5,
                                  fontSize: "16px",
                                }}
                              />{" "}
                            </div>
                          </div>
                          <span
                            className="lorem-ipsum-dolor-fc"
                            style={{ marginBottom: "10px" }}
                          >
                            Lorem ipsum dolor sit amet consectetur. Sapien
                            pharetra adipiscing odio et bibendum duis. Auctor
                            scelerisque tellus tortor euismod...
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={8} md={4} item>
                      <div className="actu">
                        <div className="img-wrapper-blue">
                          <div className="pic-26-3"></div>
                        </div>
                        <div className="text-f9">
                          <div style={{ display: "flex", width: "100%" }}>
                            <div
                              style={{
                                width: "70%",
                                color: "#080626",
                                fontFamily: "Raleway-Variable",
                                fontSize: 18,
                                fontWeight: 700,
                              }}
                            >
                              Du nouveau chez Frank
                              <p
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                  margin: 0,
                                }}
                              >
                                il y a 10 heures
                              </p>{" "}
                            </div>
                            <div style={{ width: "30%", textAlign: "right" }}>
                              <East
                                style={{
                                  background: "#fff",
                                  borderRadius: 100,
                                  color: defaultLabelColors.main_blue,
                                  padding: 5,
                                  fontSize: "16px",
                                }}
                              />{" "}
                            </div>
                          </div>
                          <span
                            className="lorem-ipsum-dolor-fc"
                            style={{ marginBottom: "10px" }}
                          >
                            Lorem ipsum dolor sit amet consectetur. Sapien
                            pharetra adipiscing odio et bibendum duis. Auctor
                            scelerisque tellus tortor euismod...
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid xs={12} item>
                <p className="heading-two">FAQ</p>
              </Grid>

              <Grid xs={9} item>
                <div className="div-frame-1-1-4" style={{ padding: "0px" }}>
                  <div className="div-frame-115" style={{ width: "100%" }}>
                    <div className="div-frame-116">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#f9c9b3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank"
                          >
                            Qu’est-ce que Frank ?
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>

                    <div className="div-line-117"></div>
                    <div className="div-frame-118">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#f9c9b3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank"
                          >
                            Comment fonctionne la livraison ?
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="div-line-11b"></div>
                    <div className="div-frame-11c">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#f9c9b3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank"
                          >
                            Que se passe-t-il si mon colis est perdu / volé ?
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="div-line-11f"></div>
                    <div className="div-frame-120">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#f9c9b3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank"
                          >
                            Y a-t-il une limite de poids ?
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="div-line-123"></div>
                  </div>
                </div>
              </Grid>

              <Grid
                xs={12}
                md={9}
                item
                style={{ marginBottom: "150px", marginTop: "30px" }}
              >
                <div
                  style={{
                    position: "relative",
                    flexDirection: "column",
                    padding: "20px",
                  }}
                  className="display-flex"
                >
                  <img
                    src={footerBanner}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      border: "1px solid #ee6931",
                      borderRadius: "12px",
                    }}
                    alt="footer-banner"
                  />
                  <p
                    className="heading-two"
                    style={{ fontSize: "32px", color: "#fff", zIndex: 111 }}
                  >
                    Être notifié plus rapidement ?
                  </p>
                  <p
                    className="paragraph-text"
                    style={{ textAlign: "center", zIndex: 111 }}
                  >
                    Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                    adipiscing odio et bibendum duis. Auctor scelerisque tellus
                    tortor euismod...
                  </p>

                  <CustomButton
                    value={"Télécharger l’application mobile"}
                    style={{
                      background: defaultLabelColors.main_blue,
                      color: "white",
                      borderRadius: "12px",
                      padding: "10px",
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          {/* Dialog for Shipment Details */}

          <DraggableDialog
            maxWidth={"md"}
            open={state?.openJobDialog}
            handleClose={() => {
              setState({ openJobDialog: false });
            }}
            dialog_title={`Ajouter un colis`}
            classes={classes}
            dialogBody={
              <DialogContent
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
                dividers
              >
                <Box width={"100%"}>
                  <>
                    <Grid
                      container
                      spacing={spacing?.spacing2}
                      style={{ paddingTop: "30px" }}
                    >
                      <Grid xs={12} item></Grid>
                      <Grid
                        xs={12}
                        md={12}
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          flexDirection: "column",
                        }}
                        item
                      >
                        <div
                          style={{
                            background: "#F4F3F6",
                            width: "100%",
                            height: "150px",
                            border: `2px dashed ${defaultLabelColors.main_blue}`,
                            borderRadius: "32px",
                          }}
                          className="display-flex"
                        >
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontFamily: "Raleway-Variable",
                              color: defaultLabelColors.main_blue,
                              fontSize: "20px",
                              fontWeight: "700",
                              flexDirection: "column",
                            }}
                            onClick={() => {
                              let { announcement_item } = state;
                              let item_obj = {
                                ...announcement_item,
                              };
                              if (item_obj?.images?.length > 5) {
                                Toast.Error(
                                  "You cannot add more than 6 pictures"
                                );
                                return;
                              }
                              item_obj?.images?.push({});
                              setState({
                                announcement_item: item_obj,
                              });
                            }}
                          >
                            <AddImageIcon fill={defaultLabelColors.main_blue} />
                            {"Ajouter des photos"}
                          </label>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            {state?.announcement_item?.images?.map(
                              (item: any, index: number) => (
                                <div
                                  key={"PRODUCTIMAGES_" + index + 1}
                                  style={{ marginTop: 10 }}
                                >
                                  <div
                                    style={{
                                      height: 100,
                                      width: 100,
                                      borderRadius: 16,
                                      backgroundColor: "white",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: 10,
                                      flexWrap: "wrap",
                                      display: "flex",
                                    }}
                                  >
                                    {item?.uri || typeof item === "string" ? (
                                      <>
                                        <label
                                          htmlFor={`product_image_back_${
                                            index + 1
                                          }`}
                                        >
                                          <img
                                            src={item?.uri ?? item ?? ""}
                                            alt="prod-img"
                                            style={{
                                              height: "110px",
                                              width: "100%",
                                              borderRadius: 16,
                                              objectFit: "contain",
                                            }}
                                          />
                                        </label>
                                        <input
                                          id={`product_image_back_${index + 1}`}
                                          type="file"
                                          className={"inputfile"}
                                          onChange={(e) =>
                                            handleLaunchGallery2(
                                              "images",
                                              index,
                                              e
                                            )
                                          }
                                        />
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <label
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          htmlFor={`product_image_back_${
                                            index + 1
                                          }`}
                                        >
                                          <AddPhotoAlternateOutlined
                                            style={{
                                              color:
                                                defaultLabelColors.main_blue,
                                              cursor: "pointer",
                                            }}
                                          />
                                        </label>
                                        <input
                                          id={`product_image_back_${index + 1}`}
                                          type="file"
                                          className={"inputfile"}
                                          onChange={(e) => {
                                            handleLaunchGallery2(
                                              "images",
                                              index,
                                              e
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        </div>
                      </Grid>
                      <Grid xs={12} item>
                        <div
                          style={{
                            background: "#F4F3F6",
                            padding: "20px",
                            borderRadius: 16,
                          }}
                        >
                          {/* <ParcelSizeSelect
                            fetchData={handleGetItemList}
                            onSelect={handleSelect}
                            props={<></>}
                          /> */}

                          {/* <CustomSelectField  /> */}
                          {/* <SelectField
                            input_label={"Titre de votre colis"}
                            handleSelect={(name, event, value) =>
                              handleSelectForProduct(name, event, value)
                            }
                            options={state?.items_list?.data?.map((i: any) => {
                              return {
                                label: i?.item_name_fr,
                                value: i?.id,
                                obj: i,
                              };
                            })}
                            value={state?.selected_prod_item}
                            name={"selected_prod_item"}
                          /> */}
                        </div>
                      </Grid>
                      {/* <Grid xs={2} item>
                        <InputField
                          input_type={"number"}
                          input_label={"‎ "}
                          input_value={state?.prod_quantity ?? ""}
                          onchange={onInputChange}
                          input_name={"prod_quantity"}
                          input_style={{
                            background: "#F4F3F6",
                            width: "100%",
                            borderRadius: "16px",
                            border: "none",
                          }}
                        />
                      </Grid> */}

                      <Grid xs={12} item style={{ display: "flex" }}>
                        <div
                          style={{
                            background: "#F4F3F6",
                            padding: "20px",
                            borderRadius: 16,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              fontFamily: "Raleway-Variable",
                              fontWeight: "600",
                              fontSize: "18px",
                            }}
                          >
                            A propos du colis
                          </p>
                          <div style={{ display: "flex", gap: "20px" }}>
                            {SHIPMENT_TYPE?.map((i: any) => {
                              return (
                                <>
                                  <div
                                    style={{
                                      borderRadius: "24px",
                                      background: "#fff",
                                      width: "18.5%",
                                      flexDirection: "column",
                                      padding: 15,
                                      fontFamily: "Raleway-Variable",
                                      gap: "20px",
                                      cursor: "pointer",
                                      border: `3px solid ${
                                        selectedValue === i?.size
                                          ? defaultLabelColors.main_blue
                                          : "#f3f3f3"
                                      }`,
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "800",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ flex: 1 }}>{i?.size}</div>
                                      <div>
                                        <Radio
                                          checked={selectedValue === i?.size}
                                          onChange={handleChange}
                                          value={i?.size}
                                          name="type"
                                          style={{ color: "#000" }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "14px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {i?.weight}
                                      <span style={{ fontWeight: "500" }}>
                                        : Entre une enveloppe et une boite à
                                        chaussure
                                      </span>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </Grid>

                      <Grid xs={12} item>
                        <div
                          style={{
                            background: "#F4F3F6",
                            padding: "20px",
                            borderRadius: 16,
                          }}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onClick={() => {
                                    setState({
                                      isDimestions: !state?.isDimestions,
                                    });
                                  }}
                                  sx={{
                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                      color: "#ee6931",
                                    },
                                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                      {
                                        backgroundColor: "#ee6931",
                                      },
                                  }}
                                />
                              }
                              label="Je connais les dimensions"
                              sx={{
                                ".MuiFormControlLabel-label": {
                                  fontFamily: "Raleway-Variable",
                                  fontSize: 16,
                                  fontWeight: 600,
                                },
                              }}
                            />
                          </FormGroup>
                        </div>
                      </Grid>

                      {state?.isDimestions ? (
                        <>
                          <Grid xs={4} item>
                            <InputField
                              input_type={"number"}
                              input_label={"length"}
                              input_value={state?.prod_length ?? ""}
                              endIicon={"cm"}
                              onchange={onInputChange}
                              input_name={"prod_length"}
                              input_style={{
                                background: "#F4F3F6",
                                width: "100%",
                                borderRadius: "16px",
                                border: "none",
                              }}
                            />
                          </Grid>
                          <Grid xs={4} item>
                            <InputField
                              input_type={"number"}
                              input_label={"width"}
                              input_value={state?.prod_width ?? ""}
                              endIicon={"cm"}
                              onchange={onInputChange}
                              input_name={"prod_width"}
                              input_style={{
                                background: "#F4F3F6",
                                width: "100%",
                                borderRadius: "16px",
                                border: "none",
                              }}
                            />
                          </Grid>
                          <Grid xs={4} item>
                            <InputField
                              input_type={"number"}
                              input_label={"heigth"}
                              input_value={state?.prod_height ?? ""}
                              endIicon={"cm"}
                              onchange={onInputChange}
                              input_name={"prod_height"}
                              input_style={{
                                background: "#F4F3F6",
                                width: "100%",
                                borderRadius: "16px",
                                border: "none",
                                fontSize: "16px",
                              }}
                            />
                          </Grid>
                        </>
                      ) : null}

                      {/* <Grid xs={12} item>
                      <div
                          style={{
                            background: "#F4F3F6",
                            padding: "20px",
                            borderRadius: 16,
                          }}
                        > 
                        <InputField
                          placeHolder={"Information complémentaire"}
                          isMultiLine={true}
                          input_rows={1}
                          input_type={"text"}
                          input_value={state.additional_info ?? ""}
                          onchange={onInputChange}
                          input_name={"additional_info"}
                          input_style={{
                            background: "#F4F3F6",
                            width: "100%",
                            borderRadius: "16px",
                            border: "none",
                            fontSize: "16px",
                            float: "left",
                          }}
                        />
                        </div>
                      </Grid> */}
                      <Grid xs={12} item>
                        <div
                          style={{
                            background: "#F4F3F6",
                            padding: "20px",
                            borderRadius: 16,
                          }}
                        >
                          <FormGroup>
                            <FormControlLabel
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: 22,
                              }}
                              control={
                                <Switch
                                  onClick={() => {
                                    setState({
                                      isFragile: !state?.isFragile,
                                    });
                                  }}
                                  sx={{
                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                      color: "#ee6931",
                                    },
                                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                      {
                                        backgroundColor: "#ee6931",
                                      },
                                  }}
                                />
                              }
                              sx={{
                                ".MuiFormControlLabel-label": {
                                  fontFamily: "Raleway-Variable",
                                  fontSize: 16,
                                  fontWeight: 600,
                                },
                              }}
                              label={"Fragile"}
                            />
                          </FormGroup>
                        </div>
                      </Grid>

                      <Grid xs={12} item>
                        <Box
                          sx={{
                            background: "#F4F3F6",
                            padding: "20px",
                            borderRadius: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "bold",
                              fontFamily: "Raleway-Variable",
                              flex: 1,
                            }}
                          >
                            Combien de colis de cette dimension souhaitez vous
                            envoyer:
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                              onClick={handleDecrement}
                              sx={{
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                width: "40px",
                                height: "40px",
                                backgroundColor: "#fff",
                              }}
                            >
                              <Remove />
                            </IconButton>
                            <Typography
                              variant="h6"
                              sx={{
                                margin: "0 15px",
                                minWidth: "20px",
                                textAlign: "center",
                              }}
                            >
                              {count}
                            </Typography>
                            <IconButton
                              onClick={handleIncrement}
                              sx={{
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                width: "40px",
                                height: "40px",
                                backgroundColor: "#fff",
                              }}
                            >
                              <Add />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                </Box>
              </DialogContent>
            }
            dialogFooter={
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  padding: "20px",
                  gap: "10px",
                }}
              >
                <CustomButton
                  classesNames={"outlined_button"}
                  value={"Annuler"}
                  style={{ width: "200px" }}
                  onClick={handleClearAllFeils}
                />

                {state?.isUpdate ? (
                  <CustomButton
                    classesNames={"contained_button"}
                    value={"Update"}
                    style={{ width: "200px" }}
                    onClick={handleUpdateShipment}
                  />
                ) : (
                  <CustomButton
                    classesNames={"contained_button"}
                    value={"Ajouter"}
                    style={{ width: "200px" }}
                    onClick={handleAddShipment}
                  />
                )}
              </div>
            }
          />

          {/* Dialog for login screen */}

          <DraggableDialog
            maxWidth={"md"}
            open={state?.openLoginDialog}
            handleClose={() => {
              setState({ openLoginDialog: false });
            }}
            classes={classes}
            dialogBody={
              <DialogContent
                style={{
                  display: "flex",
                  background: "#FCE6DD",
                }}
                dividers
              >
                <Grid container spacing={spacing.spacing2}>
                  <Grid xs={12} item>
                    <AuthAction />
                  </Grid>
                </Grid>
              </DialogContent>
            }
          />
        </div>
      </div>
      <div className="mobile-screen"></div>
    </>
  );
}

export default SendParcel;
