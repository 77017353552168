import React from 'react'
import paymentImage from "../../assets/images/paymentImage.png";
import { defaultLabelColors } from '../../utils/constants';


function OfferAcceptedCarForSender(props:any) {
  return (
    <div className="container-for-offer-card" style={{background:defaultLabelColors.main_blue}}> 
         
        <div className="text-for-offer-card">
          <h2 style={{fontWeight:500,fontFamily:'Raleway-Extra-Bold'}}>{"Votre offre a été accepté !"}</h2>
         </div>
      <div className="background-shape-for-offer-card"></div>
    </div>
  )
}

export default OfferAcceptedCarForSender
