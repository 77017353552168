import React, { useEffect } from "react";
import { defaultLabelColors } from "../../utils/constants";
import moment from "moment";
import { numberFormatWithoutCurrency } from "../../utils/helper";
import apiServices from "../../services/RequestHandler";
import { ArrowForwardIos } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import { color_theme } from "./AccepterChat";
import { makeStyles } from "@mui/styles";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import { Box, DialogContent, Grid, Rating, Stack } from "@mui/material";
import { RefrestIcon } from "../../assets/images/FrankIcons";
const useStyles = makeStyles(() => ({
  scrollableArea: {
    height: "600px", // Give the area a height to enable scrolling
    width: "100%",
    overflowY: "auto", // Enable vertical scrolling
    padding: "10px",

    /* Custom scrollbar for WebKit browsers */
    "&::-webkit-scrollbar": {
      width: "12px", // Width of the scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ee6931", // Color of the scrollbar thumb
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5", // Background of the scrollbar track
    },

    /* Custom scrollbar for Firefox */
    scrollbarWidth: "thin", // Thin scrollbar for Firefox
    scrollbarColor: "#ee6931 #f5f5f5", // Thumb and track colors for Firefox
  },
}));

function SenderChat(props: any) {
  const selector = useSelector((state: RootState) => state.mainApp);
  const classes = useStyles();

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      initilalTotalFeedBack:5
    }
  );

  useEffect(() => {}, [selector.lang]);

  useEffect(() => {
    setState({
      chat_details: props?.chat_details,
    });
    console.log("has sent you a proposal of", props);
  }, [JSON.stringify(props?.chat_details)]);

  const handleAcceptDeclineReq = async (req: any, req_val: boolean) => {
    try {
      let obj: any = {
        ship_id: Number(req?.ship_id),
        accepter_id: Number(req?.accepter_id),
        is_agree: Boolean(req_val),
      };
      if (!req_val) {
        obj.id = req.id;
      }
      const resp = await apiServices.postFromApi("c2c-shipment-rates", obj, "");
      if (resp.status === 200 || resp.status === 201) {
        const res = await apiServices.getFromApi(
          `c2c-shipments/${req?.ship_id}`,
          ""
        );
        setState({
          chat_details: res?.data,
        });
        if (props.handleGetShipmentRates) {
          props.handleGetShipmentRates({ ship_id: req?.ship_id }, true);
        }
      }
    } catch (error) {}
  };

  const getUserInfo = async (item: any) => {
    try {
      const resp = await apiServices.getFromApi(
        `c2c/get-user-details/${item?.accepter_id}`,
        ""
      );
      console.log("item", resp);

      if (resp?.status === 200 || resp?.status === 201) {
        let avg_rating_ = resp.data?.ratings2?.reduce((a: any, b: any) => {
          return a + b.rating;
        }, 0);
        let avg_rating = isNaN(avg_rating_ / resp?.data?.ratings2?.length)
          ? 0
          : avg_rating_ / resp?.data?.ratings2?.length;
        setState({
          requesterDetails: resp?.data,
          feedBackDialog: true,
          avg_rating,
        });
      }
    } catch (error) {}
  };

  const { chat_details } = state;
  return (
    <div
      style={{ rowGap: 20, height: "calc(90vh - 200px)", overflowY: "auto" }}
      className={classes?.scrollableArea}
    >
      {(state?.chat_details?.requester ?? state?.chat_details?.requesters)?.map(
        (i: any, index: number) => (
          <div
            key={`REQUESTERS_${index + 1}`}
            style={{
              flexDirection: "row",
              borderRadius: "16px",
              textAlign: "center",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "20px",
              background: i.is_agree === false ? "#d9534f1f" : "lightgrey",
              color: i.is_agree === false ? "#d9534f" : "#000",
            }}
          >
            <p
              style={{
                fontFamily: "Raleway-Bold",
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={() => {
                getUserInfo(i);
              }}
            >
              {i.accepter_name} has sent you a proposal of{" "}
              {numberFormatWithoutCurrency(i?.ship_price)}€
            </p>
            {i?.is_agree === null && (
              <div
                style={{
                  flexDirection: "row",
                  display:
                    state?.chat_details?.ship_status_level > 1
                      ? "none"
                      : "flex",
                  justifyContent: "space-between",
                  gap: 10,
                  alignItems: "center",
                  paddingRight: 10,
                }}
              >
                <div
                  style={{
                    backgroundColor: defaultLabelColors.white, // Pleasant green
                    borderRadius: 7,
                    borderWidth: 1,
                    borderColor: defaultLabelColors.white,
                    padding: "8px 16px",
                    fontFamily: "Raleway-Bold",
                    color: defaultLabelColors.main_blue,
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAcceptDeclineReq(i, false)}
                >
                  Decline
                </div>

                <div
                  style={{
                    backgroundColor: defaultLabelColors.main_blue, // Pleasant green
                    borderRadius: 7,
                    borderWidth: 1,
                    borderColor: defaultLabelColors.main_blue,
                    padding: "8px 16px",
                    fontFamily: "Raleway-Bold",
                    color: "#ffffff",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAcceptDeclineReq(i, true)}
                >
                  Accept
                </div>
              </div>
            )}
          </div>
        )
      )}

      {state?.chat_details?.ship_status_level > 1 && (
        <div
          style={{
            background: "#5cb85c1f",
            color: "#5cb85c",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "16px",
            marginTop: "10px",
            textAlign: "center",
            fontFamily: "Raleway-Bold",
            paddingLeft: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (props.handleSwitChat) {
              props.handleSwitChat();
            }
          }}
        >
          <p>You accepet {chat_details?.acc_name}'s Offer</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowForwardIos />
          </div>
        </div>
      )}

      <DraggableDialog
        maxWidth={"sm"}
        open={state?.feedBackDialog}
        handleClose={() => {
          setState({
            feedBackDialog: false,
            initilalTotalFeedBack:5
          });
        }}
        dialog_title={"Évaluation "}
        dialogBody={
          <DialogContent style={{}} dividers>
            <>
              <Grid container>
                <Grid xs={12} item>
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: 20,
                    }}
                  >
                    <div style={{ display: "flex", paddingBottom: 20 }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Détail de l’expéditeur
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Membre depuis &nbsp;
                        <span style={{ fontWeight: "600" }}>
                          {moment(state?.requesterDetails?.created_at).format(
                            "YYYY"
                          ) ?? "recently"}
                        </span>
                      </div>
                    </div>

                    <div style={{ display: "flex",paddingBottom:20}}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            width: 100,
                            height: 100,
                            borderRadius: 100,
                            overflow: "hidden",
                            display: "flex",

                            boxShadow: 3,
                          }}
                        >
                          <img
                            src={
                              state?.requesterDetails?.profile_pic ??
                              "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                            }
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-end",
                          paddingLeft: 10,
                          paddingBottom:10
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "Raleway-Bold",
                              fontSize: "18px",
                            }}
                          >
                            {state?.requesterDetails?.user_name ??
                              `${state?.requesterDetails?.first_name ?? ""} ${
                                state?.requesterDetails?.surname ?? ""
                              }` ??
                              "Requester Name"}
                          </p>
                          <p
                            style={{
                              margin: 0,
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Stack spacing={1}>
                              <Rating
                                style={{ borderRadius: 12 }}
                                name="half-rating-read"
                                defaultValue={state?.avg_rating ?? 0}
                                precision={0.5}
                                readOnly
                                size="large"
                              />
                            </Stack>

                            <span
                              style={{
                                fontSize: "16px",
                                fontFamily: "Raleway-Semi-Bold",
                                width: "100%",
                              }}
                            >
                              {`( ${
                                state?.requesterDetails?.ratings2?.length ?? "0"
                              } )`}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
<div style={{ padding:20}}>
                    {state?.requesterDetails?.ratings2?.length < 1 || !state?.requesterDetails?.ratings2?.length  ? (
                      <p
                        style={{
                          fontFamily: "Raleway-Semi-Bold",
                          fontSize: 20,
                          textAlign:'center',
                          color:defaultLabelColors.main_blue
                        }}
                      >
                        No Review Received yet!
                      </p>
                    ) : (
                      state?.requesterDetails?.ratings2
                        ?.slice(0, state?.initilalTotalFeedBack)
                        ?.map((i: any) => {
                          return (
                            <div
                              style={{
                                width: "100%",
                                marginTop: 10,
                                borderBottom: `${
                                  state?.allRatings?.ratings2?.length < 2
                                    ? "0px"
                                    : "1px"
                                } solid #E0E0E0`,
                                paddingBottom: 15,
                                display: "flex",
                                gap: 10,
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: 60,
                                  height: 60,
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  boxShadow: 3,
                                  border: "1px solid #E0E0E0",
                                }}
                              >
                                <img
                                  src={
                                    i?.profile_pic ??
                                    "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                                  }
                                  alt="Profile"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </Box>
                              <div style={{ flex: 1, paddingTop: 10 }}>
                                <p
                                  style={{
                                    fontFamily: "Raleway-Semi-Bold",
                                    fontSize: 14,
                                    color: defaultLabelColors.main_blue,
                                    margin: 0,
                                  }}
                                >
                                  {i?.added_by ?? ""}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <Stack spacing={1}>
                                    <Rating
                                      style={{ borderRadius: 12 }}
                                      name="half-rating-read"
                                      defaultValue={i?.rating ?? 2}
                                      precision={0.5}
                                      readOnly
                                      size="small"
                                    />
                                  </Stack>
                                </p>
                                <p
                                  style={{
                                    margin: 0,
                                    paddingTop: 5,
                                    fontSize: "14px",
                                    fontFamily: "Raleway-Italic",
                                  }}
                                >
                                  {i?.feedback ?? ""}
                                </p>
                              </div>

                              <div
                                style={{
                                  fontSize: 14,
                                  color: "#757575",
                                  fontFamily: "Raleway-Italic",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {moment(i?.created_at).fromNow()}{" "}
                              </div>
                            </div>
                          );
                        })
                    )}
                    {state?.requesterDetails?.ratings2?.length > 6 ? (
                      <Grid xs={12} item className="display-flex">
                        <p
                          className="profile-headings display-flex"
                          style={{
                            color: "#3026f1",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setState({
                              initilalTotalFeedBack:
                                state?.initilalTotalFeedBack + 3,
                            });
                          }}
                        >
                          <RefrestIcon /> Plus de reviews
                        </p>
                      </Grid>
                    ) : null}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          </DialogContent>
        }
      />
    </div>
  );
}

export default SenderChat;
