import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { AppStoreFR, FrankIcon } from "../../assets/images/FrankIcons";
import React, { useState } from "react";
 import MenuIcon from "@mui/icons-material/Menu";
import notre_ambition from "../../assets/images/FrankIcons/notre-ambition.png";
import aboutBanner from "../../assets/images/FrankIcons/about-banner.png";
import aboutServices from "../../assets/images/FrankIcons/about-services-section@2x.png";
import { ArrowForward, ExpandMore } from "@mui/icons-material";
import CustomButton from "../../UiComponent/CustomButton";
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import footer from "../../assets/images/FrankIcons/download-mobile-app-banner@4x.png";
import googlePlayBtn from "../../assets/images/FrankIcons/french-google-play.png";
import StepScreen1 from "../../assets/images/FrankIcons/about-steps-1.png";
import StepScreen2 from "../../assets/images/FrankIcons/about-steps-2.png";
import StepScreen3 from "../../assets/images/FrankIcons/about-steps-3.png";
import favicon from "../../assets/images/FrankIcons/frank_favicon.png";

import "./index.css";
 import NewHeader from "../../UiComponent/NewHeader";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { defaultLabelColors, spacing } from "../../utils/constants";

function AboutPage(props: any) {
  const navigate = useNavigate()
  const { classes } = props;
  const [dropdown1Open, setDropdown1Open] = useState(false);
  const [dropdown2Open, setDropdown2Open] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDropdown1 = () => {
    setDropdown1Open(!dropdown1Open);
    if (dropdown2Open) setDropdown2Open(false);
  };

  const toggleDropdown2 = () => {
    setDropdown2Open(!dropdown2Open);
    if (dropdown1Open) setDropdown1Open(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeDropdowns = () => {
    setDropdown1Open(false);
    setDropdown2Open(false);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    closeDropdowns();
  };

  const menuItems = [
    { text: "Accueil", link: "/" },
    { text: "Particulier", link: "/" },
    { text: "Transporteur", link: "/" },
    { text: "Déménagement", link: "/" },
    { text: "Market place", link: "/" },
    { text: "Stockage colis", link: "/" },
    { text: "Blog", link: "/" },
  ];

  return (
    <>
      <Helmet>
        <title>Qui sommes-nous ? | Frank
        </title>
        <link rel="canonical" href="https://frankme.com/" />
        <link rel="icon" href={favicon} sizes="any" />
        <link rel="icon" href={favicon} sizes="192x192" />
        <link rel="icon" href={favicon} sizes="512x512" />
        <meta
          name="description"
          content="Quel est le concept porté par Frank ? Découvrez-en plus sur notre équipe, nos ambitions, notre communauté et nos valeurs."

        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Frank",
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "Customer Service",
              areaServed: "FR",
              availableLanguage: "French",
            },
          })}
        </script>
      </Helmet>
      <div className="web-screen">
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#3026f1",
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop:'30px'
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <NewHeader />
          </Grid>
        </Grid>
        <Grid container spacing={spacing?.spacing2}>
          <Grid xs={12} item style={{ paddingTop: 0 }}>
            <div className="main-div-for-about">
              <div className="home-banner-inner-div-for-about">
                <p className="home-banner-inner-text-for-about">QUI SOMMES-NOUS ?</p>
                <p
                  className="home-banner-inner-text-two-for-about"
                  style={{ marginBottom: -5 }}
                >
                  Frank, c'est un service de livraison alternatif
                </p>
                <p className="home-banner-inner-text-two-for-about">
                  à destination des particuliers comme des professionnels.
                </p>

                <div className="home-banner-inner-text-child-for-about">
                  <div style={{ fontFamily: "Raleway-Italic" }}>
                    <span
                      style={{
                        fontWeight: "700",
                        fontFamily: "Raleway-Italic",
                      }}
                    >
                      {" "}
                      L'idée est née d'un constat simple :{" "}
                    </span>
                    chaque jour, un grand nombre de personnes traversent la
                    France dans des véhicules presque vides. D'un point de vue
                    écologique et logistique, c'est loin d'être optimal !
                  </div>
                  <div style={{ fontFamily: "Raleway-Italic",marginLeft:'30px' }}>
                    <span
                      style={{ fontFamily: "Raleway-Italic", fontWeight: 700 }}
                    >
                      Pourquoi ne pas mettre en relation les personnes pour
                      rentabiliser cet espace et proposer une livraison de colis
                      économique et écologique{" "}
                    </span>
                    ? C'est là que Frank arrive!
                  </div>
                </div>
              </div>
              <img
                src={aboutBanner}
                alt="Web Banner"
                style={{
                  width: "100%",
                  objectFit: "fill",
                  position: "absolute",
                  top: 60,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: -1,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            marginTop: "200px",
          }}
        >
          <div>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "52px",
                color: "#3026f1",
                textAlign: "center",
                fontWeight: "800",
              }}
            >
              NOTRE AMBITION
            </p>

            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "22px",
                color: "#000",
                marginTop: "20px",
              }}
            >
              <span style={{ fontWeight: "600", fontFamily: "Raleway-Italic" }}>
                {" "}
                Notre objectif:
              </span>{" "}
              mettre en relation les personnes qui ont besoin d'envoyer un
              <br /> colis avec celles qui ont de la place dans leur véhicule.
              Le résultat ?
            </p>
          </div>
        </Grid>
        <Grid container spacing={spacing?.spacing2}>
          <div style={{ objectFit: "contain" }}>
            <img
              src={notre_ambition}
              style={{ height: "100%", width: "100%" }}
              alt=""
            />
          </div>
        </Grid>
        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "22px",
                color: "#000",
                textAlign: "center",
              }}
            >
              Pour charger les voitures qui roulent à vide avec tous ces colis,
              il faut un lieu de rassemblement et
              <br /> une communauté.
              <span style={{ fontWeight: "600", fontFamily: "Raleway-Italic" }}>
                {" "}
                Ce lieu : c'est Frank, avec son site internet et son
                application.
              </span>
            </p>
          </div>
        </Grid>
        <Grid
          container
          spacing={spacing?.spacing2}
          style={{ paddingTop: "40px" }}
        >
          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomButton
              value={"CETTE COMMUNAUTÉ : C'EST VOUS !"}
              classesNames={"custom-button"}
              style={{
                background: "#fff",
                color: "#3026f1",
                border: "1px solid #3026f1",
                fontFamily: "Raleway-Variable",
                fontWeight: "900px",
                borderRadius: "8px",
                fontSize: "16px",
                height: "54px",
                boxShadow: "1px 64px 137px -51px rgba(0,0,0,1)",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          className="about-us-bottom-for-about"
          container
          spacing={spacing?.spacing2}
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 200px 0px 200px",
            height: "500px",
          }}
        >
          <Grid xs={12} item>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "32px",
                color: "#fff",
                fontWeight: "800",
              }}
            >
              FRANK, COMMENT ÇA MARCHE ?
            </p>
            <Grid container style={{ marginTop: "70px", padding: "30px" }}>
              <Grid
                xs={12}
                md={6}
                lg={6}
                item
                style={{
                  fontFamily: "Raleway-Italic",
                  color: "#fff",
                  fontSize: "18px",
                  paddingRight: "20px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Italic" }}>
                  De nos jours, envoyer un colis, c'est toute une aventure ! Et
                  plus il est volumineux, plus cela revient cher..
                </p>
                <br />
                <p style={{ fontFamily: "Raleway-Italic" }}>
                  C'est pour cette raison que Frank existe :
                  <span
                    style={{ fontWeight: "600", fontFamily: "Raleway-Italic" }}
                  >
                    {" "}
                    simplifier les envois des particuliers et des professionnels
                  </span>{" "}
                  qui veulent s'y retrouver financièrement.
                </p>
              </Grid>
              <Grid
                xs={12}
                md={6}
                lg={6}
                item
                style={{
                  fontFamily: "Raleway-Italic",
                  color: "#fff",
                  fontSize: "18px",
                  paddingLeft: "20px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Italic" }}>
                  Pour cela, nous avons misé sur une interface simple et
                  transparente. Sur notre site ou sur l'appli, vous planifiez
                  votre envoi, vous définissez le tarif que vous souhaitez et
                  hop, votre annonce est en ligne. Ensuite, la livraison est
                  assurée par un transporteur de la communauté. Facile, non ?
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="section-background-for-about">
          <section className="mstpone-for-about">
            <div className="mst-srcone-for-about">
              <div
                className="display-flex"
                style={{
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "32px",
                    fontFamily: "Raleway-Italic",
                    fontWeight: "800",
                    color: "#3026f1",
                    padding: "30px",
                    width: "1000px",
                    marginTop: "20px",
                    textAlign:'left'
                  }}
                >
                  NOS DIFFÉRENTS SERVICES
                </div>
                <div
                  style={{
                    background: "#fff",
                    width: "80%",
                    height: "2px",
                    marginBottom: "40px",
                  }}
                ></div>
              </div>
              <div className="imgtxt-for-about">
                <img src={StepScreen1} alt="" />
                <div style={{}} className="vous-voyez-div-for-about">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <p
                      className="vous-voulez-for-about"
                      style={{
                        background: "#ee6931",
                        textAlign: "left",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "800",
                        color: "#fff",
                        padding: 10,
                      }}
                    >
                      EXPEDITION
                    </p>
                    <p
                      className="vous-voulez-for-about "
                      style={{ width: "400px", right: "-250px", top: "20px" }}
                    >
                      <br />
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        Publiez votre annonce en indiquant les <br /> détails
                        concernant le colis et sa destination.
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section style={{}} className="mstptwo-for-about">
            <div className="mst-srctwo-for-about">
              <div className="imgtxt-for-about">
                <img src={StepScreen2} />
                <div style={{}} className="vous-voyez-two-div-for-about">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <p
                      className="vous-voulez-two-for-about"
                      style={{
                        background: "#3026f1",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "800",
                        color: "#fff",
                        padding: 10,
                      }}
                    >
                      LE MARCHÉ DE FRANK
                    </p>
                    <p
                      className="vous-voulez-two-for-about"
                      style={{ width: "400px", right: "-250px", top: "20px" }}
                    >
                      <br />
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        Que vous soyez particulier ou professionnel, vous
                        <br /> vendez vos produits de seconde main, mais aussi
                        <br /> vos créations. Nos transporteurs s'occupent
                        <br />
                        ensuite de la livraison.
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section style={{}} className="mstpthree-for-about">
            <div className="mst-srcthree-for-about">
              <div className="imgtxt-for-about">
                <img src={StepScreen3} />
                <div className="vous-voyez-three-div-for-about">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <p
                      className="vous-voulez-three-for-about"
                      style={{
                        background: "#ee6931",
                        textAlign: "left",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "800",
                        color: "#fff",
                        padding: 10,
                      }}
                    >
                      SERVICE DE LIVRAISON
                    </p>
                    <p
                      className="vous-voulez-three-for-about"
                      style={{ width: "400px", right: "-250px", top: "20px" }}
                    >
                      <br />
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        Les entreprises d'e-commerce bénéficient de <br /> notre
                        réseau de transporteurs pour optimiser
                        <br /> leur logistique.
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            paddingLeft: "200px",
            paddingRight: "200px",
            marginTop: "100px",
          }}
        >
          <div
            style={{
              borderTop: "5px dotted #3026f1",
              height: "100px",
              width: "100%",
            }}
          ></div>
        </Grid>
        <Grid
          container
          spacing={spacing?.spacing2}
          style={{ marginBottom: "100px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "200px",
            }}
          >
            <div>
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "42px",
                  fontWeight: "800",
                  color: "#3026f1",
                }}
              >
                POUR QUI ?
              </p>
            </div>

            <div style={{ marginTop: "30px" }}>
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "19px",
                  color: "#000",
                  fontWeight: "500",
                }}
              >
                Notre but, c'est de vous simplifier la vie, ou au moins l'envoi
                de vos colis !
              </p>
            </div>
          </div>
        </Grid>
        <div
          style={{
            background: "#3026f1",
            position: "relative",
            marginTop: "100px",
          }}
        >
          <Grid
            container
            spacing={spacing?.spacing2}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "250px",
            }}
          >
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "40px",
                  position: "absolute",
                  top: "-4%",
                }}
              >
                <div style={{ maxWidth: "300px" }}>
                  <div
                    style={{
                      background: "#f9d9b6",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "250px",
                      padding: "15px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ArrowForward
                          style={{
                            fontSize: "16px",
                            color: "#3026f1",
                          }}
                        />
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        width: "100%",
                        paddingLeft: "25px",
                        // marginTop:-20
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontSize: "22px",
                          fontWeight: "800",
                          paddingTop: "50px",
                          color: "#3026f1",
                          textAlign: "left",
                        }}
                      >
                        Expéditeurs
                      </p>
                    
                     <p style={{marginTop:'20px',fontFamily:'Raleway-Variable',fontSize:'16px',fontWeight:'600    '}}>
                        Vous envoyez vos colis partout en France et profitez de
                        tarifs à toute épreuve
                      </p>
                      </div>
                  </div>
                </div>

                <div style={{ maxWidth: "300px" }}>
                  <div
                    style={{
                      background: "#f9d9b6",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "250px",
                      padding: "15px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ArrowForward
                          style={{
                            fontSize: "16px",
                            color: "#3026f1",
                          }}
                        />
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        width: "100%",
                        paddingLeft: "25px",
                        // marginTop:-20
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontSize: "22px",
                          fontWeight: "800",
                          paddingTop: "50px",
                          color: "#3026f1",
                          textAlign: "left",
                        }}
                      >
                         Transporteurs
                      </p>
                    
                     <p style={{marginTop:'20px',fontFamily:'Raleway-Variable',fontSize:'16px',fontWeight:'600    '}}>
                     Vous rentalilisez l'espace inoccupé de votre véhicule et
                     votre trajet en prenant en charge des colis.
                      </p>
                      </div>
                  </div>
                </div>

                <div style={{ maxWidth: "300px" }}>
                  <div
                    style={{
                      background: "#f9d9b6",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "250px",
                      padding: "15px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ArrowForward
                          style={{
                            fontSize: "16px",
                            color: "#3026f1",
                          }}
                        />
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        width: "100%",
                        paddingLeft: "25px",
                        // marginTop:-20
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontSize: "22px",
                          fontWeight: "800",
                          paddingTop: "50px",
                          color: "#3026f1",
                          textAlign: "left",
                        }}
                      >
                          E-comerçants
                      </p>
                    
                     <p style={{marginTop:'20px',fontFamily:'Raleway-Variable',fontSize:'16px',fontWeight:'600    '}}>
                     Vous faites vos envois de façon éco-responsable et
                     économique.
                      </p>
                      </div>
                  </div>
                </div>
               
               
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={spacing?.spacing2}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                
                paddingLeft:'200px',
              }}
            >
              <div style={{   }}>
                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    fontSize: "42px",
                    color: "#fff",
                    textAlign: "left",
                    fontWeight: "800",
                    marginBottom: "20px",
                  }}
                >
                  NOS VALEURS
                </p>
              </div>
              
            </Grid>
            <Grid xs={12} item style={{padding:"0px 0px",display:'flex',justifyContent:'center',alignItems:'center'  }}>
            <div style={{height:1,background:'#fff',width:'70%'}}>

</div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={spacing?.spacing2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Grid
              xs={12}
              lg={5}
              md={5}
              sm={12}
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "column",
                // paddingLeft: "400px",
              }}
            >
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "32px",
                  fontWeight: "800",
                  color: "#f9d9b6",
                }}
              >
                Connecter les personnes
              </p>

              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  color: "#fff",
                  paddingTop: "50px",
                }}
              >
                Pour nous,
                <span style={{ fontFamily: "Raleway-Italic" }}>
                  "mettre en relation"
                </span>{" "}
                des personnes,
                <br /> ce n'est pas uniquement créer une plateforme
                <br /> collaborative où chacun trouve son compte.
                <br />{" "}
                <span style={{ fontWeight: "800" }}>
                  {" "}
                  C'est aussi rapprocher les gens et créer une
                  <br /> communauté qui partage nos valeurs
                  <br /> d'entraide, d'efficacité et de convivialité
                </span>{" "}
                : <br />
                les Frankies.
              </p>

              <div
                style={{
                  width: "400px",
                  borderTop: "1px solid #fff",
                  marginTop: "50px",
                }}
              ></div>
              <div>
                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    fontSize: "32px",
                    fontWeight: "800",
                    color: "#f9d9b6",
                    paddingTop: "50px",
                    textAlign: "left",
                  }}
                >
                  Efficacité et simplicité
                </p>

                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    color: "#fff",
                    paddingTop: "50px",
                  }}
                >
                  Efficacité et simplicité Nous avons pensé notre
                  <br /> système de la façon la plus simple possible:
                </p>

                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    color: "#fff",
                    paddingTop: "50px",
                    fontWeight: "800",
                    textAlign: "left",
                  }}
                >
                  • Mise en relation
                  <br />
                  • Prise en charge du colis
                  <br />• Réception de la commande
                </p>

                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    color: "#fff",
                    paddingTop: "50px",
                  }}
                >
                  Vous faites des économies, mais vous y<br /> gagnez aussi en
                  sérénité et en sécurité.
                </p>
              </div>
            </Grid>
            <Grid md={2} lg={2} item></Grid>
            <Grid
              xs={12}
              lg={5}
              md={5}
              item
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                // paddingRight: "300px",
              }}
            >
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "32px",
                  fontWeight: "800",
                  color: "#f9d9b6",
                  // paddingRight:'100px'
                }}
              >
                Un impact positif sur
                <br /> l'écologie... et sur votre
                <br /> portefeuille
              </p>

              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#fff",
                  paddingTop: "50px",
                }}
              >
                Saviez-vous qu'un grand nombre de <br /> voitures roulent avec
                une seule personne
                <br /> à l'intérieur ?
              </p>

              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "22px",
                  color: "#fff",
                  paddingTop: "50px",
                }}
              >
                Du côté des camions de transport,
                <br /> beaucoup de trajets se font avec un coffre
                <br /> rempli partiellement, ou carrément vide ! En
                <br /> remplissant ces véhicules vides avec des
                <br /> colis à distribuer, Frank contribue à réduire
                <br /> l'empreinte carbone des envois, mais aussi
                <br /> des trajets. Et avec nos tarifs compétitifs,
                <br /> tout le monde est content!
              </p>
            </Grid>
          </Grid>

          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "1000px",
                marginTop: "60px",
                paddingTop: "60px",
              }}
            ></div>
          </Grid>

          <div className="main-container-for-about">
            <div className="display-flex">
              <div className="div-faq-for-about" style={{width:'1000px'}}>
                <div className="div-frame-114-for-about">
                  <div className="div-frame-115-for-about" style={{ padding: "30px" }}>
                    <span
                      className="span-marketplace-questions-for-about"
                      style={{
                        textAlign: "left",
                        paddingLeft: "16px",
                        paddingBottom: "30px",
                        fontWeight: "900",
                        fontSize: "42px",
                      }}
                    >
                      Des questions ?
                    </span>
                    <div className="div-frame-116-for-about">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#F5C9B3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  fontSize: "28px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank-for-about"
                          >
                            <h3 style={{ fontWeight: "500" }}>
                              Comment fonctionne la livraison collaborative ?
                            </h3>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              width: "100%",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            Grâce à notre plateforme, un particulier peut
                            envoyer un colis qui sera livré par un autre
                            particulier lors d’un de ses trajets personnels. Au
                            cœur de notre activité, la livraison collaborative
                            nous permet de créer une communauté d’entraide en
                            garantissant un service d’envoi peu coûteux et
                            écologique.
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>

                    <div className="div-line-123-for-about"></div>
                    <div className="div-frame-116-for-about">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#F5C9B3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  fontSize: "28px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank-for-about"
                          >
                            <h3 style={{ fontWeight: "500" }}>
                              Comment fonctionne la livraison professionnelle
                              avec Frank ?
                            </h3>
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            <p>
                              {" "}
                              Nous couvrons l’envoi du premier au dernier
                              kilomètre et stockons vos colis dans notre
                              entrepôt en Île-de-France. En fonction de vos
                              besoins, nous proposons plusieurs modes de
                              livraison professionnelle :
                            </p>

                            <p>
                              {" "}
                              • Pick & Pack : récupération des colis à votre
                              adresse, préparation des colis, packaging d’envoi
                              ;{" "}
                            </p>
                            <p>
                              • Livraison totale : nous nous occupons de tout,
                              de la récupération des colis jusqu’à leur dépôt
                              chez le client final ;
                            </p>
                            <p>
                              • Stockage :nous stockons vos colis dans notre
                              entrepôt en Île-de-France, avant leur retrait.
                            </p>

                            <p>
                              {" "}
                              Pour plus d’informations, vous pouvez nous
                              contacter en envoyant un mail à l’adresse :{" "}
                              <a href="mailto:hello@frankme.com">
                                hello@frankme.com
                              </a>
                              .
                            </p>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="div-line-123-for-about"></div>
                    <div className="div-frame-116-for-about">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#F5C9B3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  fontSize: "28px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank-for-about"
                          >
                            <h3 style={{ fontWeight: "500" }}>
                              Puis-je gagner de l’argent avec Frank ?
                            </h3>
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            <p>
                              {" "}
                              Vous êtes transporteur ? En voiture, en train, en
                              trottinette, en péniche ou à pied, quel que soit
                              le mode de transport, vous gagnez de l’argent !{" "}
                            </p>

                            <p>
                              Vous envoyez un colis avec Frank ? Vous ne gagnez
                              pas d’argent, mais vous en économisez à coup sûr !{" "}
                            </p>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="div-line-123-for-about"></div>
                    <div className="div-frame-116-for-about">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#F5C9B3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  fontSize: "28px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank-for-about"
                          >
                            <h3 style={{ fontWeight: "500" }}>
                              Comment fonctionne l’assurance des colis ?
                            </h3>
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            Tout envoi de colis via Frank est assuré à une
                            hauteur de 100 €. Pour vos colis les plus précieux,
                            vous pouvez souscrire à une assurance supplémentaire
                            (HISCOX).
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="div-line-123-for-about"></div>
                    <div className="div-frame-116-for-about">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#F5C9B3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  fontSize: "28px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank-for-about"
                          >
                            <h3 style={{ fontWeight: "500" }}>
                              Comment devenir transporteur ?
                            </h3>
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            <p>
                              {" "}
                              Pour devenir transporteur à nos côtés, rien de
                              plus simple :{" "}
                            </p>

                            <p>
                              {" "}
                              • Vous créez un compte sur le site ou
                              l’application de Frank ;{" "}
                            </p>
                            <p>
                              {" "}
                              • Vous checkez les annonces à côté de vous, ou sur
                              vos trajets ;{" "}
                            </p>
                            <p> • Vous acceptez une offre ;</p>
                            <p>
                              {" "}
                              • Vous remettez le colis au destinataires, qui
                              vous remet un code pour valider la livraison ;{" "}
                            </p>
                            <p>
                              {" "}
                              • Vous recevez le montant de la livraison sur
                              votre porte-monnaie une fois que vous avez
                              renseigné le code.{" "}
                            </p>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="div-line-123-for-about"></div>
                    <div className="div-frame-116-for-about">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#F5C9B3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  fontSize: "28px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank-for-about"
                          >
                            <h3 style={{ fontWeight: "500" }}>
                              En tant que transporteur, comment transférer
                              ...
                            </h3>
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            <p>
                              Sur l’application Frank, il y a un espace
                              porte-monnaie, où vous trouverez le montant total
                              de vos gains. Il suffit de cliquer sur la carte
                              bancaire, puis sur “transférer sur un compte
                              bancaire” et de renseigner les informations
                              demandées. L’argent arrive sur votre compte au
                              bout de quelques jours !
                            </p>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>

                    <div className="div-line-123-for-about"></div>
                    <div className="div-frame-116-for-about">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#F5C9B3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  fontSize: "28px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank-for-about"
                          >
                            <h3 style={{ fontWeight: "500" }}>
                              C’est quoi, le Marché de Frank ?
                            </h3>
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            <p>
                              {" "}
                              Le Marché de Frank, c’est une marketplace pour
                              particuliers et pour professionnels :{" "}
                            </p>
                            <p>
                              {" "}
                              • Les professionnels peuvent y vendre autant de
                              produits qu’ils le veulent. Chaque professionnel a
                              son propre shop au nom de sa marque ;{" "}
                            </p>
                            <p>
                              • Les particuliers peuvent vendre leurs articles
                              de seconde main ou leurs créations personnelles
                              jusqu’à une hauteur de 2 500 euros par mois.
                              Au-delà de ce montant, vous êtes clairement un
                              professionnel !
                            </p>
                            Pour les vendeurs particuliers, la livraison des
                            produits est également assurée par des particuliers.
                            Une fois qu’une personne a acheté un objet, une
                            annonce est automatiquement créée dans l’espace
                            dédié aux transporteurs. Il ne reste plus qu’à
                            attendre que l’un d’entre eux accepte la livraison !
                            Ce système de marketplace collaborative est
                            écologique de A à Z.
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>

                    <div className="div-line-123-for-about"></div>
                    <div className="div-frame-116-for-about">
                      <div style={{ width: "100%" }}>
                        <Accordion
                          elevation={0}
                          style={{
                            border: "0px",
                            background: "#F5C9B3",
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            style={{ border: "none", width: "100%" }}
                            expandIcon={
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  fontSize: "28px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                {" "}
                                <ExpandMore
                                  style={{ textAlign: "right" }}
                                />{" "}
                              </div>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="span-what-is-frank-for-about"
                          >
                            <h3 style={{ fontWeight: "500" }}>
                              Y a-t-il une limite de poids pour la livraison des
                              colis ?
                            </h3>
                          </AccordionSummary>
                          <AccordionDetails style={{ width: "100%" }}>
                            <p>
                              Il n’y a pas de limite de poids chez Frank. Pour
                              un déménagement, vous pouvez prévoir le nombre de
                              personnes dont vous avez besoin en renfort en
                              fonction de la taille des meubles.
                            </p>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>

                    <div className="div-line-123-for-about"></div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>

      
    </>
  );
}

export default AboutPage;
