import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  OverlayView,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import locImage from "../../assets/images/FrankIcons/myLoc.png";
import React, { useCallback, useEffect, useState } from "react";
import apiServices from "../../services/RequestHandler";
import MarkerPinIcon from "../../assets/images/FrankIcons/PinMarkerIcon.png";
import { defaultLabelColors, greyStyle, spacing } from "../../utils/constants";
import DraggableDialog from "../DraggableDialog";
import originPinIcon from "../../assets/images/FrankIcons/originPinIcon.png";
import destinaionPinIcon from "../../assets/images/FrankIcons/destinationPinIcon.png";
import {
  Box,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  HomeIcon,
  MapHomeIcon,
  MapPointerIcon,
  PointerIcon,
} from "../../assets/images/FrankIcons";
import InputField from "../TextFeild";
import { Send } from "@mui/icons-material";
import CustomButton from "../CustomButton";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import Toast from "../Toast/Toast";
import {
  decodePolyline,
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { styled } from "@mui/system";
import { strings } from "../../Store/Localization";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import AuthAction from "../../views/Auth/AuthAction";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove the border
    },
    "&:hover fieldset": {
      border: "none", // Remove hover border
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove focus border
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "52px",
    padding: "12px 14px", // Adjust padding if needed
    textAlign: "center",
    fontFamily: "Raleway-Variable",
    fontWeight: "700",
  },
  "& .MuiInputAdornment-root": {
    fontSize: `52px !important`,
    fontFamily: "Raleway-Variable", // To match the font family
    fontWeight: "700",
  },
});

const containerStyle = {
  width: "100%",
  borderRadius: "32px",
  height: "100%",
  maxHeight: "100%",
};

const options = {
  disableDefaultUI: true, // Disables all default controls
  zoomControl: true, // Enables only the zoom control
  mapTypeControl: false, // Disables the map type control
  streetViewControl: false, // Disables street view control
  fullscreenControl: false, // Disables fullscreen control
  styles: greyStyle,
};
interface MarkerData {
  id: number;
   // Add any other properties related to your job data
}

interface GoogleMapProps {
  handleGetLocation: (data: any) => void;
  markers_data?: Array<any>;
  origin?: {};
  handleGetJobInfo?:(data:MarkerData) => void;
  destination?: {};
  waypoints?: google.maps.DirectionsWaypoint[];
  handleGetRouteLog?: (data?: any) => void;
}

const isLatLngLiteral = (obj: any): obj is google.maps.LatLngLiteral => {
  return obj && typeof obj.lat === "number" && typeof obj.lng === "number";
};

function GoogleMaps({
  handleGetLocation,
  markers_data = [],
  origin,
  destination,
  handleGetJobInfo,
  waypoints = [],
  handleGetRouteLog,
 }: GoogleMapProps) {
  const [address, setAddress] = useState(null);

  const [userLocation, setUserLocation] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [directionsResponse, setDirectionsResponse] =
    useState<google.maps.DirectionsResult | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      openJobDialog: false,
      openLoginDialog: false,
    }
  );

  const center = {
    lat: markerPosition?.lat || 48.85849254092111,
    lng: markerPosition?.lng || 2.3481133545091564,
  };
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const calculateRoute = useCallback(() => {
    if (origin && destination) {
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin,
          destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK && result) {
            setDirectionsResponse(result);
            let decoded_routes = decodePolyline(
              result?.routes[0]?.overview_polyline
            );
            if (handleGetRouteLog) {
              handleGetRouteLog(decoded_routes);
            }
            setError(null);
          } else {
            setError(`Failed to get directions: ${status}`);
            Toast.Error("No Possible route available");
            setDirectionsResponse(null);
          }
        }
      );
    }
  }, [origin, destination]);

  React.useEffect(() => {
    if (origin && destination) {
      calculateRoute();
    }
  }, [origin, destination, calculateRoute]);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const onInputChange = (e: any) => {
    try {
      let { value, name } = e.target;

      const re = /^\d*\.?\d{0,2}$/;

      if (value === "") {
        setState({
          [name]: value,
        });
        return;
      }

      if (
        !re.test(value) ||
        value[0] === " " ||
        parseFloat(value) < 0 ||
        value[0] === "."
      ) {
        return;
      }

      if (value.length > 1 && value[0] === "0" && !value.includes(".")) {
        return;
      }

      if (parseFloat(value) > 10000) {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {
      console.error("Error in onInputChange:", error);
    }
  };
  const handleAcceptJob = async () => {
    try {
      let obj = {
        ship_id: Number(state?.jobDetails?.id),
        ship_price: Number(state?.jobDetails?.ship_price),
      };
      let resp = await apiServices.postFromApi("c2c-shipment-rates", obj, "");

      if (resp?.status === 200 || resp?.status === 201) {
        Toast.showSuccess("Job Accepted Succesfully");
        setState({ openJobDialog: false });
      }
    } catch (error) {}
  };
  const handleNegotiate = async (negotiatedValue?: Number, shipID?: Number) => {
    if (
      state.negotiated_price < state.jobDetails?.ship_price ||
      !state.negotiated_price
    ) {
      Toast.Error("Le montant n’est pas valide");
      return;
    }
    handleSentFirstMessage();
   };  
  const handleJobInfoAfterNego = async () => {
    try {
      let user = getDataFromLocalStorage("user");

      const resp = await apiServices.getFromApi(
        `c2c-shipments/${state?.jobDetails?.id}`,
        ""
      );
      const accepter_details = resp?.data?.requester?.filter(
        (i: any) => i?.accepter_id === user?.id
      );

      accepter_details?.sort((a: any, b: any) =>
        new Date(a?.created_at).getTime() < new Date(b?.created_at).getTime()
          ? -1
          : 1
      );

      if (accepter_details?.[0]?.is_agree === null) {
        setState({
          disableNegotiate: true,
          accepter_details,
        });
      }
      setState({
        jobDetails: resp?.data,
      });
    } catch (error) {}
  };
  const handleSentFirstMessage = async (amount?: number) => {
    let user = getDataFromLocalStorage("user");

    const name = user?.first_name + user?.surname ? ` ${user?.surname}` : "";
    let first_msg: any = {
      message: ``,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: state?.jobDetails,
      sender_id: `${user?.id}`,
      receiver_id: `${state?.jobDetails?.customer_id}`,
      sender_name: name,
    };
    let new_msg: any = {
      message: `${name} has sent you a message. neg_amt=${
        amount ?? state?.negotiated_price
      }`,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: null,
      sender_id: `${user?.id}`,
      receiver_id: `${state?.jobDetails?.customer_id}`,
      sender_name: name,
    };
    if (amount) {
      const resp = await apiServices.postFromApi(
        "/chat/create-first-message",
        {
          first_msg,
          new_msg,
        },
        ""
      );

      return;
    }

    let obj = {
      ship_id: Number(
        state.jobDetails?.id
          ? state.jobDetails?.id
          : state?.jobDetails?.id ?? state?.jobDetails?.ship_id
      ),
      ship_price: Number(state.negotiated_price ?? 0),
    };
    const resp = await apiServices.postFromApi("/c2c-shipment-rates", obj, "");
    if (resp.status === 201) {
      Toast.showSuccess(strings.offer_request_sent);
      handleJobInfoAfterNego()
      const resp = await apiServices.postFromApi(
        "/chat/create-first-message",
        {
          first_msg,
          new_msg,
        },
        ""
      );
      if (resp.status === 201) {
        setState({ messagingScreen: true, disableNegotiateBtn: false });
      }
    } else {
      Toast.Error(
        resp.error?.message ??
          resp?.message ??
          resp?.error?.error ??
          "Internal Server Error"
      );
    }
    setState({ disableNegotiateBtn: false });
  };
  const onMapPress = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      try {
        let resp = await apiServices.getFromApi(
          `helper/get-address?lat=${lat}&lng=${lng}`,
          ""
        );
        setMarkerPosition({
          lat,
          lng,
        });
        setAddress(resp);

        if (handleGetLocation) {
          handleGetLocation(resp);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  // const handleGetJobInfo = async (item: any) => {
  //   try {
  //     let user = getDataFromLocalStorage("user");
  //     if (!user) {
  //       setState({ openLoginDialog: true });
  //       return;
  //     }

  //     const resp = await apiServices.getFromApi(
  //       `c2c-shipments/${item?.id}`,
  //       ""
  //     );

  //     const accepter_details = resp?.data?.requester?.filter(
  //       (i: any) => i?.accepter_id === user?.id
  //     );

  //     accepter_details?.sort((a: any, b: any) =>
  //       new Date(a?.created_at).getTime() < new Date(b?.created_at).getTime()
  //         ? -1
  //         : 1
  //     );

  //     if (accepter_details?.[0]?.is_agree === null) {
  //       setState({
  //         disableNegotiate: true,
  //         accepter_details,
  //       });
  //     }

  //     setState({
  //       jobDetails: resp?.data,
  //       openJobDialog: true,
  //       negotiated_price: resp?.data?.ship_price + 1,
  //     });
  //   } catch (error) {}
  // };

  const CustomMarker = ({ item }: any) => {
    return (
      <div
        className="speech-bubble"
        style={{
          backgroundColor: "white",
          borderRadius: "16px",
          minWidth: "60px",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          padding: "10px ",
        }}
        onClick={() => {
          handleGetJobInfo  && handleGetJobInfo(item);
        }}
      >
        <div style={{ flex: 1 }}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5 7.27771L12 11.9999M12 11.9999L3.49997 7.27771M12 11.9999L12 21.5M21 16.0585V7.94141C21 7.59876 21 7.42744 20.9495 7.27464C20.9049 7.13947 20.8318 7.01539 20.7354 6.9107C20.6263 6.79236 20.4766 6.70916 20.177 6.54276L12.777 2.43164C12.4934 2.27409 12.3516 2.19531 12.2015 2.16442C12.0685 2.13709 11.9315 2.13709 11.7986 2.16442C11.6484 2.19531 11.5066 2.27409 11.223 2.43165L3.82297 6.54276C3.52345 6.70916 3.37369 6.79236 3.26463 6.9107C3.16816 7.01539 3.09515 7.13947 3.05048 7.27465C3 7.42745 3 7.59877 3 7.94141V16.0585C3 16.4012 3 16.5725 3.05048 16.7253C3.09515 16.8605 3.16816 16.9845 3.26463 17.0892C3.37369 17.2076 3.52345 17.2908 3.82297 17.4572L11.223 21.5683C11.5066 21.7258 11.6484 21.8046 11.7986 21.8355C11.9315 21.8628 12.0685 21.8628 12.2015 21.8355C12.3516 21.8046 12.4934 21.7258 12.777 21.5683L20.177 17.4572C20.4766 17.2908 20.6263 17.2076 20.7354 17.0892C20.8318 16.9845 20.9049 16.8605 20.9495 16.7253C21 16.5725 21 16.4012 21 16.0585Z"
              stroke="#ee6931"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 9.5L7.5 4.5"
              stroke="#ee6931"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          style={{
            fontSize: "18px",
            fontWeight: "800",
            fontFamily: "Raleway-Italic",
            color: defaultLabelColors.main_blue,
          }}
        >
          {item?.ship_price}€
        </div>
      </div>
    );
  };
  const handleGetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newLocation = { lat: latitude, lng: longitude };
          setUserLocation(newLocation);
          handleGetLocation(newLocation); // Call the provided function with the new location
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  const handleCloseLoginPage = () => {
    try {
      setState({ openLoginDialog: false });
    } catch (error) {}
  };

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={isLatLngLiteral(origin) ? origin : userLocation || center}
        zoom={14}
        options={options}
        onClick={(e) => onMapPress(e)}
      >
        {markers_data?.length < 1
          ? null
          : markers_data?.map((marker: any, index: number) => {
              const pickup = JSON?.parse(marker?.pickup?.location) ?? {};
              return (
                <>
                  <OverlayView
                    position={{
                      lat: pickup?.coordinates?.[0],
                      lng: pickup?.coordinates?.[1],
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <CustomMarker item={marker} />
                  </OverlayView>
                </>
              );
            })}

        {/* Render directions if the route is successfully calculated */}
        {directionsResponse && (
          <DirectionsRenderer
            directions={directionsResponse}
            options={{
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: defaultLabelColors.main_blue,
                strokeWeight: 4,
              },
            }}
          />
        )}
        {isLatLngLiteral(origin) && (
          <Marker
            position={origin}
            icon={{
              url: originPinIcon,
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
        )}
        {isLatLngLiteral(destination) && (
          <Marker
            position={destination}
            icon={{
              url: destinaionPinIcon,
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
        )}
        {state.show_window && (
          <InfoWindow
            position={{
              lat: state.selected_marker?.pickup?.coordinates?.[0],
              lng: state.selected_marker?.pickup?.coordinates?.[1],
            }}
            onCloseClick={() =>
              setState({ show_window: false, selected_marker: null })
            }
          >
            <div
              style={{
                height: 100,
                width: 100,
                borderRadius: 16,
                background: "cyan",
              }}
            >
              <h1> </h1>
            </div>
          </InfoWindow>
        )}
        {userLocation && (
          <Marker
            position={userLocation}
            icon={{
              url: MarkerPinIcon,
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
        )}

        {}

        <button
          onClick={handleGetUserLocation}
          style={{
            position: "absolute",
            bottom: "120px",
            right: "10px",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
          }}
        >
          <img
            src={locImage}
            alt="My Location"
            style={{ width: "20px", height: "20px" }}
          />
        </button>
      </GoogleMap>

      

      
    </>
  );
}

export default GoogleMaps;
