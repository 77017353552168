import React, { useEffect, useState } from "react";
import CustomFooter from "../../UiComponent/CustomFooter";
import {
  AddProductIcon,
  AnouncementIcon,
  CartIconMarketPlace,
  ConsoleIcon,
  FrankIcon,
  MailOrangeIcon,
  UserOrangeIcon,
} from "../../assets/images/FrankIcons";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { defaultLabelColors, spacing } from "../../utils/constants";
import drawerBoxIcon from "../../assets/mobileImages/drawer-box-icon.png";
import InputField from "../../UiComponent/TextFeild";
import {
  AddBoxOutlined,
  ArrowDropDown,
  Close,
  KeyboardArrowDown,
  Logout,
  Search,
  Settings,
} from "@mui/icons-material";
import frIcon from "../../assets/images/FrankIcons/fr-icon.png";
import enIcon from "../../assets/images/FrankIcons/en-icon.png";
import marketplace_banner_img from "../../assets/images/FrankIcons/services-icon-1.png";
import marketplace_banner_img_two from "../../assets/images/ec20a7fa2b94b62d2a3b0e9fcbfec8543d68719d.png";
import { strings } from "../../Store/Localization";
import apiServices from "../../services/RequestHandler";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixed,
} from "../../utils/helper";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
} from "../../utils/LocalStore.util";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../UiComponent/LoadingComponent";
import Toast from "../../UiComponent/Toast/Toast";
import { RootState } from "../../Store";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateLanguage } from "../../Store/MainAppReducer";
import { Helmet } from "react-helmet";
import HeaderForMarketPlace from "../../UiComponent/HeaderForMarketPlace";
import Loader from "../../UiComponent/Loader";
interface CartItem {
  id: number;
  name: string;
  quantity: number;
}
export const MarketPlace = (props: any) => {
  let user = getDataFromLocalStorage("user");
  const dispatch = useDispatch<any>();
  let userDetails = getDataFromLocalStorage("user");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEN, setAnchorEN] = React.useState<null | HTMLElement>(null);
  const [anchorEM, setAnchorEM] = useState<HTMLButtonElement | null>(null);
  const openForLang = Boolean(anchorEN);
  const selector = useSelector((state: RootState) => state.mainApp);
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isFavorited: false,
    }
  );
  const handleClickForLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEN(event.currentTarget);
  };
  const handleCloseForLang = () => {
    setAnchorEN(null);
  };
  const [cartItems, setCartItems] = useState<CartItem[]>([
    { id: 1, name: "Product 1", quantity: 2 },
    { id: 2, name: "Product 2", quantity: 1 },
  ]);
  const handleClickforCart = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEM(event.currentTarget);
  };
  const handleCloseforCart = () => {
    setAnchorEM(null);
  };
  const openForCart = Boolean(anchorEM);
  const id = openForCart ? "cart-popover" : undefined;
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      navigate("/");
      Toast.showSuccess("Logout Succesfully");
    } catch (error) {}
  };
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };
  useEffect(() => {
    handleGetAllCategories();
    handleGetProducts();
  }, []);
  const handleOpenProduct = (id: any) => {
    try {
      navigate(`/product/${id}`);
    } catch (error) {}
  };
  const handleGetProducts = async () => {
    try {
      let user = getDataFromLocalStorage("user");
      let obj = {
        user_id: user?.id,
      };
      let resp = await apiServices.postFromApi(
        "c2c-product-masters/product-home",
        obj,
        ""
      );

      setState({
        latestProducts: resp?.data?.latest_items,
        topTrendingProducts: resp?.data?.top_trending_items,
        mostViewedProducts: resp?.data?.most_recent_view_items,
      });
    } catch (error) {}
  };
  const getProductsByCategory = (cat_id: any) => {
    try {
      navigate(`/all-products/${Number(cat_id)}`);
    } catch (error) {}
  };

  function buildNestedStructure(data: any) {
    const lookup: any = {};
    const rootNodes: any = [];

    data.forEach((item: any) => {
      lookup[item.id] = { ...item, childern: [] };
    });
    data.forEach((item: any) => {
      if (item.parent_id) {
        lookup[item.parent_id]?.childern.push(lookup[item.id]);
      } else {
        rootNodes.push(lookup[item.id]);
      }
    });

    setState({ parentCategories: rootNodes });
    return rootNodes;
  }
  const handleGetAllCategories = async () => {
    try {
      setState({ activity: true });
      let resp = await apiServices.getFromApi("c2c-categories", "");
      if (resp?.status === 201 || resp?.status === 200) {
        buildNestedStructure(resp?.data);
        console.log(
          "buildNestedStructure(resp?.data);",
          buildNestedStructure(resp?.data)
        );
        setState({ activity: false });
      }
    } catch (error) {}
  };

  return  (
     
    <>
      <Helmet>
        <title>
          Marketplace | Trouvez des Offres de Livraison et Services
          Collaboratifs
        </title>
        <link
          rel="canonical"
          href="https://www.v2internal.frankme.com/marketplace"
        />
        <meta
          name="description"
          content="Explorez notre marketplace pour découvrir des offres de livraison entre particuliers et services collaboratifs. Trouvez ce dont vous avez besoin pour expédier vos colis facilement."
        />
      </Helmet>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3" }}>
        <Loader loading={state?.activity} />

          <Grid container>
            <Grid xs={12} item>
              <HeaderForMarketPlace />
            </Grid>
          </Grid>

          <div style={{ padding: "0px 50px 0px 50px" }}>
            {/* <Grid container spacing={spacing.spacing2}>
            <Grid xs={12} item>
              <p className="page-title-one">{strings?.frank_shop_2}</p>
            </Grid>
            <Grid xs={4} item style={{ display: "flex" }}>
              <InputField
                input_type={"text"}
                input_value={state?.searchedText ?? ""}
                onchange={onInputChange}
                startIcon={<Search />}
                placeHolder={"Rechercher votre produit"}
                input_name={"searchedText"}
                input_style={{
                  background: "#fff",
                  borderRadius: "16px",
                  border: "none",
                  fontSize: `64px !important`,
                  width: "100%",
                }}
              />
            </Grid>
            <Grid xs={3} item style={{ display: "flex" }}>
              <InputField
                input_type={"text"}
                input_value={state?.location ?? ""}
                onchange={onInputChange}
                startIcon={<PushPin />}
                placeHolder={"Localisation"}
                input_name={"location"}
                input_style={{
                  background: "#fff",
                  borderRadius: "16px",
                  border: "none",
                  fontSize: `64px !important`,
                  width: "100%",
                }}
              />
            </Grid>
            <Grid xs={3} item style={{ display: "flex" }}>
              <InputField
                input_type={"text"}
                input_value={state?.prix ?? ""}
                onchange={onInputChange}
                startIcon={<LocalOffer />}
                placeHolder={"Prix"}
                input_name={"prix"}
                input_style={{
                  background: "#fff",
                  borderRadius: "16px",
                  border: "none",
                  fontSize: `64px !important`,
                  width: "100%",
                }}
              />
            </Grid>
          </Grid> */}
            <Grid container style={{ display: "none" }}>
              <Grid xs={12} item>
                <h1>MarketPlace</h1>
              </Grid>
            </Grid>

            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} item>
                <h2 className="page-title-two">
                  Nos catégories les plus populaires
                </h2>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "40px",
                display: "flex",
              }}
            >
              <ScrollContainer
                style={{
                  width: "100%",
                  height: "auto",
                  padding: "10px 0",
                  gap: "50px",
                  cursor: "pointer",
                }}
                className="scroll-container"
                horizontal={true}
                activationDistance={70}
              >
                {state?.parentCategories?.map((i: any, idx: any) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={3}
                      style={{ paddingLeft: "10px", width: "100%" }}
                      key={idx}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "32px",
                          padding: "24px",
                          textAlign: "center",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          marginRight: "16px", // Space between each item
                          width: "100%",
                          minWidth: "310px",
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mb={2}
                          onClick={() => {
                            getProductsByCategory(i?.id);
                          }}
                        >
                          <Box
                            component="span"
                            sx={{
                              display: "inline-block",
                              backgroundColor: "#fff",
                              padding: "8px",
                              borderRadius: "8px",
                            }}
                          >
                            <ConsoleIcon />
                          </Box>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            ml={1}
                            color="#4D1A18"
                          >
                            {handleLocalize({
                              en: i?.category_name?.en,
                              fr: i?.category_name?.fr,
                            }) ?? "N/A"}
                          </Typography>
                        </Box>

                        {/* Category Buttons */}
                        <Grid container spacing={2}>
                          {i?.childern
                            ?.slice(0, 4)
                            .map((category: any, index: any) => (
                              <Grid item xs={6} key={index}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    width: "100%",
                                    backgroundColor: "#F5F5F5",
                                    color: "#000",
                                    textTransform: "none",
                                    fontWeight: "600",
                                    fontFamily: "Raleway-Variable",
                                    borderRadius: "16px",
                                    boxShadow: "none",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    "&:hover": {
                                      backgroundColor: "#F5F5F5",
                                    },
                                  }}
                                  onClick={() => {
                                    getProductsByCategory(category?.id);
                                  }}
                                >
                                  {handleLocalize({
                                    en: category?.category_name?.en,
                                    fr: category?.category_name?.fr,
                                  }) ?? "N/A"}
                                </Button>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    </Grid>
                  );
                })}
              </ScrollContainer>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <Grid
                xs={12}
                md={5.8}
                item
                style={{
                  background: defaultLabelColors.main_orange,
                  borderRadius: "32px",
                  padding: "32px",
                  overflow: "hidden",
                  width: "98%",
                }}
              >
                <div style={{ display: "flex", height: "120px" }}>
                  <div
                    style={{
                      flex: 1,
                      fontSize: "26px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      cursor: "pointer",
                    }}
                  >
                    <AddProductIcon /> Vendre un article
                  </div>
                  <div style={{ width: "200px" }}>
                    <img
                      src={marketplace_banner_img}
                      alt="marketplace-banner"
                      style={{}}
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                xs={12}
                md={5.8}
                item
                style={{
                  background: defaultLabelColors.main_blue,
                  borderRadius: "32px",
                  padding: "32px",
                  overflow: "hidden",
                  width: "98%",
                }}
              >
                <div style={{ display: "flex", height: "120px" }}>
                  <div
                    style={{
                      flex: 1,
                      fontSize: "26px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Lorem ipsum <br />
                    dolar sit amet.
                  </div>
                  <div>
                    <img
                      src={marketplace_banner_img_two}
                      alt="marketplace-banner-two"
                      style={{ width: "200px" }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ paddingBottom: "10px" }}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "-35px",
                }}
              >
                <h2 style={{ flex: 1 }} className="page-title-two">
                  Derniers produits mis en ligne
                </h2>
                {state?.latestProducts?.length < 4 ? null : (
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                      color: defaultLabelColors.main_blue,
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    Voir tous{" "}
                  </p>
                )}
              </Grid>

              {state?.latestProducts?.slice(0, 4)?.map((i: any, idx: any) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    style={{ cursor: "pointer", maxHeight: 400 }}
                    onClick={() => {
                      handleOpenProduct(i?.id);
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "32px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        overflow: "hidden",
                      }}
                    >
                      <Box position="relative">
                        <div style={{ height: "130px" }}>
                          <CardMedia
                            component="img"
                            image={
                              i?.images[0] ??
                              "https://via.placeholder.com/300x200"
                            }
                            alt="Product Image"
                            sx={{ objectFit: "contain", height: "140px" }}
                          />
                        </div>
                        {/* <IconButton
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          backgroundColor: "#fff",
                          padding: "5px",
                          borderRadius: "50%",
                          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                          "&:hover": { backgroundColor: "#fff" },
                        }}
                      >
                        {state?.isFavorited ? (
                          <Favorite sx={{ color: "#ee6931" }} />
                        ) : (
                          <FavoriteBorder sx={{ color: "#626175" }} />
                        )}
                      </IconButton> */}
                      </Box>

                      <CardContent>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          color="#3B3A3A"
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Raleway-Variable",
                            textWrap: "nowrap",
                          }}
                        >
                          {handleLocalize({
                            en: i?.product_title?.en,
                            fr: i?.product_title?.fr,
                          }) ?? "N/A"}
                        </Typography>

                        <Typography
                          variant="body2"
                          color="#8F8F8F"
                          sx={{
                            fontSize: "12px",
                            marginBottom: "4px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {handleLocalize({
                            en: i?.brand_name?.en,
                            fr: i?.brand_name?.fr,
                          }) ?? "N/A"}{" "}
                          -
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <Typography
                              variant="body2"
                              color="#8F8F8F"
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Raleway-Variable",
                              }}
                            >
                              Versailles - 78000
                            </Typography>
                          </div>
                          <div
                            style={{
                              color: defaultLabelColors.main_blue,
                              fontWeight: "700",
                              fontFamily: "Raleway-Variable",
                              fontSize: "22px",
                            }}
                          >
                            {i?.variation?.discount ? (
                              <span
                                style={{ color: "lightgray", paddingRight: 20 }}
                              >
                                <s> {i?.variation?.price} € </s>
                              </span>
                            ) : null}
                            {i?.variation?.discount
                              ? numberFormatWithoutCurrency(
                                  Number(i?.variation?.price) -
                                    Number(i?.variation?.discount)
                                )
                              : numberFormatWithoutCurrency(
                                  i?.variation?.price
                                )}
                            €
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>

            <Grid container spacing={2} style={{ paddingBottom: "20px" }}>
              <Grid
                xs={12}
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "-35px",
                }}
              >
                <h2 style={{ flex: 1 }} className="page-title-two">
                  Les boutiques tendances
                </h2>
                {state?.topTrendingProducts?.length < 4 ? null : (
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                      color: defaultLabelColors.main_blue,
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    Voir tous{" "}
                  </p>
                )}
              </Grid>
              {state?.topTrendingProducts
                ?.slice(0, 4)
                ?.map((i: any, idx: any) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={3}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOpenProduct(i?.id);
                      }}
                    >
                      <Card
                        sx={{
                          borderRadius: "32px",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          overflow: "hidden",
                        }}
                      >
                        <Box position="relative">
                          <div style={{ height: "130px" }}>
                            <CardMedia
                              component="img"
                              image={
                                i?.images[0] ??
                                "https://via.placeholder.com/300x200"
                              }
                              alt="Product Image"
                              sx={{ objectFit: "contain", height: "140px" }}
                            />
                          </div>
                          {/* <IconButton
                          onClick={() => {
                            setState({ isFavorited: !state?.isFavorited });
                          }}
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            backgroundColor: "#fff",
                            padding: "5px",
                            borderRadius: "50%",
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                            "&:hover": { backgroundColor: "#fff" },
                          }}
                        >
                          {state?.isFavorited ? (
                            <Favorite sx={{ color: "#ee6931" }} />
                          ) : (
                            <FavoriteBorder sx={{ color: "#626175" }} />
                          )}
                        </IconButton> */}
                        </Box>

                        <CardContent>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            color="#3B3A3A"
                            sx={{
                              fontSize: "16px",
                              fontFamily: "Raleway-Variable",
                              textWrap: "nowrap",
                            }}
                          >
                            {handleLocalize({
                              en: i?.product_title?.en,
                              fr: i?.product_title?.fr,
                            }) ?? "N/A"}
                          </Typography>

                          <Typography
                            variant="body2"
                            color="#8F8F8F"
                            sx={{
                              fontSize: "12px",
                              marginBottom: "4px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {handleLocalize({
                              en: i?.brand_name?.en,
                              fr: i?.brand_name?.fr,
                            }) ?? "N/A"}{" "}
                            -
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Typography
                                variant="body2"
                                color="#8F8F8F"
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                Versailles - 78000
                              </Typography>
                            </div>
                            <div
                              style={{
                                color: defaultLabelColors.main_blue,
                                fontWeight: "700",
                                fontFamily: "Raleway-Variable",
                                fontSize: "22px",
                              }}
                            >
                              {i?.variation?.discount ? (
                                <span
                                  style={{
                                    color: "lightgray",
                                    paddingRight: 20,
                                  }}
                                >
                                  <s> {i?.variation?.price} € </s>
                                </span>
                              ) : null}
                              {i?.variation?.discount
                                ? numberFormatWithoutCurrencyFixed(
                                    i?.variation?.price - i?.variation?.price
                                  )
                                : numberFormatWithoutCurrencyFixed(
                                    i?.variation?.price
                                  )}{" "}
                              €
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>

            {user && state?.topTrendingProducts?.length > 1 ? (
              <Grid container spacing={2} style={{ paddingBottom: "50px" }}>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "-35px",
                  }}
                >
                  <h2 style={{ flex: 1 }} className="page-title-two">
                    Une autre catégorie
                  </h2>
                  {state?.topTrendingProducts?.length < 4 ? null : (
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        color: defaultLabelColors.main_blue,
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                      Voir tous{" "}
                    </p>
                  )}
                </Grid>
                {state?.mostViewedProducts?.length < 1 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <span
                      style={{
                        textAlign: "center",
                        fontFamily: "Raleway-Variable",
                        color: "GrayText",
                        fontSize: "32px",
                      }}
                    >
                      No viewed any product yet!
                    </span>
                  </div>
                ) : (
                  state?.mostViewedProducts
                    ?.slice(0, 4)
                    ?.map((i: any, idx: any) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          lg={3}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleOpenProduct(i?.id);
                          }}
                        >
                          <Card
                            sx={{
                              borderRadius: "32px",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              overflow: "hidden",
                            }}
                          >
                            <Box position="relative">
                              <div style={{ height: "180px" }}>
                                <CardMedia
                                  component="img"
                                  image={
                                    i?.images[0] ??
                                    "https://via.placeholder.com/300x200"
                                  }
                                  alt="Product Image"
                                  sx={{ objectFit: "contain", height: "160px" }}
                                />
                              </div>
                              {/* <IconButton
                              sx={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                backgroundColor: "#fff",
                                padding: "5px",
                                borderRadius: "50%",
                                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                                "&:hover": { backgroundColor: "#fff" },
                              }}
                            >
                              {state?.isFavorited ? (
                                <Favorite sx={{ color: "#ee6931" }} />
                              ) : (
                                <FavoriteBorder sx={{ color: "#626175" }} />
                              )}
                            </IconButton> */}
                            </Box>

                            {/* Content Section */}
                            <CardContent>
                              <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#3B3A3A"
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Raleway-Variable",
                                  textWrap: "nowrap",
                                }}
                              >
                                {handleLocalize({
                                  en: i?.product_title?.en,
                                  fr: i?.product_title?.fr,
                                }) ?? "N/A"}
                              </Typography>

                              <Typography
                                variant="body2"
                                color="#8F8F8F"
                                sx={{
                                  fontSize: "12px",
                                  marginBottom: "4px",
                                  fontFamily: "Raleway-Variable",
                                }}
                              >
                                {handleLocalize({
                                  en: i?.brand_name?.en,
                                  fr: i?.brand_name?.fr,
                                }) ?? "N/A"}{" "}
                                -
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <Typography
                                    variant="body2"
                                    color="#8F8F8F"
                                    sx={{
                                      fontSize: "12px",
                                      fontFamily: "Raleway-Variable",
                                    }}
                                  >
                                    Versailles - 78000
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    color: defaultLabelColors.main_blue,
                                    fontWeight: "700",
                                    fontFamily: "Raleway-Variable",
                                    fontSize: "22px",
                                  }}
                                >
                                  {i?.variation?.discount ? (
                                    <span
                                      style={{
                                        color: "lightgray",
                                        paddingRight: 20,
                                      }}
                                    >
                                      <s> {i?.variation?.price} € </s>
                                    </span>
                                  ) : null}
                                  {i?.variation?.discount
                                    ? numberFormatWithoutCurrencyFixed(
                                        i?.variation?.price -
                                          i?.variation?.price
                                      )
                                    : numberFormatWithoutCurrencyFixed(
                                        i?.variation?.price
                                      )}{" "}
                                  €
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })
                )}
              </Grid>
            ) : null}
          </div>
        </div>

        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>
      </div>
    </>
  );
};

export default MarketPlace;
