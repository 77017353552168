import React, { useEffect, useRef, useState } from "react";
import HeaderForMarketPlace from "../../../UiComponent/HeaderForMarketPlace";
import "react-quill/dist/quill.snow.css"; // Import styles for Quill editor

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddPhotoAlternateOutlined,
  ArrowBack,
  ArrowBackIos,
  Close,
  ExpandMore,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { defaultLabelColors } from "../../../utils/constants";
import { handleLocalize, toBase64 } from "../../../utils/helper";
import Toast from "../../../UiComponent/Toast/Toast";
import { AddImageIcon } from "../../../assets/images/FrankIcons";
import InputField from "../../../UiComponent/TextFeild";
import apiServices from "../../../services/RequestHandler";
import _ from "lodash";
import ReactQuill from "react-quill";
import SelectField from "../../../UiComponent/SelectField";
import CustomButton from "../../../UiComponent/CustomButton";
import axios from "axios";
import { BASE_URL } from "../../../services/HttpProvider";
import { color_theme } from "../../SenderInbox.tsx/AccepterChat";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store";

type ChildCategory = {
  id: number;
  category_name: {
    fr: string;
  };
};

type ParentCategory = {
  id: number;
  category_name: {
    fr: string;
  };
  children: ChildCategory[]; // Array of children for each parent
};

interface GroupedAttribute {
  attribute_name: {
    en: string;
    fr: string;
  };
  values: Array<{
    en: string;
    fr: string;
  }>;
}

interface Props {
  groupedAttributes: { [key: string]: GroupedAttribute };
}

function AddProductIndividual(props: any) {
  const { groupedAttributes } = props;
  const [valueForRich, setValueForRich] = useState("");

  const navigate = useNavigate();
  const selector = useSelector((state: RootState) => state.mainApp);
  
  const [open, setOpen] = useState(false);
  const [selectedParent, setSelectedParent] = useState<ParentCategory | null>(
    null
  );
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectParentAttributes, setSelectParentAttributes] = useState("");
  const [selectChildAttributes, setSelectChildAttributes] = useState("");
  const [showChildren, setShowChildren] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isMultiLang: false,
      items: [],
      prodImages: {
        images: [],
      },

      variations: [],
      catalog: {
        product_specifications: [],
      },
      attr_types: [],
      p_variants: [],
      net_weight: '',

      selectedValues: {},
    }
  );
  useEffect(() => {
    handleGetAllCategories();
    getAllAttributes();
    getServiceMethods();
  }, []);

  const handleGetSpecificatiobByCategory = async (catId: number) => {
    try {
      let prod_specs = await apiServices.getFromApi(
        `c2c-specification-categories/${catId}`,
        ""
      );

      if (prod_specs?.status === 200 || prod_specs?.status === 201) {

        
        setState({  showSpecification: true,


          product_specifications_resp: prod_specs?.data,
           
        });
        if (prod_specs?.status == 200) {
          let catalog: any = {
            product_specifications: [],
          };
  
          for (
            let index = 0;
            index < prod_specs?.product_specifications?.length;
            index++
          ) {
            const item = prod_specs?.product_specifications[index];
            let isPresent = prod_specs?.data?.findIndex(
              (a: any) => Number(a.id) == Number(item?.specification_name_id),
            );
            let selected_i = prod_specs?.data[isPresent];
  
            let prod_sp = {
              spec_id: Number(item.id),
              specification_name_id: selected_i?.id,
              data: selected_i,
              product_specification_values:
                item?.product_specification_values?.map((j: any) => ({
                  specification_value_id: j.specification_value_id,
                  item_data:
                    selected_i?.specification_values?.filter((a: any) =>
                      item?.product_specification_values?.some(
                        (b: any) => a.id == b.specification_value_id,
                      ),
                    )[0] ?? {},
                })),
              selected_product_specification_values:
                item?.product_specification_values?.map(
                  (j: any) => j.specification_value_id,
                ),
              _value: selected_i?.is_drop_down
                ? item?.product_specification_values?.map(
                    (j: any) => j.specification_value_id,
                  )
                : item?.product_specification_values[0]?.specification_value?.en,
            };
            catalog.product_specifications?.push(prod_sp);
          }
          setState({
            catalog,
          });
       }
      }
    } catch (error) {}
  };
  const handleGetAtributessByCategory = async (catId: number) => {
    try {
      let resp = await apiServices.getFromApi(
        `c2c-particualr-attribute-categories/${catId}`,
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        setState({ attributes: resp?.data, showSpecification: true });
      }
    } catch (error) {}
  };

  useEffect(() => {
    setSelectChildAttributes("");
  }, [selectParentAttributes]);

  const getServiceMethods = async () => {
    try {
      const s_method_resp = await apiServices.getFromApi(
        "/c2c-service-methods",
        ""
      );
      setState({
        delivery_methods: s_method_resp?.data,
      });
      getBrandList();
    } catch (error) {
      console.log(error);
    }
  };
  const getBrandList = async () => {
    try {
      const resp = await apiServices.getFromApi("c2c-brands", "");
      const brands = resp?.data?.map((item: any) => {
        return {
          id: Number(item.id),
          value: Number(item.id),
          brand_name: item.brand_name,
          brand_image: item?.brand_image,
        };
      });

      setState({
        all_brands: brands,
      });
      handleGetAllCategories();
    } catch (error) {
      console.log(error);
      setState({
        loading_screen: false,
      });
    }
  };
  const onInputChange = (e: any) => {
    try {
      let { value, name } = e.target;

      if (value === "") {
        setState({
          [name]: value,
        });
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {
      console.error("Error in onInputChange:", error);
    }
  };
  function buildNestedStructure(data: any) {
    const lookup: any = {};
    const rootNodes: any = [];

    data.forEach((item: any) => {
      lookup[item.id] = { ...item, childern: [] };
    });
    data.forEach((item: any) => {
      if (item.parent_id) {
        lookup[item.parent_id]?.childern.push(lookup[item.id]);
      } else {
        rootNodes.push(lookup[item.id]);
      }
    });

    setState({ parentCategories: rootNodes });
    return rootNodes;
  }
  const handleGetAllCategories = async () => {
    try {
      handleGetDeliveryMethods();
      setState({ activity: true });
      let resp = await apiServices.getFromApi("c2c-categories", "");
      if (resp?.status === 201 || resp?.status === 200) {
        buildNestedStructure(resp?.data);

        setState({ activity: false });
      }
    } catch (error) {}
  };
  const handleLaunchGallery2 = async (
    state_name: string,
    index: number,
    file: any
  ) => {
    try {
      if (!state?.preventInputClick) {
        const selectedFile = file.target.files[0];

        const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];

        if (!selectedFile || !allowedImageTypes.includes(selectedFile.type)) {
          alert("Please upload a valid image file (jpg, png, gif).");
          return;
        }
        let { prodImages } = state;
        let item_obj = {
          ...prodImages,
        };
        item_obj.images[index] = {
          file: file.target.files[0],
          uri: await toBase64(file.target.files[0]),
        };
        setState({
          prodImages: item_obj,
        });
      }
      setState({ preventInputClick: false });
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemoveImage = (index: number) => {
    setState({ preventInputClick: true });

    let { prodImages } = state;
    let item_obj = {
      ...prodImages,
    };

    item_obj.images.splice(index, 1);

    setState({
      prodImages: item_obj,
    });
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      let { prodImages } = state;
      let item_obj = {
        ...prodImages,
      };
      if (item_obj?.images?.length >= 6) {
        Toast.Error("You cannot add more than 6 pictures");
        return;
      }
      const file = files[0];
      const base64 = await toBase64(file);
      item_obj?.images?.push({
        file,
        uri: base64,
      });
      setState({
        prodImages: item_obj,
      });
    }
  };
  const handleClick = () => {
    if (!state?.preventInputClick) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
    setState({ preventInputClick: false }); // Reset the flag after the click is handled
  };
  const handleChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };
  const handleParentClick = (parentCategory: ParentCategory) => {
    setSelectedParent(parentCategory);
    setShowChildren(true);
  };
  const handleChildSelect = (childCategory: any) => {
    setSelectedCategory(childCategory?.category_name?.fr);

    handleGetSpecificatiobByCategory(childCategory?.id);

    setState({ catID: childCategory?.id });
    handleGetAtributessByCategory(childCategory?.id);
    setShowChildren(false);
    setSelectedParent(null);
    setOpen(false);
    handleClose();
  };
  const handleSelectChange =
    (specLabel: string) => (event: SelectChangeEvent<any>) => {
      setState({
        ...state,
        selectedValues: {
          ...state.selectedValues,
          [specLabel]: event.target.value,
        },
      });
    };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setShowChildren(false);
    setSelectedParent(null);
    setOpen(false);
  };
  const getAllAttributes = async () => {
    try {
      const resp = await apiServices.getFromApi("c2c-product-attributes", "");
      const data: any[] = resp.data;
      setState({ allAtrrData: resp?.data });

      const groupedAttributes = _.groupBy(
        data,
        (item) => item.attribute_name.en
      );

      const result: any = Object.entries(groupedAttributes).reduce(
        (acc, [key, items]) => {
          acc[key] = {
            attribute_name: items[0].attribute_name,
            values: items.map((item) => item.attribute_value),
          };
          return acc;
        },
        {} as any
      );
      let attrnames = [];
      for (const key in result) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
          const element = result[key];
          attrnames.push(element.attribute_name);
        }
      }

      setState({ allAtribures: result, parentAttributes: attrnames });
    } catch (error) {
      console.error("Error fetching attributes:", error);
    }
  };

  const handleChangeForRich = (newValue: any) => {
    setValueForRich(newValue);
  };
  const handleGetDeliveryMethods = async () => {
    try {
      const resp = await apiServices.getFromApi("c2c-service-methods", "");
      const deliveryMethods = resp?.data?.filter(
        (i: any) => i?.service_method_idx !== 2
      );
      setState({ serviceMethods: deliveryMethods });
    } catch (error) {}
  };

  const handleAdDprouct = async () => {
    try {
      console.log("state.catalog",state?.catalog);
      
      let obj: any = {
        category_id: Number(state?.catID),
        brand_id: state?.brand?.id,
        product_title: {
          en: state?.prod_name_en,
          fr: state?.prod_name_fr ?? state?.prod_name_en,
        },
        product_desc: valueForRich,
        base_price: Number(state.prod_prix),

        videos: [""],
        video_links: [""],
        attributes: [
          {
            attribute_id: 172543538230777,
          },
          {
            attribute_id: 172475566026041,
          },
        ],
        product_specifications: [
          {
            specification_name_id: 172535076677485,
            product_specification_values: [
              {
                specification_value_id: 172535076680714,
              },
            ],
          },
        ],
        product_attributes: [
          {
            attribute_name_id: 173133181978887,
            product_attribute_values: [
              {
                attribute_value_id: 173133181983892,
              },
            ],
          },
        ],
      };
      let formData = new FormData();

      for (const img of state?.prodImages?.images ?? []) {
        formData.append("files", img.file);
      }
      if (state?.prodImages?.images?.length > 0) {
        try {
          const resp = await axios({
            baseURL: BASE_URL,
            headers: {
              "Content-Type": "multipart/form-data", // this is important
            },
            method: "post",
            url: "upload/upload-multiple",
            data: formData,
          });

          let images = resp.data?.data?.map((i: any) => i.uri);

          obj["images"] = images;
        } catch (error) {
          obj["prod_images"] = [];
        }
      }
      console.log(obj);
    } catch (error) {}
  };

  const handleSelectChangeForBrand = (event: any) => {
    const selectedBrand = state.all_brands.find(
      (brand: any) => brand.brand_name.en === event.target.value
    );

    setState({
      brand: { id: selectedBrand.id, name: selectedBrand.brand_name.en },
    });
  };

  const handleUpdateItemState = (
    item: any,
    index: number,
    type: string
  ) => {
    try {
      const { product_specifications } = state.catalog;
  
      let selectedItem;
  
      if (type === "dropdown") {
        selectedItem = {
          ...product_specifications[index],
          specification_name_id: Number(state.product_specifications_resp[index]?.id),
          data: state.product_specifications_resp[index],
          product_specification_values: [
            {
              specification_value_id: item?.id,
              item_data: item,
            },
          ],
          _value: item,
        };
      } else if (type === "textInput") {
        selectedItem = {
          ...product_specifications[index],
          specification_name_id: Number(state.product_specifications_resp[index]?.id),
          data: state.product_specifications_resp[index],
          product_specification_values: [
            {
              pro_specification_value: {
                en: item,
                fr: item,
              },
              item_data: item,
            },
          ],
          _value: item,
        };
      } else if (type === "multiDropdown") {
        selectedItem = {
          ...product_specifications[index],
          specification_name_id: Number(state.product_specifications_resp[index]?.id),
          data: state.product_specifications_resp[index],
          product_specification_values: item?.map((i: any) => ({
            specification_value_id: Number(i),
          })),
          selected_product_specification_values: item,
          _value: item,
        };
      }
  
       const updatedProductSpecifications = [...product_specifications];
      console.log("selectedItem",selectedItem);
      
      updatedProductSpecifications[index] = selectedItem;
      console.log("updatedProductSpecifications[index]",updatedProductSpecifications[index]);
      
  
      setState((prevState:any) => ({
        catalog: {
          ...prevState.catalog,
          product_specifications: updatedProductSpecifications,
        },
      }));
      console.log("State?.product_specifications",state?.catalog);
      
    } catch (error) {
      console.error("Error in handleUpdateItemState:", error);
    }
  };

  return (
    <>
      <div
        style={{
          background: "#f9c9b3",
          height: "100%",
          minHeight: "100vh",
          maxHeight: "100%",
        }}
      >
        <HeaderForMarketPlace />

        <div style={{ padding: "10px 130px 500px 130px" }}>
          <Grid container spacing={2}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "26px",
                  gap: 10,
                  cursor: "pointer",
                }}
                className="display-flex"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {" "}
                <ArrowBackIos style={{ fontSize: "22px" }} /> Vendre un article
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{ flexDirection: "column" }}
          >
            <Grid
              xs={12}
              md={12}
              style={{
                display: "flex",
                cursor: "pointer",
                flexDirection: "column",
                maxWidth: "800px",
                width: "700px",
              }}
              item
            >
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none", zIndex: -11111111111 }}
                onChange={handleFileChange}
              />

              <div
                style={{
                  background: "#FFFFFF",
                  width: "100%",
                  height: "150px",
                  border: `2px dashed #BCBBC4`,
                  borderRadius: "24px",
                }}
                className="display-flex"
                onClick={handleClick}
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Raleway-Variable",
                    color: defaultLabelColors.main_orange,
                    fontSize: "20px",
                    fontWeight: "700",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <AddImageIcon fill={defaultLabelColors.main_orange} />
                  {"Ajouter des photos"}
                </label>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", gap: 15 }}>
                  {state?.prodImages?.images?.map(
                    (item: any, index: number) => (
                      <div
                        key={"PRODUCTIMAGES_" + index + 1}
                        style={{ marginTop: 10 }}
                      >
                        <div
                          style={{
                            height: 100,
                            width: 100,
                            borderRadius: 16,
                            backgroundColor: "white",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 10,
                            flexWrap: "wrap",
                            display: "flex",
                          }}
                        >
                          {item?.uri || typeof item === "string" ? (
                            <>
                              <label
                                style={{ position: "relative" }}
                                htmlFor={`product_image_back_${index + 1}`}
                              >
                                <img
                                  src={item?.uri ?? item ?? ""}
                                  alt="img"
                                  style={{
                                    height: "110px",
                                    width: "100%",
                                    borderRadius: 16,
                                    objectFit: "contain",
                                    zIndex: -111,
                                  }}
                                />
                                <Close
                                  style={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    backgroundColor: "grey",
                                    borderRadius: "50%",
                                    color: "#fff",
                                    height: "20px",
                                    width: "20px",
                                    zIndex: 11111,
                                    top: -10,
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleRemoveImage(index);
                                  }}
                                />
                              </label>

                              <input
                                id={`product_image_back_${index + 1}`}
                                type="file"
                                accept="image/*"
                                // accept=".jpg, .jpeg, .png"
                                className={"inputfile"}
                                onChange={(e) =>
                                  handleLaunchGallery2("images", index, e)
                                }
                              />
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <label
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                htmlFor={`product_image_back_${index + 1}`}
                              >
                                <AddPhotoAlternateOutlined
                                  style={{
                                    color: defaultLabelColors.main_blue,
                                    cursor: "pointer",
                                  }}
                                />
                              </label>
                              <input
                                id={`product_image_back_${index + 1}`}
                                type="file"
                                accept="image/*"
                                className={"inputfile"}
                                onChange={(e) => {
                                  handleLaunchGallery2("images", index, e);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <p
                style={{
                  fontFamily: "Raleway-Semi-Bold",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Les annonces avec photos ont 6 fois plus de chances d’être
                vendus. Jusqu’a 6 images
              </p>
            </Grid>

            <Grid
              xs={12}
              item
              style={{
                maxWidth: "800px",
                width: "700px",
              }}
            >
              <div
                style={{
                  background: "#F4F3F6",
                  padding: "16px",
                  borderRadius: 24,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <p
                  style={{
                    flex: 1,
                    fontFamily: "Raleway-Semi-Bold",
                    fontSize: "16px",
                  }}
                >
                  Titre de votre article
                  <span style={{ float: "right" }}>
                    <Switch
                      value={state?.isMultiLang}
                      onClick={() => {
                        setState({ isMultiLang: !state?.isMultiLang });
                      }}
                    />
                    Is Multi Language?
                  </span>
                </p>

                <InputField
                  isMultiLine={true}
                  placeHolder={`Article titre ${
                    state?.isMultiLang ? "(EN)" : ""
                  }`}
                  input_type={"text"}
                  input_value={state.prod_name_en ?? ""}
                  onchange={onInputChange}
                  input_name={"prod_name_en"}
                  input_style={{
                    background: "#fff",
                    width: "100%",
                    borderRadius: "16px",
                    border: "none",
                    fontSize: "16px",
                    margin: 0,
                  }}
                />

                {state?.isMultiLang ? (
                  <InputField
                    isMultiLine={true}
                    input_type={"text"}
                    placeHolder={"Article titre (FR)"}
                    input_value={state.prod_name_fr ?? ""}
                    onchange={onInputChange}
                    input_name={"prod_name_fr"}
                    input_style={{
                      background: "#fff",
                      width: "100%",
                      borderRadius: "16px",
                      border: "none",
                      fontSize: "16px",
                      marginTop: "10px",
                      margin: 0,
                    }}
                  />
                ) : null}
              </div>
            </Grid>
            <Grid
              xs={12}
              item
              style={{
                maxWidth: "800px",
                width: "700px",
              }}
            >
              <div
                style={{
                  background: "#F4F3F6",
                  padding: "16px",
                  borderRadius: 24,
                  height: "100%",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Semi-Bold",
                    fontSize: "16px",
                  }}
                >
                  Ajouter une description{" "}
                  <span
                    style={{ fontFamily: "Raleway-Variable", fontSize: "12px" }}
                  >
                    (minimum 10 caractère)
                  </span>
                </p>
                {/* <InputField
                  //   placeHolder={"Information complémentaire"}
                  isMultiLine={true}
                  input_rows={3}
                  input_type={"text"}
                  input_value={state.additional_info ?? ""}
                  onchange={onInputChange}
                  input_name={"additional_info"}
                  input_style={{
                    background: "#fff",
                    width: "100%",
                    borderRadius: "16px",
                    border: "none",
                    fontSize: "16px",
                    margin: 0,
                  }}
                /> */}

                <div>
                  <ReactQuill
                    value={valueForRich}
                    onChange={handleChangeForRich}
                    placeholder="Type product description here..."
                    style={{
                      maxHeight: "250px",
                      height: "200px",
                      overflowY: "scroll",
                      background: "#fff",
                      fontFamily: "Raleway-Variable",
                      border: "none",
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              xs={12}
              item
              style={{
                maxWidth: "800px",
                width: "700px",
              }}
            >
              <div
                style={{
                  background: "#F4F3F6",
                  padding: "16px",
                  borderRadius: 24,
                  height: "100%",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Semi-Bold",
                    fontSize: "16px",
                  }}
                >
                  Prix
                </p>
                <InputField
                  placeHolder={"0,00€"}
                  input_type={"number"}
                  input_value={state.prod_prix ?? ""}
                  onchange={onInputChange}
                  input_name={"prod_prix"}
                  input_style={{
                    background: "#fff",
                    width: "100%",
                    borderRadius: "16px",
                    border: "none",
                    fontSize: "16px",
                    margin: 0,
                  }}
                />
              </div>
            </Grid>

            <Grid
              xs={12}
              item
              style={{
                maxWidth: "800px",
                width: "700px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  open={open}
                  style={{ borderRadius: 16, fontFamily: "Raleway-Semi-Bold" }}
                  onOpen={handleOpen}
                  value={selectedCategory}
                  displayEmpty
                  renderValue={(selected) =>
                    selected ? selected : "Catégorie"
                  }
                  IconComponent={ExpandMore}
                  sx={{
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      borderRadius: 16,
                    },
                    "& .MuiSelect-icon": {
                      color: "#ee6931",
                    },
                  }}
                >
                  {showChildren && selectedParent && (
                    <MenuItem onClick={() => setShowChildren(false)}>
                      <ArrowBack sx={{ marginRight: 1, color: "#ee6931" }} />
                      Retour
                    </MenuItem>
                  )}

                  {showChildren && selectedParent
                    ? state?.parentCategories
                        ?.filter((i: any) => i?.id === selectedParent?.id)?.[0]
                        ?.childern?.map((child: any) => (
                          <MenuItem
                            style={{
                              fontFamily: "Raleway-Semi-Bold",
                              fontSize: "11px",
                            }}
                            key={child.id}
                            onClick={() => handleChildSelect(child)}
                          >
                            <ListItemText
                              style={{ fontFamily: "Raleway-Semi-Bold" }}
                              primary={child?.category_name?.fr}
                            />
                            <Radio
                              checked={
                                selectedCategory === child?.category_name?.fr
                              }
                              sx={{ color: "#ee6931" }}
                            />
                          </MenuItem>
                        ))
                    : state?.parentCategories?.map((parent: any) => (
                        <MenuItem
                          key={parent.id}
                          onClick={() => handleParentClick(parent)}
                        >
                          <ListItemText primary={parent?.category_name?.fr} />
                          <ExpandMore sx={{ color: "#ee6931" }} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              xs={12}
              item
              style={{
                maxWidth: "800px",
                width: "700px",
              }}
            >
              <FormControl fullWidth key="Brands">
                <Select
                  style={{
                    borderRadius: 16,
                    fontFamily: "Raleway-Semi-Bold",
                    marginTop: 5,
                  }}
                  sx={{
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      borderRadius: 16,
                    },
                    "& .MuiSelect-icon": {
                      color: "#ee6931",
                    },
                  }}
                  value={state?.brand}
                  onChange={handleSelectChangeForBrand}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Set maximum height for the dropdown
                      },
                    },
                  }}
                  renderValue={(selected) => {
                    return selected || "Select a brand";
                  }}
                >
                  {state?.all_brands?.map((i: any) => (
                    <MenuItem key={i?.id} value={i?.brand_name?.en}>
                      {handleLocalize({
                        en: i?.brand_name?.en,
                        fr: i?.brand_name?.fr,
                      }) ??
                        i?.brand_name?.en ??
                        ""}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              xs={12}
              item
              style={{
                maxWidth: "800px",
                width: "700px",
                gap: 20,
              }}
            >
              {state?.product_specifications_resp?.length > 1 ? (
                <p style={{ margin: 0, fontFamily: "Raleway-Semi-Bold" }}>
                  Select Specification
                </p>
              ) : null}
 
 {state.product_specifications_resp?.map((prod_spec: any, index: any) => (
  <div key={`PRODUCT_SPEC_${prod_spec?.id}__${index}`}>
    {prod_spec?.is_drop_down ? (
      <>
        {prod_spec?.is_muti_selection ? (
          <FormControl
            fullWidth
            style={{
              backgroundColor: "#ffffff", 
              borderRadius: 16,
              fontFamily: "Raleway-Variable",  
              marginTop:5

             }}
            variant="outlined"
          >
            <Select
              multiple
              sx={{
                backgroundColor: "white",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  borderRadius: 16,
                  marginTop:5

                },}}
              displayEmpty
              value={
                state.catalog?.product_specifications[index]?._value ?? []
              }
              onChange={(e) =>
                handleUpdateItemState(e.target.value, index, "multiDropdown")
              }
              renderValue={(selected) => 
                selected.length ? `${selected.length} items selected` : prod_spec?.specification_name.en
              }
              style={{
                fontFamily: "Raleway-Variable",
                borderRadius: 16,
                

               }}
            >
              {prod_spec?.specification_values?.map((value: any) => (
                <MenuItem key={value.id} value={value.id}>
                  <Checkbox
                    checked={state.catalog?.product_specifications[index]?._value?.includes(value.id)}
                  />
                  <ListItemText primary={value?.specification_value?.en} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <FormControl
            fullWidth
            style={{
              backgroundColor: "#ffffff",
              borderRadius: 16,
              fontFamily: "Raleway-Variable",
              marginTop:5

            }}
            variant="outlined"
          >
            <Select
            sx={{
              backgroundColor: "white",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
                borderRadius: 16,
              },}}
              displayEmpty
              value={state.catalog?.product_specifications[index]?._value ?? ""}
              onChange={(e) =>
                handleUpdateItemState(e.target.value, index, "dropdown")
              }
              renderValue={(selected) => selected || prod_spec?.specification_name.en}
              style={{
                fontFamily: "Raleway-Variable",
                borderRadius: 16,
              }}
            >
              {prod_spec?.specification_values?.map((value: any) => (
                <MenuItem key={value.id} value={value.id}>
                  {value?.specification_value?.en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </>
    ) : (
      <TextField
      sx={{
        backgroundColor: "white",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
          borderRadius: 16,
        },}}
        variant="outlined"
        value={state.catalog?.product_specifications[index]?._value ?? ""}
        onChange={(e: any) =>
          handleUpdateItemState(e.target.value, index, "textInput")
        }
        fullWidth
        style={{
          backgroundColor: "#ffffff",
          borderRadius: 16,
          fontFamily: "Raleway-Variable",
          marginTop: 10,
        }}
        placeholder={prod_spec?.specification_name.en}
        InputProps={{
          style: { fontFamily: "Raleway-Variable" },
        }}
      />
    )}
  </div>
))}
     
  
               

              {state?.attributes?.length > 1 ? (
                <p
                  style={{
                    margin: 0,
                    marginTop: 15,
                    fontFamily: "Raleway-Semi-Bold",
                  }}
                >
                  Select Attributes
                </p>
              ) : null}
              {state?.attributes?.map((i: any) => {
                return (
                  <FormControl
                    style={{}}
                    fullWidth
                    key={i?.specification_name?.en}
                  >
                    <Select
                      style={{
                        borderRadius: 16,
                        fontFamily: "Raleway-Semi-Bold",
                        marginTop: 5,
                      }}
                      sx={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          borderRadius: 16,
                        },
                        "& .MuiSelect-icon": {
                          color: "#ee6931",
                        },
                      }}
                      name={i?.attribute_name?.en}
                      value={
                        state?.selectedValues[i?.attribute_name?.en] ??
                        i?.attribute_name?.en
                      }
                      onChange={handleSelectChange(i?.attribute_name?.en)}
                    >
                      <MenuItem value={i?.attribute_name?.en} disabled>
                        {i?.attribute_name?.en}
                      </MenuItem>

                      {i?.attribute_values?.map((j: any) => {
                        return (
                          <MenuItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                            key={j?.attribute_value?.en}
                            value={j?.attribute_value?.en}
                          >
                            {i?.attribute_name?.en === "Color" ? (
                              <div
                                style={{
                                  height: "25px",
                                  borderRadius: 100,
                                  width: "25px",
                                  background: `${
                                    j?.attribute_value?.en === "Multi color"
                                      ? "conic-gradient( red, orange, yellow, green, cyan, blue, violet, red )"
                                      : j?.attribute_value?.en
                                  }`,
                                }}
                              ></div>
                            ) : null}{" "}
                            {j?.attribute_value?.en}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                );
              })}
            </Grid>
            <Grid
              xs={12}
              item
              style={{
                maxWidth: "800px",
                width: "700px",
              }}
            >
              <div
                style={{
                  background: "#F4F3F6",
                  padding: "16px",
                  borderRadius: 24,
                  height: "100%",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Semi-Bold",
                    fontSize: "16px",
                  }}
                >
                  Remise de l’article - (1 minimum)
                </p>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    padding: "5px",
                  }}
                >
                  <MenuItem
                    sx={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "11px",
                      background: "#fff",
                    }}
                    key={state?.byHand}
                    onClick={() => {
                      setState({ byHand: !state?.byHand });
                    }}
                  >
                    <ListItemText
                      primary={"En main propre"}
                      sx={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "11px",
                      }}
                    />
                    <Radio
                      checked={state?.byHand}
                      sx={{
                        color: "#ee6931",
                        "&.Mui-checked": {
                          color: "#ee6931",
                        },
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: "11px",
                      background: "#fff",
                    }}
                    key={state?.diliveryPossible}
                    onClick={() => {
                      setState({ diliveryPossible: !state?.diliveryPossible });
                    }}
                  >
                    <ListItemText
                      style={{ fontFamily: "Raleway-Semi-Bold" }}
                      primary={"Livraison possible"}
                    />
                    <Radio
                      checked={state?.diliveryPossible}
                      sx={{
                        color: "#ee6931",
                        "&.Mui-checked": {
                          color: "#ee6931",
                        },
                      }}
                    />
                  </MenuItem>

                  <MenuItem
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      fontSize: "11px",
                      background: "#fff",
                    }}
                    key={state?.showServiceMethods}
                    onClick={() => {
                      setState({
                        showServiceMethods: !state?.showServiceMethods,
                      });
                    }}
                  >
                    <ListItemText
                      style={{ fontFamily: "Raleway-Semi-Bold" }}
                      primary={"Méthodes de livraison"}
                    />

                    <ExpandMore sx={{ color: "#ee6931", marginRight: 1 }} />
                  </MenuItem>

                  {state?.diliveryPossible
                    ? state?.serviceMethods?.map((i: any) => {
                        return (
                          <MenuItem
                            sx={{
                              fontFamily: "Raleway-Variable",
                            }}
                            style={{
                              fontSize: "11px",
                              background: "#fff",
                            }}
                            key={state?.diliveryPossible}
                            onClick={() => {
                              setState({
                                diliveryPossible: !state?.diliveryPossible,
                              });
                            }}
                          >
                            <ListItemText
                              style={{ fontFamily: "Raleway-Semi-Bold" }}
                              primary={i.service_method_name?.en ?? ""}
                            />
                            <Radio
                              checked={state?.diliveryPossible}
                              sx={{
                                color: "#ee6931",
                                "&.Mui-checked": {
                                  color: "#ee6931",
                                },
                              }}
                            />
                          </MenuItem>
                        );
                      })
                    : null}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingTop: 20,
                }}
              >
                <CustomButton
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#fff",
                    fontFamily: "Raleway-Variable",
                    background: "#ee6931",

                    border: "none",
                    borderRadius: "12px",
                    padding: "10px 40px 10px 40px",
                  }}
                  onClick={handleAdDprouct}
                  value={"Ajouter mon article"}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default AddProductIndividual;
