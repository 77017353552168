import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NewHeader from "../../UiComponent/NewHeader";
import apiServices from "../../services/RequestHandler";
import drawerBoxIcon from "../../assets/mobileImages/drawer-box-icon.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddBoxOutlined,
  ArrowBackIosNew,
  ArrowDropDown,
  Close,
  KeyboardArrowDown,
  Logout,
  Search,
  Settings,
} from "@mui/icons-material";
import frIcon from "../../assets/images/FrankIcons/fr-icon.png";
import enIcon from "../../assets/images/FrankIcons/en-icon.png";
import {
  buildHierarchy,
  getCategoryPath,
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { defaultLabelColors } from "../../utils/constants";
import { styled } from "@mui/system";
import CustomFooter from "../../UiComponent/CustomFooter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
} from "../../utils/LocalStore.util";
import Toast from "../../UiComponent/Toast/Toast";
import {
  AnouncementIcon,
  CartIcon,
  CartIconMarketPlace,
  FrankIcon,
  MailOrangeIcon,
  UserOrangeIcon,
} from "../../assets/images/FrankIcons";
import { strings } from "../../Store/Localization";
import { setUpdateLanguage } from "../../Store/MainAppReducer";
import InputField from "../../UiComponent/TextFeild";
import LoadingComponent from "../../UiComponent/LoadingComponent";

const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "20px",
  fontFamily: "Raleway-Variable",
  fontWeight: "600",
  padding: "0px 12px",
  color: "#ee6931",
  margin: "0px",
  paddingTop: "0px",
  "& .MuiSelect-icon": {
    color: "#ee6931",
  },
  "& fieldset": {
    border: "none",
  },
  ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: 2,
  },
}));
interface CartItem {
  id: number;
  name: string;
  quantity: number;
}
function AllProductByCateogry(props: any) {
  let param = useParams();
  const dispatch = useDispatch<any>();
  const selector = useSelector((state: RootState) => state.mainApp);
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      category_id: param?.id,
      lang: "fr",
    }
  );

  // Functions for navbar start
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [category, setCategory] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEN, setAnchorEN] = React.useState<null | HTMLElement>(null);
  const openForLang = Boolean(anchorEN);
  const handleClickForLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEN(event.currentTarget);
  };
  const handleCloseForLang = () => {
    setAnchorEN(null);
  };
  const [anchorEM, setAnchorEM] = useState<HTMLButtonElement | null>(null);
  const [cartItems, setCartItems] = useState<CartItem[]>([
    { id: 1, name: "Product 1", quantity: 2 },
    { id: 2, name: "Product 2", quantity: 1 },
  ]);

  const handleClickforCart = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEM(event.currentTarget);
  };

  // Handle close dropdown
  const handleCloseforCart = () => {
    setAnchorEM(null);
  };

  const openForCart = Boolean(anchorEM);
  const id = openForCart ? "cart-popover" : undefined;

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let userDetails = getDataFromLocalStorage("user");
  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      navigate("/");
      Toast.showSuccess("Logout Succesfully");
    } catch (error) {}
  };

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  //navbar code end here

  const handleChange = (event: any) => {
    setCategory(event.target.value);
  };
  useEffect(() => {
    handleGetAllCategories();
    handleGetAllProductByCategory(state?.category_id)
  }, []);

  const handleGetAllProductByCategory = async (cat_id?: any, category?: any) => {
    try {
      setState({ activity: true });
      let obj = {
        category_id: cat_id,
      };

      let resp = await apiServices.postFromApi(
        "c2c-product-masters/get-by-category",
        obj,
        ""
      );

      const groupedCategories = buildHierarchy(state.all_categories);
      const category_path = getCategoryPath(cat_id, groupedCategories);

      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          all_products: resp?.data,
        });
      }
       
      console.log(category_path)
      setState({
        path: category_path
          ?.map((i: any) => `${handleLocalize(i)}`)
          .join(" > "),
      });
      setState({ activity: false });
    } catch (error) {}
  };

  const handleOpenProduct = (id: any) => {
    try {
      navigate(`/product/${id}`);
    } catch (error) {}
  };

  const handleGetAllCategories = async () => {
    try {
      setState({ activity: true });

      let resp = await apiServices.getFromApi("c2c-categories", "");
      if (resp?.status === 201 || resp?.status === 200) {
        buildNestedStructure(resp?.data);
      }
      setState({
        all_categories: resp?.data,
      });
      handleGetAllProductByCategory(state?.category_id);
      setState({ activity: false });
    } catch (error) {
      console.log(error);
    }
  };

  function buildNestedStructure(data: any) {
    const lookup: any = {};
    const rootNodes: any = [];

    data.forEach((item: any) => {
      lookup[item.id] = { ...item, childern: [] };
    });
    data.forEach((item: any) => {
      if (item.parent_id) {
        lookup[item.parent_id]?.childern.push(lookup[item.id]);
      } else {
        rootNodes.push(lookup[item.id]);
      }
    });

    setState({ parentCategories: rootNodes });
    return rootNodes;
  }

  const { all_products, parentCategories, activity } = state;
  return activity ? (
    <LoadingComponent activity={activity} />
  ) : (
    <div className="web-screen">
      <div style={{ background: "#f9c9b3" }}>
        {/* this is navbar */}
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#F4C9B3",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <div
              style={{
                padding: 15,
                background: "white",
                borderRadius: 24,
                marginTop: 20,
                // width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 14,
                    width: "92%",
                  }}
                >
                  <a href="/" style={{ marginBottom: "-10px", marginLeft: 25 }}>
                    <FrankIcon fill={"#ee6931"} height={55} />
                  </a>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 14,
                      flex: 1,
                    }}
                  >
                    <div className="font_resp">
                      <div
                        className="menu-component menu-container"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="particulier font_resp"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Particulier{" "}
                          <KeyboardArrowDown
                            style={{ fontSize: "24px", fontWeight: "lighter" }}
                          />
                        </span>

                        <div className="dropdown">
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/send-package");
                            }}
                          >
                            Envoyer un colis
                          </div>
                          <div className="dropdown-item">Suivre mon colis</div>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/transporter-un-colis");
                            }}
                          >
                            Devenir transporteur
                          </div>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/send-package");
                            }}
                          >
                            Démenagement{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="font_resp">
                      <div
                        className="menu-component menu-container"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="particulier font_resp"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Transporteur{" "}
                          <KeyboardArrowDown
                            style={{ fontSize: "24px", fontWeight: "lighter" }}
                          />{" "}
                        </span>

                        <div className="dropdown">
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/transporter-un-colis");
                            }}
                          >
                            Devenir Transporteur
                          </div>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/transporter-un-colis");
                            }}
                          >
                            Voir les annonces
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="font_resp">
                      <div
                        className="menu-component menu-container"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="particulier font_resp"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            navigate("/marketplace");
                          }}
                        >
                          Market place
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="hide_login_drawer"
                    style={{ cursor: "pointer", marginTop: "10px" }}
                    onClick={toggleDrawer(true)}
                  >
                    <svg
                      width="23px"
                      height="22px"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginTop: "-10px" }}
                    >
                      <path
                        d="M4 16H28M4 8H28M4 24H20"
                        stroke="#281208"
                        stroke-width="2.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  {/* This is Drawer */}
                  <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={toggleDrawer(false)}
                    style={{
                      borderRadius: "0px",
                      background: "none",
                      height: "100vh",
                    }}
                    PaperProps={{
                      sx: {
                        width: "300px",
                      },
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1, padding: "20px 0px 0px 20px" }}>
                        <FrankIcon fill={"#ee6931"} height={60} />
                      </div>
                      <div style={{ padding: "20px 20px 0px 0px" }}>
                        <IconButton onClick={toggleDrawer(false)}>
                          <Close style={{ fontSize: "28px", color: "#000" }} />
                        </IconButton>
                      </div>
                    </div>
                    <Typography
                      variant="body1"
                      style={{ paddingLeft: "20px", flex: 1 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 20,
                        }}
                      >
                        {userDetails ? (
                          <div className="user" style={{ cursor: "pointer" }}>
                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                                fontFamily: "Raleway-Variable",
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                navigate("/chat");
                              }}
                            >
                              <MailOrangeIcon />{" "}
                              <span style={{ color: "#000" }}>Mail</span>
                            </div>
                          </div>
                        ) : null}

                        {userDetails ? (
                          <div
                            className="compte"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <div className="user" style={{}}>
                              <div
                                onClick={handleClick}
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  fontFamily: "Raleway-Variable",
                                  fontWeight: "600",
                                }}
                              >
                                <UserOrangeIcon /> <span>Account</span>
                              </div>
                            </div>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "200px",
                                  borderRadius: "12px",
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&::before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <MenuItem
                                className="dropdown-item"
                                style={{ fontFamily: "Raleway-Variable" }}
                                onClick={() => {
                                  navigate("/profile");
                                }}
                              >
                                <Avatar /> Profil
                              </MenuItem>

                              <Divider />

                              <MenuItem
                                className="dropdown-item"
                                style={{ fontFamily: "Raleway-Variable" }}
                                // onClick={handleClose}
                              >
                                <ListItemIcon>
                                  <Settings fontSize="small" />
                                </ListItemIcon>
                                Settings
                              </MenuItem>
                              <MenuItem
                                className="dropdown-item"
                                style={{ fontFamily: "Raleway-Variable" }}
                                // onClick={handleLogout}
                              >
                                <ListItemIcon>
                                  <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                              </MenuItem>
                            </Menu>
                          </div>
                        ) : (
                          <div style={{ gap: "20px", marginTop: "20px" }}>
                            <div
                              onClick={() => {
                                navigate("/login");
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <span
                                className="particulier"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "left",
                                }}
                              >
                                S'inscrire
                              </span>
                            </div>

                            <div
                              onClick={() => {
                                navigate("/login");
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginTop: "20px",
                              }}
                            >
                              <span
                                className="particulier  "
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                              >
                                Se connecter
                              </span>
                            </div>
                          </div>
                        )}

                        <div
                          className="compte"
                          style={{ cursor: "pointer", position: "relative" }}
                        >
                          <div className="user">
                            <div
                              className="particulier"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={handleClickForLang}
                            >
                              {selector.lang?.toUpperCase()} <ArrowDropDown />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <img src={drawerBoxIcon} height={"60%"} alt="box-icon" />
                      </div>
                    </Typography>
                  </Drawer>
                  {/*Drawer ends here*/}

                  <div className="login_area">
                    <div className="right">
                      <div className="font_resp">
                        <div
                          className="menu-component menu-container"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="particulier font_resp"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Suivre mon colis
                          </span>
                        </div>
                      </div>

                      {userDetails ? (
                        <div className="tracker" style={{ cursor: "pointer" }}>
                          <div
                            className="line-mail"
                            onClick={() => {
                              navigate("/chat");
                            }}
                          >
                            <MailOrangeIcon />
                          </div>
                        </div>
                      ) : null}

                      {userDetails ? (
                        <div
                          className="compte"
                          style={{ cursor: "pointer", position: "relative" }}
                        >
                          <div className="user" style={{}}>
                            <div onClick={handleClick}>
                              <UserOrangeIcon />
                            </div>
                          </div>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: "200px",
                                borderRadius: "12px",
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem
                              className="dropdown-item"
                              style={{ fontFamily: "Raleway-Variable" }}
                              onClick={() => {
                                navigate("/profile");
                              }}
                            >
                              <Avatar /> Profil
                            </MenuItem>
                            <MenuItem
                              className="dropdown-item"
                              style={{
                                fontFamily: "Raleway-Variable",
                                gap: 10,
                              }}
                              onClick={() => {
                                navigate("/my-jobs");
                              }}
                            >
                              <AnouncementIcon
                                height={25}
                                width={25}
                                fill={"#bdbdbd"}
                              />
                              {"Transactions"}
                            </MenuItem>
                            <MenuItem
                              className="dropdown-item"
                              style={{
                                fontFamily: "Raleway-Variable",
                                gap: 10,
                              }}
                              onClick={() => {
                                navigate("/my-anounces");
                              }}
                            >
                              <AnouncementIcon
                                height={25}
                                width={25}
                                fill={"#bdbdbd"}
                              />{" "}
                              {strings?.my_parcels}
                            </MenuItem>
                            <Divider />

                            <MenuItem
                              className="dropdown-item"
                              style={{ fontFamily: "Raleway-Variable" }}
                              // onClick={handleClose}
                            >
                              <ListItemIcon>
                                <Settings fontSize="small" />
                              </ListItemIcon>
                              Settings
                            </MenuItem>
                            <MenuItem
                              className="dropdown-item"
                              style={{ fontFamily: "Raleway-Variable" }}
                              onClick={handleLogout}
                            >
                              <ListItemIcon>
                                <Logout fontSize="small" />
                              </ListItemIcon>
                              Logout
                            </MenuItem>
                          </Menu>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #F4C7B8 ",
                            borderRadius: 4,
                            padding: 5,
                            color: "#ee6931",
                            gap: 10,
                          }}
                        >
                          <div
                            onClick={() => {
                              navigate("/login");
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              className="particulier font_resp"
                              style={{
                                cursor: "pointer",
                                textAlign: "center",
                              }}
                            >
                              S'inscrire
                            </span>
                          </div>
                          <div
                            style={{
                              width: "2px",
                              height: "10px",
                              background: "#F4C7B8",
                            }}
                          ></div>
                          <div
                            onClick={() => {
                              navigate("/login");
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              className="particulier font_resp"
                              style={{
                                cursor: "pointer",
                                textAlign: "center",
                              }}
                            >
                              Se connecter
                            </span>
                          </div>
                        </div>
                      )}

                      <div
                        className="compte"
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        <div className="user">
                          <div
                            className="particulier font_resp"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={handleClickForLang}
                          >
                            {selector.lang?.toUpperCase()} <ArrowDropDown />
                          </div>

                          <Menu
                            id="account-menu"
                            MenuListProps={{
                              "aria-labelledby": "fade-button",
                            }}
                            anchorEl={anchorEN}
                            open={openForLang}
                            onClose={handleCloseForLang}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                // width: "200px",
                                borderRadius: "12px",
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                dispatch(setUpdateLanguage("fr"));
                                handleCloseForLang();
                              }}
                            >
                              <div className="display-flex" style={{ gap: 10 }}>
                                <img src={frIcon} alt="fr-icon" height={30} width={30} />
                                FR
                              </div>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                dispatch(setUpdateLanguage("en"));
                                handleCloseForLang();
                              }}
                            >
                              <div className="display-flex" style={{ gap: 10 }}>
                                <img src={enIcon} alt="en-icon" height={20} width={30} />
                                EN
                              </div>
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderTop: "1px solid lightgray",
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <InputField
                    placeHolder={"Rechercher des produits"}
                    input_value={state?.searched_prod}
                    input_name={"searched_prod"}
                    input_style={{
                      background: "#F4F3F6",
                      minWidth: "200px",
                      width: "600px",
                      maxWidth: "772px",
                      borderRadius: "12px",
                    }}
                    readonly={true}
                    startIcon={<Search style={{ opacity: "50%" }} />}
                  />
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  <Button
                    variant="contained"
                    startIcon={<AddBoxOutlined style={{ fontSize: "26px" }} />}
                    sx={{
                      backgroundColor: defaultLabelColors.main_orange, // Same as the blue color in the image
                      color: "#fff",
                      borderRadius: "12px",
                      padding: "10px 20px",
                      textTransform: "none",
                      fontSize: "16px",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "bold",
                      width: "300px",
                      "&:hover": {
                        backgroundColor: defaultLabelColors.main_orange,
                      },
                    }}
                  >
                    Vendre un article
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClickforCart}
                    sx={{
                      backgroundColor: defaultLabelColors.main_blue,
                      color: "#fff",
                      borderRadius: "12px",
                      textTransform: "none",
                      fontSize: "16px",
                      textAlign: "center",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: defaultLabelColors.main_blue,
                      },
                    }}
                  >
                    <IconButton aria-describedby={id}>
                      <Badge badgeContent={cartItems.length} color="primary">
                        <CartIconMarketPlace fill={defaultLabelColors.white} />
                      </Badge>
                    </IconButton>
                  </Button>

                  <Popover
                    id={id}
                    open={openForCart}
                    anchorEl={anchorEM}
                    onClose={handleCloseforCart}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        borderRadius: "16px", // Apply border radius here
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for effect
                        padding: "10px",
                      },
                    }}
                  >
                    <List>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontWeight: 700,
                            color: defaultLabelColors.main_blue,
                            fontSize: "22px",
                          }}
                        >
                          Votre panier
                        </div>
                        <div>
                          <CartIconMarketPlace
                            fill={defaultLabelColors.main_blue}
                          />
                        </div>
                      </div>
                      {cartItems.length > 0 ? (
                        <div
                          className="card-container"
                          style={{ marginTop: "5px", width: "400px" }}
                        >
                          <img
                            src={
                              "https://media.istockphoto.com/id/1411619454/photo/end-table-round-coffee-table-isolated-on-white.jpg?s=1024x1024&w=is&k=20&c=1RTn3iimqMcYoGZu_io2END5yxZVOw0d6jV-nrlTPWE="
                            }
                            className="card-image"
                            alt="card-img"
                          />
                          <div className="card-details">
                            <div
                              className="card-title"
                              style={{ fontSize: 17 }}
                            >
                              {/* {handleLocalize({
                               fr: i?.packageType?.item_name_fr,
                               en: i?.packageType?.item_name_en,
                             }) ?? ""} */}
                              Table
                            </div>
                            <div className="card-dimensions">
                              {/* {i?.productSize[0]?.size ?? ""} */}
                              large
                            </div>
                          </div>
                          <div className="card-quantity">
                            x2
                            {/* {i?.prod_quantity} */}
                          </div>
                        </div>
                      ) : (
                        <ListItem>
                          <Typography>No items in the cart.</Typography>
                        </ListItem>
                      )}
                    </List>
                  </Popover>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: 10,
                  width: "80%",
                  padding: "5px 15px",
                }}
              >
                {parentCategories?.map((category: any) => {
                  return (
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        backgroundColor: "#F5F5F5",
                        color: "#000",
                        textTransform: "none",
                        fontWeight: "600",
                        fontFamily: "Raleway-Variable",
                        borderRadius: "16px",
                        boxShadow: "none",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        "&:hover": {
                          backgroundColor: "#F5F5F5",
                        },
                      }}
                      onClick={() => {
                        handleGetAllProductByCategory(category?.id, category);
                      }}
                    >
                      {handleLocalize({
                        en: category?.category_name?.en,
                        fr: category?.category_name?.fr,
                      }) ?? ""}
                    </Button>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
        {/* navbar ends here */}

        <div style={{ padding: "30px 80px 50px 80px" }}>
          <Grid container spacing={2} style={{ paddingBottom: "40px" }}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                fontFamily: "Raleway-Variable",
                fontWeight: "500",
                fontSize: "26px",
                color: "#4B4A61",
                gap: 10,
                alignItems: "center",
              }}
            >
              <ArrowBackIosNew
                onClick={() => {
                  navigate(-1);
                }}
                style={{ cursor: "pointer" }}
              />
             <h1 style={{fontSize:'26px'}}> {` ${state.path ?? ""} `}</h1>
            </Grid>
          </Grid>

          {/* <Grid container spacing={2} style={{display:'flex',flexDirection:'row'}}>
               <CustomSelect
                labelId="category-label"
                id="category-select"
                value={"Localisation"}
                onChange={handleChange}
                IconComponent={ArrowDropDown}
              >
                
              </CustomSelect>
              <CustomSelect
                labelId="category-label"
                id="category-select"
                value={"Catégorie"}
                onChange={handleChange}
                IconComponent={ArrowDropDown}
              >
                
              </CustomSelect>
              <CustomSelect
                labelId="category-label"
                id="category-select"
                value={"Taille"}
                onChange={handleChange}
                IconComponent={ArrowDropDown}
              >
                
              </CustomSelect>
              <CustomSelect
                labelId="category-label"
                id="category-select"
                value={"Marque"}
                onChange={handleChange}
                IconComponent={ArrowDropDown}
              >
              
              </CustomSelect>
              <CustomSelect
                labelId="category-label"
                id="category-select"
                value={"État"}
                onChange={handleChange}
                IconComponent={ArrowDropDown}
              >
              
              </CustomSelect>
              <CustomSelect
                labelId="category-label"
                id="category-select"
                value={"Couleur "}
                onChange={handleChange}
                IconComponent={ArrowDropDown}
              >
               
              </CustomSelect>
              <CustomSelect
                labelId="category-label"
                id="category-select"
                value={"Prix"}
                // onChange={handleChange}
                IconComponent={ArrowDropDown}
              >
              
              </CustomSelect>
              <CustomSelect
                labelId="category-label"
                id="category-select"
                value={"Trier par"}
                // onChange={handleChange}
                IconComponent={ArrowDropDown}
              >
               
              </CustomSelect>
          </Grid> */}

          <Grid container spacing={2}>
            <Grid xs={12} item>
              <p style={{ fontFamily: "Raleway-Variable", fontWeight: "700" }}>
                {all_products?.length < 1
                  ? null
                  : `${all_products?.length} annonces`}
              </p>
            </Grid>
            {all_products?.map((i: any, idx: any) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={3}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleOpenProduct(i?.id);
                  }}
                >
                  <Card
                    sx={{
                      borderRadius: "32px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      overflow: "hidden",
                    }}
                  >
                    <Box position="relative">
                      <div style={{ height: "130px", paddingTop: 10 }}>
                        <CardMedia
                          component="img"
                          image={
                            i?.images[0] ??
                            "https://via.placeholder.com/300x200"
                          }
                          alt="Product Image"
                          sx={{ objectFit: "contain", height: "140px" }}
                        />
                      </div>
                      {/* <IconButton
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          backgroundColor: "#fff",
                          padding: "5px",
                          borderRadius: "50%",
                          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                          "&:hover": { backgroundColor: "#fff" },
                        }}
                      >
                        {state?.isFavorited ? (
                          <Favorite sx={{ color: "#ee6931" }} />
                        ) : (
                          <FavoriteBorder sx={{ color: "#626175" }} />
                        )}
                      </IconButton> */}
                    </Box>

                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#3B3A3A"
                        sx={{
                          fontSize: "16px",
                          fontFamily: "Raleway-Variable",
                          textWrap: "nowrap",
                        }}
                      >
                        {handleLocalize({
                          en: i?.product_title?.en,
                          fr: i?.product_title?.fr,
                        }) ?? "N/A"}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="#8F8F8F"
                        sx={{
                          fontSize: "12px",
                          marginBottom: "4px",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        {handleLocalize({
                          en: i?.brand_name?.en,
                          fr: i?.brand_name?.fr,
                        }) ?? ""}{" "}
                        -
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Typography
                            variant="body2"
                            color="#8F8F8F"
                            sx={{
                              fontSize: "12px",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            Versailles - 78000
                          </Typography>
                        </div>
                        <div
                          style={{
                            color: defaultLabelColors.main_blue,
                            fontWeight: "700",
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                          }}
                        >
                          {i?.variation?.discount ? (
                            <span
                              style={{ color: "lightgray", paddingRight: 20 }}
                            >
                              <s> {i?.variation?.price} € </s>
                            </span>
                          ) : null}
                          {i?.variation?.discount
                            ? numberFormatWithoutCurrency(
                                Number(i?.variation?.price) -
                                  Number(i?.variation?.discount)
                              )
                            : numberFormatWithoutCurrency(i?.variation?.price)}
                          €
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>

      <div className="main-container" style={{ marginTop: "90px" }}>
        <CustomFooter />
      </div>
    </div>
  );
}

export default AllProductByCateogry;
