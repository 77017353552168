import React from "react";
import womenShoping from "../../assets/images/FrankIcons/women-shoping.png";
import PropTypes from "prop-types";

function OfferAcceptedCard(props: any) {
  const { amountAccepted, headingOne, headingTwo } = props;
  return (
    <div className="container-for-offer-card">
      <div className="content-for-offer-card">
        <img
          src={womenShoping}
          alt="Person shopping"
          className="shopping-image-for-offer-card"
        />
        <div className="text-for-offer-card" style={{flex:1}}>
          <h2  style={{fontSize:'26px',fontWeight:'500',fontFamily:'Raleway-Extra-Bold'}}>{headingOne ?? "Votre offre a été accepté !"}</h2>
          <div className="amount-for-offer-card"> + {amountAccepted} €</div>
          </div>
      </div>
      <div className="background-shape-for-offer-card"></div>
    </div>
  );
}

OfferAcceptedCard.propTypes = {
  amountAccepted: PropTypes.any,
  headingOne: PropTypes.any,
  headingTwo: PropTypes.any,
};

export default OfferAcceptedCard;
