import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import {
  defaultLabelColors,
  SHIPMENT_TYPE,
  spacing,
} from "../../utils/constants";

import {
  Add,
  AddPhotoAlternateOutlined,
  Close,
  East,
  ExpandMore,
  Remove,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AddImageIcon,
  PlusPackageitemIcon,
} from "../../assets/images/FrankIcons";
import apiServices from "../../services/RequestHandler";
import { strings } from "../../Store/Localization";
import { getAnouncementList } from "../../Store/MainAppReducer";
import CustomAnnouceCard from "../../UiComponent/CustomAnnouceCard";
import CustomButton from "../../UiComponent/CustomButton";
import CustomFooter from "../../UiComponent/CustomFooter";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import NewHeader from "../../UiComponent/NewHeader";
import ParcelSizeSelect from "../../UiComponent/ParcelSizeSelect";
import InputField from "../../UiComponent/TextFeild";
import Toast from "../../UiComponent/Toast/Toast";
import { handleLocalize, toBase64 } from "../../utils/helper";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../utils/LocalStore.util";
import AuthAction from "../Auth/AuthAction";
import DropOffInfo from "./DropOffInfo";
import PickupInfo from "./PickupInfo";
import ReviewPackage from "./ReviewPackage";
import SendPackageMobileView from "./SendPackageMobileView";
import ScrollToTop from "../../UiComponent/ScrollToTop";
import { Helmet } from "react-helmet";
import { RootState } from "../../Store";
import FrankConstants from "../../utils/FrankConstants";

function SendPackage(props: any) {
  const navigate = useNavigate();
  const selector = useSelector((state: RootState) => state.mainApp);
  const { classes } = props;
  const [selectedValue, setSelectedValue] = React.useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch<any>();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      openLoginDialog: false,
      openJobDialog: false,
      isDimestions: false,
      shouldNavigate: false,
      step: 1,
      items: [],
      item_quantity: 1,
      announcement_item: {
        images: [],
      },
      isUpdate: false,
      preventInputClick: false,
    }
  );

  useEffect(() => {
    handleGetRecentTransactions();

    dispatch(
      getAnouncementList({
        end_point: "/item-lists/all",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    console.log("RE-RENDER");
  }, [selector.lang]);

  const handleEmptyShipment = () => {
    try {
      setState({
        items: [],
        announcement_item: {
          images: [],
        },
        item_quantity: 1,
      });
    } catch (error) {}
  };
  const handleIncrmntDecrmnt = (state_name: string) => {
    try {
      if (state_name === "increament") {
        setState({
          item_quantity: state?.item_quantity + 1,
        });
      }
      if (state_name === "decrement" && state?.item_quantity > 1) {
        setState({
          item_quantity: state?.item_quantity - 1,
        });
      }
    } catch (error) {}
  };
  const handleCloseLoginPage = () => {
    try {
      setState({ openLoginDialog: false });
    } catch (error) {}
  };
  const handleUpdateState = (state_name: string, data: any) =>
    setState({
      ...state,
      [state_name]: data,
      step: state.step + 1,
    });
  const _handleUpdateState = (state_name: string, data: any) =>
    setState({
      [state_name]: data,
    });
  const handleGetItemList = async () => {
    try {
      const items_list = await apiServices.getFromApi("item-lists/all", "");
      return items_list?.data?.map((item: any) => ({
        label: item?.item_name_fr,
        value: item?.id,
        ...item,
      }));
    } catch (error) {
      console.error("Error fetching items:", error);
      return [];
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  const onInputChangeForDimensions = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let { value, name } = e.target;
  
      // Allow only digits and up to two decimal places
      const re = /^\d*\.?\d{0,2}$/;
  
      // Ensure the value is either empty or matches the regular expression
      if (value === "" || re.test(value)) {
  
        // Prevent leading space, negative numbers, or starting with a period
        if (
          value[0] === " " || 
          parseFloat(value) < 0 || 
          value[0] === "." || 
          (value.length > 1 && value[0] === "0" && !value.includes("."))
        ) {
          return;
        }
  
        // Limit the input to values up to 10,000
        if (parseFloat(value) > 10000) {
          return;
        }
  
        // Set the state with the valid value
        setState({
          [name]: value,
        });
      }
    } catch (error) {
      console.error("Error in onInputChangeForDimensions:", error);
    }
  };
  
  const handleGetRecentTransactions = async () => {
    try {
      let resp = await apiServices.getFromApi(
        "c2c-shipments/get-recent-by-customer",
        ""
      );
      setState({
        recentTransactions: resp?.data,
      });
    } catch (error) {}
  };
  const handleOpenDialog = () => {
    try {
      setState({ openJobDialog: true });
    } catch (error) {}
  };
  const handleLaunchGallery2 = async (
    state_name: string,
    index: number,
    file: any
  ) => {
    try {
      if (!state?.preventInputClick) {
        const selectedFile = file.target.files[0];

        const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];

        if (!selectedFile || !allowedImageTypes.includes(selectedFile.type)) {
          alert("Please upload a valid image file (jpg, png, gif).");
          return;
        }
        let { announcement_item } = state;
        let item_obj = {
          ...announcement_item,
        };
        item_obj.images[index] = {
          file: file.target.files[0],
          uri: await toBase64(file.target.files[0]),
        };
        setState({
          announcement_item: item_obj,
        });
      }
      setState({ preventInputClick: false });
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemoveImage = (index: number) => {
    setState({ preventInputClick: true });

    let { announcement_item } = state;
    let item_obj = {
      ...announcement_item,
    };

    item_obj.images.splice(index, 1);

    setState({
      announcement_item: item_obj,
    });
  };
  const handleClick = () => {
    if (!state?.preventInputClick) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
    setState({ preventInputClick: false }); // Reset the flag after the click is handled
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      let { announcement_item } = state;
      let item_obj = {
        ...announcement_item,
      };
      if (item_obj?.images?.length >= 6) {
        Toast.Error("You cannot add more than 6 pictures");
        return;
      }
      const file = files[0];
      const base64 = await toBase64(file);
      item_obj?.images?.push({
        file,
        uri: base64,
      });
      setState({
        announcement_item: item_obj,
      });
    }
  };
  const EditShipmentItem = (item: any, idx: any) => {
    try {
       setSelectedValue(item?.productSize?.size);

      setState({
        current_index: idx,
        openJobDialog: true,
        item_quantity: item?.prod_quantity,
        prod_length: item?.productLength,
        prod_width: item?.productWidth,
        prod_height: item?.productHeight,
        additional_info: item?.product_additionalInfo,
        isFragile: item?.is_fragile,
        isDimestions: item?.isDimestions,
        announcement_item: {
          images: item?.images,
        },
        selected_value: item?.packageType,
        isUpdate: true,
      });
    } catch (error) {}
  };
  const handleClearAllFeils = () => {
    try {
      setState({
        openJobDialog: false,
        selected_value: null,
        item_quantity: 1,
        prod_length: "",
        prod_width: "",
        prod_height: "",
        additional_info: "",
        isFragile: false,
        isDimestions: false,
        announcement_item: {
          images: [],
        },
        isUpdate: false,
      });
      setSelectedValue("");
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleAddShipment = () => {
    const { announcement_item } = state;
    if (state?.announcement_item?.images?.length < 1) {
      Toast.Error(strings.product_image_error);
      return;
    }
    if (!state.selected_value?.label) {
      Toast.Error(strings?.item_name_error);
      return;
    }
    if (!selectedValue) {
      Toast.Error(strings?.select_size);
      return;
    }

    let shipment_size = SHIPMENT_TYPE?.filter(
      (i) => i?.size === selectedValue
    )[0];

    const updatedItem = {
      ...announcement_item,
      id: state?.item_id,
      packageType: state.selected_value,
      prod_quantity: state?.item_quantity ?? 1,
      productLength: state?.prod_length,
      productWidth: state?.prod_width,
      productHeight: state?.prod_height,
      product_additionalInfo: state.additional_info ?? "",
      is_fragile: state?.isFragile ?? false,
      productSize: shipment_size,
      isDimestions: state?.isDimestions ?? false,
    };

    state?.items.push(updatedItem);
    const token = getDataFromLocalStorage("token");

    if (!token) {
      saveDataToLocalStorage("shipment", state);
    }
    handleClearAllFeils();
  };
  const handleUpdateShipment = (id: any, index: any) => {
    const { announcement_item } = state;

    if (
      state?.current_index < 0 ||
      state?.current_index >= state.items.length
    ) {
      console.error("Invalid index:", state?.current_index);
      return;
    }
    let shipment_size = SHIPMENT_TYPE?.filter((i) => i?.size === selectedValue);

    if (!state.selected_value?.label) {
      Toast.Error(strings?.item_name_error);
      return;
    }

    const updated_data = {
      ...announcement_item,
      id: state?.item_id,
      packageType: state.selected_value,
      prod_quantity: state?.item_quantity ?? 1,
      productLength: state?.prod_length,
      productWidth: state?.prod_width,
      productHeight: state?.prod_height,
      product_additionalInfo: state.additional_info ?? "",
      is_fragile: state?.isFragile ?? false,
      productSize: shipment_size?.length ? shipment_size[0] : null,
      isDimestions: state?.isDimestions ?? false,
    };

    // const updatedItems = state.items.map((item: any) =>
    //   item.id === state?.current_id ? { ...item, ...updated_data } : item
    // );
    const updatedItems = [...state.items]; // Copy the current items array
    updatedItems[state?.current_index] = {
      ...updatedItems[state?.current_index],
      ...updated_data,
    };

    if (updatedItems) {
      setState({
        ...state,
        items: updatedItems,
        openJobDialog: false,
        isUpdate: false,
      });
    }
    handleClearAllFeils();
  };
  const handleContinue = () => {
    try {
      if (state?.items?.length < 1) {
        handleOpenDialog();
        return;
      }

      const token = getDataFromLocalStorage("token");

      if (!token) {
        setState({ openLoginDialog: true });

        return;
      }

      setState({
        step: state?.step + 1,
      });
    } catch (error) {}
  };
  const handleRemoveItem = (id: any, idx: any) => {
    try {
      state?.items?.splice(idx, 1);

      setState({});
      handleClearAllFeils();
    } catch (error) {}
  };
  const handleShipmentTypeChange = (
    value: any | { label: any; value: any }
  ) => {
    setState({
      item_id: value?.id,
      prod_name:
        handleLocalize({
          en: value?.item_name_en,
          fr: value?.item_name_fr,
        }) ?? "",
      prod_length: value?.length ?? 0,
      prod_width: value?.width ?? 0,
      prod_height: value?.height ?? 0,
      // prod_size: product_size,
      selected_value: value,
    });
  };

  return (
    <>
      <Helmet>
        <title>Envoyer un colis entre particuliers - Frank</title>
        <link
          rel="canonical"
          href="https://www.v2internal.frankme.com/send-package"
        />
        <meta
          name="description"
          content="Envoyez vos colis entre particuliers avec Frank pour une livraison économique, écologique, et moins chère partout en France. Rejoignez notre réseau de livraison collaborative."
        />
      </Helmet>
      <div className="web-screen">
        <ScrollToTop />

        <div style={{ background: "#f9c7b8" }}>
          <ScrollToTop />

          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>
        </div>

        {state?.step === 1 ? (
          <>
            <div
              style={{ padding: "0px 140px 0px 140px", background: "#F4C9B3" }}
            >
              <Grid
                container
                spacing={spacing.spacing2}
                style={{ height: "100%",paddingBottom:'10px' }}
              >
                <Grid xs={12} item>
                  <h1
                    className="heading-one"
                    style={{
                      fontFamily: "Raleway-Italic",
                      color: defaultLabelColors.main_blue,
                      fontSize: 40,
                    }}
                  >
                    ENVOYER UN COLIS ENTRE PARTICULIERS
                  </h1>
                  <p
                    className="paragraph-text-two"
                    style={{ marginTop: "-10px", fontSize: "20px" }}
                  >
                    Vous voulez envoyer un colis sans vous ruiner ? Découvrez la
                    livraison collaborative avec Frank!
                    <br />
                    Notre service innovant vous permet de faire livrer vos colis
                    par des particuliers. Un moyen économique et écologique
                    d'expédier vos paquets partout en France.
                  </p>

                  <h2
                    className="paragraph-text-two"
                    style={{  fontFamily:"Raleway-Bold-Italic",paddingBottom:10 }}
                  >
                    Déposez votre annonce
                  </h2>
                </Grid>
              </Grid>

              <div
                style={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "0px 16px 16px 16px",
                }}
              >
                <Grid container spacing={2}>
                  {state?.items?.map((i: any, idx: any) => {
                    return (
                      <Grid xs={12} md={6} item>
                        <div
                          style={{
                            background: "#f4f3f6",
                            borderRadius: "16px",
                          }}
                        >
                          <CustomAnnouceCard
                            productImage={i?.images[0].uri}
                            imageQuantity={i?.images?.length}
                            productLength={i?.productLength}
                            productWidth={i?.productWidth}
                            productheight={i?.productHeight}
                            productSize={
                              i?.productSize[0]?.size ?? i?.productSize?.size
                            }
                            productTitle={handleLocalize({
                              fr: i?.packageType?.item_name_fr,
                              en: i?.packageType?.item_name_en,
                            })}
                            shipmentQuantity={i?.prod_quantity}
                            handleEdit={() => EditShipmentItem(i, idx)}
                            handleRemove={() => handleRemoveItem(i, idx)}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                  <Grid
                    xs={12}
                    md={state?.items?.length % 2 ? 6 : 12}
                    item
                    onClick={handleOpenDialog}
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      className="display-flex"
                      style={{
                        width: "100%",
                        flexDirection: "column",
                        gap: 10,
                        border: "1px dashed  #3026f1",
                        background: "#F4F4FD",
                        padding: "30px",
                        borderRadius: "16px",
                        cursor: "pointer",
                      }}
                    >
                      <PlusPackageitemIcon
                        height={32}
                        fill={defaultLabelColors.main_blue}
                      />
                      <p
                        style={{
                          fontFamily: "Raleway-Extra-Bold-Italic",
                          color: defaultLabelColors.main_blue,
                          fontSize: "24px",
                          marginTop: "-1px",
                        }}
                      >
                        {strings?.add_item}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={spacing.spacing2}>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingTop: "40px",
                  }}
                >
                  <CustomButton
                    style={{ fontFamily: "Raleway-Variable" }}
                    classesNames={"contained_button_two"}
                    onClick={handleContinue}
                    value={
                      <React.Fragment>
                        <div
                          className="display-flex"
                          style={{
                            columnGap: "20px",
                            padding: "4px 0px 4px 0px",
                          }}
                        >
                          {strings?.continue_}
                          <East
                            style={{
                              color: defaultLabelColors.main_blue,
                              background: "#f9d9b6",
                              borderRadius: 100,
                              fontSize: 18,
                              padding: 5,
                            }}
                          />
                        </div>
                      </React.Fragment>
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                spacing={spacing.spacing2}
                style={{ paddingTop: "100px", paddingBottom: "100px" }}
              >
                <Grid xs={12} item>
                  <div
                    className="triangle"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "20px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Italic",
                        fontWeight: "800",
                        color: "#fff",
                        fontSize: "32px",
                        width: "25%",
                      }}
                    >
                      COMMENT
                      <br /> ÇA FONCTIONNE ?
                    </div>

                    <div
                      style={{
                        display: "flex",
                        width: "22%",
                        height: 150,
                        borderRadius: "16px",
                        justifyContent: "center",

                        alignItems: "flex-start",
                        gap: "10px",
                        flexDirection: "column",
                      }}
                      className="mstpone_1"
                    >
                      <div>
                        <span
                          style={{
                            fontFamily: "Raleway-Extra-Bold-Italic",
                            color: "#fff",
                            background: defaultLabelColors.main_orange,
                            borderRadius: 100,
                            fontSize: "25px",
                            padding: "5px 15px 10px 15px",
                            textAlign: "left",
                            marginLeft: 20,
                          }}
                        >
                          1
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontFamily: "Raleway-Extra-Bold",
                          color: defaultLabelColors.main_blue,
                           textAlign: "left",
                          padding: "0px 5px 0px 5px",
                          marginLeft: 20,
                        }}
                      >
                        Créez votre annonce <br /> annonce
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        width: "22%",
                        height: 150,
                        borderRadius: "16px",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        gap: "10px",
                        flexDirection: "column",
                      }}
                      className="mstpone_1"
                    >
                      <div>
                        <span
                          style={{
                            fontFamily: "Raleway-Extra-Bold-Italic",
                            color: "#fff",
                            background: defaultLabelColors.main_blue,
                            borderRadius: 100,
                            fontSize: "25px",
                            padding: "5px 15px 10px 15px",
                            textAlign: "left",
                            marginLeft: "20px",
                          }}
                        >
                          2
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontFamily: "Raleway-Extra-Bold",
                          color: defaultLabelColors.main_blue,
                           textAlign: "left",
                          padding: "0px 5px 0px 5px",
                          marginLeft: 20,
                        }}
                      >
                        Mentionnez le prix <br /> de votre choix
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        width: "22%",
                        height: 150,
                        borderRadius: "16px",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        gap: "10px",
                        flexDirection: "column",
                      }}
                      className="mstpone_1"
                    >
                      <div>
                        <span
                          style={{
                            fontFamily: "Raleway-Extra-Bold-Italic",
                            color: "#fff",
                            background: defaultLabelColors.main_orange,
                            borderRadius: 100,
                            fontSize: "25px",
                            padding: "5px 15px 10px 15px",
                            // textAlign: "center",
                            marginLeft: 20,
                          }}
                        >
                          3
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontFamily: "Raleway-Extra-Bold",
                          color: defaultLabelColors.main_blue,
                           textAlign: "left",
                          padding: "0px 5px 0px 5px",
                          marginLeft: 20,
                        }}
                      >
                        Attendez que l’annonce <br />
                        trouve un transporteur
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div style={{ padding: "0px 140px 0px 140px" }}>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h2
                    style={{
                      textAlign: "left",
                      fontSize: "42px",
                      fontFamily: "Raleway-Extra-Bold-Italic",
                      padding: " 40px 0px 20px 20px",
                      margin: 0,
                    }}
                  >
                    Nos actualités
                  </h2>
                  <ul className="cards">
                    <li className="cards__item">
                      <div className="card">
                        <div className="card__image card__image--river"></div>
                        <div className="card__content">
                          <div style={{ display: "flex" }}>
                            <div className="card__title" style={{ flex: 1 }}>
                              Du nouveau chez Frank
                              <p
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                  marginTop: 5,
                                }}
                              >
                                il y a 10 heures
                              </p>
                            </div>
                            <div>
                              <East
                                style={{
                                  background: "#fff",
                                  borderRadius: 100,
                                  color: defaultLabelColors.main_blue,
                                  padding: 5,
                                  fontSize: "16px",
                                }}
                              />{" "}
                            </div>
                          </div>
                          <p className="card__text">
                            Lorem ipsum dolor sit amet consectetur. Sapien
                            pharetra adipiscing odio et bibendum duis. Auctor
                            scelerisque tellus tortor euismod...
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="cards__item">
                      <div className="card">
                        <div className="card__image card__image--record"></div>
                        <div className="card__content">
                          <div style={{ display: "flex" }}>
                            <div className="card__title" style={{ flex: 1 }}>
                              Du nouveau chez Frank
                              <p
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                  marginTop: 5,
                                }}
                              >
                                il y a 10 heures
                              </p>
                            </div>
                            <div>
                              <East
                                style={{
                                  background: "#fff",
                                  borderRadius: 100,
                                  color: defaultLabelColors.main_blue,
                                  padding: 5,
                                  fontSize: "16px",
                                }}
                              />{" "}
                            </div>
                          </div>
                          <p className="card__text">
                            Lorem ipsum dolor sit amet consectetur. Sapien
                            pharetra adipiscing odio et bibendum duis. Auctor
                            scelerisque tellus tortor euismod...
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="cards__item">
                      <div className="card">
                        <div className="card__image card__image--flowers"></div>
                        <div className="card__content">
                          <div style={{ display: "flex" }}>
                            <div className="card__title" style={{ flex: 1 }}>
                              Du nouveau chez Frank
                              <p
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                  marginTop: 5,
                                }}
                              >
                                il y a 10 heures
                              </p>
                            </div>
                            <div>
                              <East
                                style={{
                                  background: "#fff",
                                  borderRadius: 100,
                                  color: defaultLabelColors.main_blue,
                                  padding: 5,
                                  fontSize: "16px",
                                }}
                              />{" "}
                            </div>
                          </div>
                          <p className="card__text">
                            Lorem ipsum dolor sit amet consectetur. Sapien
                            pharetra adipiscing odio et bibendum duis. Auctor
                            scelerisque tellus tortor euismod...
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Grid>

              <Grid container className="display-flex">
                <Grid xs={12} item>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "26px",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "700",
                      color: "#000",
                    }}
                  >
                    <h3
                      style={{
                        cursor: "pointer",
                        fontSize: "26px",
                       }}
                      className="display-flex"
                      onClick={() => {
                        navigate("/blogs");
                      }}
                    >
                      VOIR TOUS LES ARTICLES &nbsp; <East />
                    </h3>
                  </div>

                  <div
                    style={{
                      // maxWidth: "1000px",
                      marginTop: "30px",
                      height: 1,
                      background: "grey",
                      textAlign: "center",
                    }}
                  ></div>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                marginTop={5}
                className="display-flex"
              >
                <Grid
                  xs={12}
                  item
                  className="display-flex"
                  style={{
                    flexDirection: "column",
                    background: "#F5C9B3",
                    padding: "10px",
                    borderRadius: "18px",
                    maxWidth: "1000px",
                    justifyContent: "center",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "36px",
                       fontFamily: "Raleway-Italic",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      textAlign: "left",
                      width: "100%",
                      paddingLeft: "48px",
                      padding: "15px",
                      margin: 0,
                    }}
                  >
                    En savoir plus sur l'envoi de colis entre particuliers
                  </h3>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Quels types de colis puis-je envoyer avec Frank?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "95%" }}>
                      <p>
                        Particuliers ou professionnels, vous pouvez expédier
                        toutes sortes de colis via notre plateforme ;
                      </p>
                      <p>
                        1. Des petits paquets : cadeaux, vêtements, livres, etc.
                        ;
                      </p>
                      <p>
                        2. Des colis moyens : appareils électroménagers, meubles
                        en kit, vélos, matériel de sport, etc. ;
                      </p>
                      <p>
                        3. Des envois volumineux : cartons de déménagement, gros
                        électroménager, meubles, etc. ;
                      </p>
                      <p>
                        Il n'y a pas de limite de poids. Tant qu'un particulier
                        accepte de transporter votre colis ;
                      </p>
                      <p>
                        Avec Frank, vous pouvez même organiser votre
                        déménagement !
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%"  }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Où faut-il déposer mon colis?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "95%" }}>
                      <p>
                        Vous convenez d'un point de rendez-vous avec le
                        particulier qui assurera le transport de colis. Cela
                        peut être :{" "}
                      </p>

                      <p>• À votre domicile ;</p>
                      <p>• Sur son lieu de départ (gare, aéroport, etc.).</p>
                      <p>
                        L'application vous permet de choisir le lieu le plus
                        pratique et d'échanger avec le transporteur pour
                        organiser la remise du colis en toute simplicité.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Existe-t-il un poids maximum pour l'envoi d'un colis
                        entre particuliers?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "95%" }}>
                      <p>
                        Non, il n'y a pas de limite de poids chez Frank,
                        contrairement à de nombreux transporteurs. Notre réseau
                        de particuliers nous permet d'acheminer même les colis
                        les plus lourds et encombrants.
                      </p>
                      <p>
                        C'est idéal pour les déménagements par exemple. Vous
                        pouvez indiquer le volume et le nombre de personnes
                        requises, et constituer une équipe de Frankies pour vous
                        aider !
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Puis-je envoyer plusieurs colis en même temps ?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "95%" }}>
                      <p>
                        Oui, avec Frank vous pouvez grouper vos expéditions sans
                        problème. Au moment de publier votre annonce, précisez
                        simplement le nombre de colis à envoyer en appuyant sur
                        le “ + “. Notre système adapté fera en sorte de trouver
                        le transporteur adéquat, qu'il s'agisse de quelques
                        paquets ou d'une cargaison complète.
                      </p>
                      <p>
                        Vous pouvez aussi envoyer plusieurs colis à plusieurs
                        destinations différentes. Dans ce cas, il faudra publier
                        une annonce par colis.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Quelle assurance est disponible pour mon colis ?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "95%" }}>
                      <p>
                        Chez Frank,
                        <span style={{ fontWeight: 600 }}>
                          {" "}
                          la sécurité de vos colis est notre priorité.
                        </span>
                        C'est pourquoi chaque envoi réalisé via notre service de
                        livraison collaborative est automatiquement assuré à
                        hauteur de 100€, sans frais supplémentaires.
                      </p>
                      <p>
                        Mais si votre colis a une valeur supérieure, pas de
                        panique ! Grâce à notre partenariat avec un assureur
                        indépendant, vous pouvez souscrire à une
                        <span style={{ fontWeight: 600 }}>
                          {" "}
                          garantie renforcée{" "}
                        </span>{" "}
                        qui couvrira intégralement votre bien en cas de vol,
                        casse ou incident pendant le transport.
                      </p>
                      <p>
                        Par ailleurs, notre plateforme collaborative nous permet
                        de sélectionner des livreurs de confiance.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Comment est fixé le prix pour l'envoi de mon colis ?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "95%" }}>
                      <p>
                        Chez Frank, l’expéditeur peut indiquer le tarif qu’il
                        souhaite pour sa demande de livraison. Le prix dépendra
                        du poids et des dimensions du colis, mais aussi de la
                        distance à parcourir. De leur côté, les transporteurs
                        peuvent être amenés à négocier le prix. Une fois que
                        vous avez renseigné les principales informations
                        concernant votre colis, Frank vous conseille une
                        fourchette de prix que vous pouvez suivre.{" "}
                      </p>
                      <p>
                        Nous optimisons chaque trajet pour vous garantir le
                        meilleur rapport qualité-prix, jusqu'à 50 % moins cher
                        que les transporteurs classiques. Avec notre calculateur
                        en ligne, estimez le coût de votre expédition en
                        quelques clics !
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Quelles sont les zones de livraisons possibles pour
                        l'envoi de mon colis ?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "95%" }}>
                      <p>
                        Envoyez vos colis partout en France métropolitaine, de
                        la grande ville au petit village. Grâce à notre vaste
                        communauté de transporteurs particuliers, nous couvrons
                        même les zones rurales habituellement mal desservies.
                        Que ce soit pour un trajet local, régional ou national,
                        il y aura toujours un Particulier prêt à livrer votre colis à
                        bon port. Élargissez vos horizons sans vous ruiner !
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </div>
          </>
        ) : state?.step === 2 ? (
          <div
            style={{ padding: "0px 140px 0px 140px", background: "#F4C9B3" }}
          >
            <PickupInfo
              handleUpadte={_handleUpdateState}
              handleUpadateState={handleUpdateState}
              {...state}
              {...props}
            />
          </div>
        ) : state?.step === 3 ? (
          <div
            style={{ padding: "0px 140px 0px 140px", background: "#F4C9B3" }}
          >
            <DropOffInfo
              handleUpadte={_handleUpdateState}
              handleUpadateState={handleUpdateState}
              {...state}
              {...props}
            />
          </div>
        ) : (
          <div
            style={{ padding: "0px 140px 0px 140px", background: "#F4C9B3" }}
          >
            <ReviewPackage
              handleUpadte={_handleUpdateState}
              handleEmptyShipment={handleEmptyShipment}
              handleUpadateState={handleUpdateState}
              {...state}
              {...props}
            />
          </div>
        )}

        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>

        <DraggableDialog
          maxWidth={"md"}
          open={state?.openJobDialog}
          handleClose={() => {
            handleClearAllFeils();
          }}
          dialog_title={strings?.add_item}
          classes={classes}
          dialogBody={
            <DialogContent
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
              dividers
            >
              <Box width={"100%"}>
                <>
                  <Grid
                    container
                    spacing={spacing?.spacing2}
                    style={{ paddingTop: "30px" }}
                  >
                    <Grid xs={12} item></Grid>
                    {/* <Grid
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        flexDirection: "column",
                      }}
                      item
                    >
                      <div
                        style={{
                          background: "#F4F3F6",
                          width: "100%",
                          height: "150px",
                          border: `2px dashed ${defaultLabelColors.main_blue}`,
                          borderRadius: "32px",
                        }}
                        className="display-flex"
                        onClick={() => {
                          let { announcement_item } = state;
                          let item_obj = {
                            ...announcement_item,
                          };
                          if (item_obj?.images?.length > 5) {
                            Toast.Error("You cannot add more than 6 pictures");
                            return;
                          }
                          item_obj?.images?.push({});
                          setState({
                            announcement_item: item_obj,
                          });
                        }}
                      >
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontFamily: "Raleway-Variable",
                            color: defaultLabelColors.main_blue,
                            fontSize: "20px",
                            fontWeight: "700",
                            flexDirection: "column",
                            cursor: "pointer",
                          }}
                        >
                          <AddImageIcon fill={defaultLabelColors.main_blue} />
                          {"Ajouter des photos"}
                        </label>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          {state?.announcement_item?.images?.map(
                            (item: any, index: number) => (
                              <div
                                key={"PRODUCTIMAGES_" + index + 1}
                                style={{ marginTop: 10 }}
                              >
                                <div
                                  style={{
                                    height: 100,
                                    width: 100,
                                    borderRadius: 16,
                                    backgroundColor: "white",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 10,
                                    flexWrap: "wrap",
                                    display: "flex",
                                  }}
                                >
                                  {item?.uri || typeof item === "string" ? (
                                    <>
                                      <label
                                        htmlFor={`product_image_back_${
                                          index + 1
                                        }`}
                                      >
                                        <img
                                          src={item?.uri ?? item ?? ""}
                                          alt=""
                                          style={{
                                            height: "110px",
                                            width: "100%",
                                            borderRadius: 16,
                                            objectFit: "contain",
                                          }}
                                        />
                                      </label>
                                      <input
                                        id={`product_image_back_${index + 1}`}
                                        type="file"
                                        className={"inputfile"}
                                        onChange={(e) =>
                                          handleLaunchGallery2(
                                            "images",
                                            index,
                                            e
                                          )
                                        }
                                      />
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <label
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        htmlFor={`product_image_back_${
                                          index + 1
                                        }`}
                                      >
                                        <AddPhotoAlternateOutlined
                                          style={{
                                            color: defaultLabelColors.main_blue,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </label>
                                      <input
                                        id={`product_image_back_${index + 1}`}
                                        type="file"
                                        className={"inputfile"}
                                        onChange={(e) => {
                                          handleLaunchGallery2(
                                            "images",
                                            index,
                                            e
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></div>
                      </div>
                    </Grid> */}
                    <Grid
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        flexDirection: "column",
                      }}
                      item
                    >
                      {/* Hidden File Input */}
                      <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        style={{ display: "none", zIndex: -11111111111 }}
                        onChange={handleFileChange}
                      />

                      {/* Main Div */}
                      <div
                        style={{
                          background: "#F4F3F6",
                          width: "100%",
                          height: "150px",
                          border: `2px dashed ${defaultLabelColors.main_blue}`,
                          borderRadius: "32px",
                        }}
                        className="display-flex"
                        onClick={handleClick}
                      >
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontFamily: "Raleway-Extra-Bold",
                            color: defaultLabelColors.main_blue,
                            fontSize: "20px",
                             flexDirection: "column",
                            cursor: "pointer",
                          }}
                        >
                          <AddImageIcon fill={defaultLabelColors.main_blue} />
                          {"Ajouter des photos"}
                        </label>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          {state?.announcement_item?.images?.map(
                            (item: any, index: number) => (
                              <div
                                key={"PRODUCTIMAGES_" + index + 1}
                                style={{ marginTop: 10 }}
                              >
                                <div
                                  style={{
                                    height: 100,
                                    width: 100,
                                    borderRadius: 16,
                                    backgroundColor: "white",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 10,
                                    flexWrap: "wrap",
                                    display: "flex",
                                  }}
                                >
                                  {item?.uri || typeof item === "string" ? (
                                    <>
                                      <label
                                        style={{ position: "relative" }}
                                        htmlFor={`product_image_back_${
                                          index + 1
                                        }`}
                                      >
                                        <img
                                          src={item?.uri ?? item ?? ""}
                                          alt="img"
                                          style={{
                                            height: "110px",
                                            width: "100%",
                                            borderRadius: 16,
                                            objectFit: "contain",
                                            zIndex: -111,
                                          }}
                                        />
                                        <Close
                                          style={{
                                            position: "absolute",
                                            cursor: "pointer",
                                            backgroundColor: "grey",
                                            borderRadius: "50%",
                                            color: "#fff",
                                            height: "20px",
                                            width: "20px",
                                            zIndex: 11111,
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleRemoveImage(index);
                                          }}
                                        />
                                      </label>

                                      <input
                                        id={`product_image_back_${index + 1}`}
                                        type="file"
                                        accept="image/*"
                                        // accept=".jpg, .jpeg, .png"
                                        className={"inputfile"}
                                        onChange={(e) =>
                                          handleLaunchGallery2(
                                            "images",
                                            index,
                                            e
                                          )
                                        }
                                      />
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <label
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        htmlFor={`product_image_back_${
                                          index + 1
                                        }`}
                                      >
                                        <AddPhotoAlternateOutlined
                                          style={{
                                            color: defaultLabelColors.main_blue,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </label>
                                      <input
                                        id={`product_image_back_${index + 1}`}
                                        type="file"
                                        accept="image/*"
                                        className={"inputfile"}
                                        onChange={(e) => {
                                          handleLaunchGallery2(
                                            "images",
                                            index,
                                            e
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <div
                        style={{
                          background: "#F4F3F6",
                          padding: "20px",
                          borderRadius: 16,
                        }}
                      >
                        <ParcelSizeSelect
                          onChange={handleShipmentTypeChange}
                          selected_value={state?.selected_value}
                        />
                      </div>
                    </Grid>

                    <Grid xs={12} item style={{ display: "flex" }}>
                      <div
                        style={{
                          background: "#F4F3F6",
                          padding: "20px",
                          borderRadius: 16,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontFamily: "Raleway-Extra-Bold",
                             fontSize: "18px",
                            marginBottom: "5px",
                          }}
                        >
                          A propos du colis
                        </p>
                        <Grid container style={{ display: "flex" }}>
                          {FrankConstants.itemSizes(selector.lang)?.map(
                            (i: any) => {
                              return (
                                <>
                                  <Grid
                                    xs={6}
                                    md={3}
                                    lg={3}
                                    item
                                    onClick={() => {
                                      setSelectedValue(i?.size);
                                    }}
                                    style={{
                                      borderRadius: "24px",
                                      background: "#fff",
                                      width: "18.5%",
                                      flexDirection: "column",
                                      padding: 15,
                                      fontFamily: "Raleway-Extra-Bold",
                                      gap: "20px",
                                      cursor: "pointer",
                                      border: `3px solid ${
                                        selectedValue === i?.size
                                          ? defaultLabelColors.main_blue
                                          : "#f3f3f3"
                                      }`,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ flex: 1 }}>
                                        {i?.size_trans}
                                      </div>
                                      <div>
                                        <Radio
                                          checked={selectedValue === i?.size}
                                          onChange={handleChange}
                                          value={i?.size}
                                          name="type"
                                          style={{ color: "#000" }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "14px",
                                        marginTop: "10px",
                                        fontFamily:'Raleway-Bold'
                                      }}
                                    >
                                      {i?.weight_fr}
                                      <span
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "12px",
                                        fontFamily:'Raleway-Variable'

                                        }}
                                      >
                                        : {i?.desc}
                                      </span>
                                    </div>
                                  </Grid>
                                </>
                              );
                            }
                          )}
                        </Grid>
                      </div>
                    </Grid>

                    <Grid xs={12} item>
                      <div
                        style={{
                          background: "#F4F3F6",
                          padding: "20px",
                          borderRadius: 16,
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state?.isDimestions} // Use 'checked' instead of 'value'
                                onClick={() => {
                                  setState({
                                    isDimestions: !state?.isDimestions,
                                  });
                                }}
                                sx={{
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "#ee6931",
                                  },
                                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                    {
                                      backgroundColor: "#ee6931",
                                    },
                                }}
                              />
                            }
                            label="Je connais les dimensions"
                            sx={{
                              ".MuiFormControlLabel-label": {
                                fontFamily: "Raleway-Extra-Bold",
                                fontSize: 16,
                               },
                            }}
                          />
                        </FormGroup>
                        {state?.isDimestions ? (
                          <Grid
                            container
                            spacing={2}
                            style={{ display: "flex", paddingTop: 10 }}
                          >
                            <Grid xs={4} item>
                              <span
                                style={{
                                  margin: 0,
                                  paddingLeft: 10,
                                  fontFamily: "Raleway-Extra-Bold",
                                  fontSize: "16px",
                                }}
                              >
                                {strings?.length}
                              </span>
                              <InputField
                                input_type={"number"}
                                input_value={state?.prod_length ?? ""}
                                endIicon={
                                  <span
                                    style={{ fontFamily: "Raleway-Italic" }}
                                  >
                                    cm
                                  </span>
                                }
                                onchange={onInputChangeForDimensions}
                                input_name={"prod_length"}
                                input_style={{
                                  background: "#fff",
                                  width: "100%",
                                  borderRadius: "16px",
                                  border: "none",
                                }}
                              />
                            </Grid>
                            <Grid xs={4} item>
                              <span
                                style={{
                                  margin: 0,
                                  paddingLeft: 10,
                                  fontFamily: "Raleway-Extra-Bold",
                                   fontSize: "16px",
                                }}
                              >
                                {strings?.width}
                              </span>
                              <InputField
                                input_type={"number"}
                                input_value={state?.prod_width ?? ""}
                                endIicon={
                                  <span
                                    style={{ fontFamily: "Raleway-Italic" }}
                                  >
                                    cm
                                  </span>
                                }
                                onchange={onInputChangeForDimensions}
                                input_name={"prod_width"}
                                input_style={{
                                  background: "#fff",
                                  width: "100%",
                                  borderRadius: "16px",
                                  border: "none",
                                }}
                              />
                            </Grid>
                            <Grid xs={4} item>
                              <span
                                style={{
                                  margin: 0,
                                  paddingLeft: 10,
                                  fontFamily: "Raleway-Extra-Bold",
                                   fontSize: "16px",
                                }}
                              >
                                {strings?.height}
                              </span>
                              <InputField
                                input_type={"number"}
                                input_value={state?.prod_height ?? ""}
                                endIicon={
                                  <span
                                    style={{ fontFamily: "Raleway-Italic" }}
                                  >
                                    cm
                                  </span>
                                }
                                onchange={onInputChangeForDimensions}
                                input_name={"prod_height"}
                                input_style={{
                                  background: "#fff",
                                  width: "100%",
                                  borderRadius: "16px",
                                  border: "none",
                                  fontSize: "16px",
                                }}
                              />
                            </Grid>
                            
                          </Grid>
                        ) : null}
                      </div>
                    </Grid>

                   
 
                    <Grid xs={12} item>
                      <div
                        style={{
                          background: "#F4F3F6",
                          padding: "20px",
                          borderRadius: 16,
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: 22,
                            }}
                            control={
                              <Switch
                                checked={state?.isFragile} // Use 'checked' instead of 'value'
                                onClick={() => {
                                  setState({
                                    isFragile: !state?.isFragile,
                                  });
                                }}
                                sx={{
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "#ee6931",
                                  },
                                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                    {
                                      backgroundColor: "#ee6931",
                                    },
                                }}
                              />
                            }
                            sx={{
                              ".MuiFormControlLabel-label": {
                                fontFamily: "Raleway-Extra-Bold",
                                fontSize: 16,
                              },
                            }}
                            label={strings?.fragile}
                          />
                        </FormGroup>
                      </div>
                    </Grid>

                    <Grid xs={12} item>
                              <div
                            style={{
                              background: "#F4F3F6",
                              padding: "20px",
                              borderRadius: 16,
                              display:'flex',
                              justifyContent:'flex-start',
                            }}
                              >
                                <InputField
                                  placeHolder={"Information complémentaire"}
                                  isMultiLine={true}
                                  input_rows={2}
                                  input_type={"text"}
                                  input_value={state.additional_info ?? ""}
                                  onchange={onInputChange}
                                  input_name={"additional_info"}
                                  input_style={{
                                    background: "#fff",
                                    width: "99%",
                                    borderRadius: "16px",
                                    border: "none",
                                    fontSize: "16px",
                                  }}
                                />
                              </div>
                            </Grid>

                    <Grid xs={12} item>
                      <Box
                        sx={{
                          background: "#F4F3F6",
                          padding: "20px",
                          borderRadius: "16px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontFamily: "Raleway-Extra-Bold",
                            flex: 1,
                          }}
                        >
                          Combien de colis de cette dimension souhaitez vous
                          envoyer:
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            onClick={() => {
                              handleIncrmntDecrmnt("decrement");
                            }}
                            sx={{
                              border: "1px solid #e0e0e0",
                              borderRadius: "8px",
                              width: "40px",
                              height: "40px",
                              backgroundColor: "#fff",
                            }}
                          >
                            <Remove />
                          </IconButton>
                          <Typography
                            variant="h6"
                            sx={{
                              margin: "0 15px",
                              minWidth: "20px",
                              textAlign: "center",
                            }}
                          >
                            {state?.item_quantity ?? 1}
                          </Typography>
                          <IconButton
                            onClick={() => {
                              handleIncrmntDecrmnt("increament");
                            }}
                            sx={{
                              border: "1px solid #e0e0e0",
                              borderRadius: "8px",
                              width: "40px",
                              height: "40px",
                              backgroundColor: "#fff",
                            }}
                          >
                            <Add />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              </Box>
            </DialogContent>
          }
          dialogFooter={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                padding: "20px",
                gap: "10px",
              }}
            >
              <CustomButton
                classesNames={"outlined_button"}
                value={"Annuler"}
                style={{ width: "200px" }}
                onClick={handleClearAllFeils}
              />

              {state?.isUpdate ? (
                <CustomButton
                  classesNames={"contained_button"}
                  value={"Update"}
                  style={{ width: "200px" }}
                  onClick={handleUpdateShipment}
                />
              ) : (
                <CustomButton
                  classesNames={"contained_button"}
                  value={"Ajouter"}
                  style={{ width: "200px" }}
                  onClick={handleAddShipment}
                />
              )}
            </div>
          }
        />

        <DraggableDialog
          maxWidth={"md"}
          open={state?.openLoginDialog}
          handleClose={() => {
            setState({ openLoginDialog: false });
          }}
          classes={classes}
          dialogBody={
            <DialogContent
              style={{
                display: "flex",
                background: "#FCE6DD",
              }}
              dividers
            >
              <Grid container spacing={spacing.spacing2}>
                <Grid xs={12} item>
                  <AuthAction
                    {...state}
                    {...props}
                    handleCloseLoginPage={handleCloseLoginPage}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          }
        />
      </div>
      <div className="mobile-screen" style={{ width: "100%" }}>
        <SendPackageMobileView
          {...state}
          {...props}
          selectedValue={selectedValue}
          handleGetItemList={handleGetItemList}
          handleShipmentTypeChange={handleShipmentTypeChange}
          onInputChange={onInputChange}
          handleIncrmntDecrmnt={handleIncrmntDecrmnt}
          handleClearAllFeils={handleClearAllFeils}
          handleUpdateShipment={handleUpdateShipment}
          handleAddShipment={handleAddShipment}
          handleLaunchGallery2={handleLaunchGallery2}
          setSelectedValue={setSelectedValue}
          handleCloseLoginPage={handleCloseLoginPage}
          EditShipmentItem={EditShipmentItem}
          handleRemoveItem={handleRemoveItem}
          handleEmptyShipment={handleEmptyShipment}
          handleOpenDialog={handleOpenDialog}
          handleContinue={handleContinue}
          handleRemoveImage={handleRemoveImage}
          handleUpadateState={handleUpdateState}
          handleUpadte={_handleUpdateState}
        />
      </div>
    </>
  );
}

export default SendPackage;
