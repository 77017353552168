import { Avatar, Badge, Button, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemIcon, Menu, MenuItem, Popover, Typography } from '@mui/material';
import React, { useState } from 'react'
import { AnouncementIcon, CartIconMarketPlace, FrankIcon, MailOrangeIcon, UserOrangeIcon } from '../../assets/images/FrankIcons';
import { AddBoxOutlined, ArrowDropDown, Close, KeyboardArrowDown, Logout, Search, Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { clearAllDataFromLocalStorage, getDataFromLocalStorage } from '../../utils/LocalStore.util';
import drawerBoxIcon from "../../assets/mobileImages/drawer-box-icon.png";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Store';
import frIcon from "../../assets/images/FrankIcons/fr-icon.png";
import enIcon from "../../assets/images/FrankIcons/en-icon.png";
import Toast from '../Toast/Toast';
import { strings } from '../../Store/Localization';
import { setUpdateLanguage } from '../../Store/MainAppReducer';
import InputField from '../TextFeild';
import { defaultLabelColors } from '../../utils/constants';
interface CartItem {
    id: number;
    name: string;
    quantity: number;
  }
function HeaderForMarketPlace() {
    let user = getDataFromLocalStorage("user");
    const dispatch = useDispatch<any>();
    let userDetails = getDataFromLocalStorage("user");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorEN, setAnchorEN] = React.useState<null | HTMLElement>(null);
    const [anchorEM, setAnchorEM] = useState<HTMLButtonElement | null>(null);
    const openForLang = Boolean(anchorEN);
    const selector = useSelector((state: RootState) => state.mainApp);
    const navigate = useNavigate();
    const [state, setState] = React.useReducer(
      (state: any, newState: any) => ({ ...state, ...newState }),
      {
        isFavorited: false,
      }
    );
    const handleClickForLang = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEN(event.currentTarget);
    };
    const handleCloseForLang = () => {
      setAnchorEN(null);
    };

 


   
  const [cartItems, setCartItems] = useState<CartItem[]>([
    { id: 1, name: "Product 1", quantity: 2 },
    { id: 2, name: "Product 2", quantity: 1 },
  ]);
  const handleClickforCart = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEM(event.currentTarget);
  };
  const handleCloseforCart = () => {
    setAnchorEM(null);
  };

  const openForCart = Boolean(anchorEM);
  const id = openForCart ? "cart-popover" : undefined;
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      navigate("/");
      Toast.showSuccess("Logout Succesfully");
    } catch (error) {}
  };
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };
  return (
    <Grid
    container
    spacing={2}
    className="display-flex"
    style={{
      background: "#F4C9B3",
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "30px",
    }}
  >
    <Grid xs={12} item style={{ zIndex: 99 }}>
      <div
        style={{
          padding: 15,
          background: "white",
          borderRadius: 24,
          marginTop: 20,
          // width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 14,
              width: "92%",
            }}
          >
            <a
              href="/"
              style={{ marginBottom: "-10px", marginLeft: 25 }}
            >
              <FrankIcon fill={"#ee6931"} height={55} />
            </a>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 14,
                flex: 1,
              }}
            >
              <div className="font_resp">
                <div
                  className="menu-component menu-container"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className="particulier font_resp"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Particulier{" "}
                    <KeyboardArrowDown
                      style={{
                        fontSize: "24px",
                        fontWeight: "lighter",
                      }}
                    />
                  </span>

                  <div className="dropdown">
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        navigate("/send-package");
                      }}
                    >
                      Envoyer un colis
                    </div>
                    <div className="dropdown-item">
                      Suivre mon colis
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        navigate("/transporter-un-colis");
                      }}
                    >
                      Devenir transporteur
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        navigate("/send-package");
                      }}
                    >
                      Démenagement{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="font_resp">
                <div
                  className="menu-component menu-container"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className="particulier font_resp"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Transporteur{" "}
                    <KeyboardArrowDown
                      style={{
                        fontSize: "24px",
                        fontWeight: "lighter",
                      }}
                    />{" "}
                  </span>

                  <div className="dropdown">
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        navigate("/transporter-un-colis");
                      }}
                    >
                      Devenir Transporteur
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        navigate("/transporter-un-colis");
                      }}
                    >
                      Voir les annonces
                    </div>
                  </div>
                </div>
              </div>

              <div className="font_resp">
                <div
                  className="menu-component menu-container"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className="particulier font_resp"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      navigate("/marketplace");
                    }}
                  >
                    Market place
                  </span>
                </div>
              </div>
            </div>

            <div
              className="hide_login_drawer"
              style={{ cursor: "pointer", marginTop: "10px" }}
              onClick={toggleDrawer(true)}
            >
              <svg
                width="23px"
                height="22px"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "-10px" }}
              >
                <path
                  d="M4 16H28M4 8H28M4 24H20"
                  stroke="#281208"
                  stroke-width="2.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {/* This is Drawer */}
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              style={{
                borderRadius: "0px",
                background: "none",
                height: "100vh",
              }}
              PaperProps={{
                sx: {
                  width: "300px",
                },
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, padding: "20px 0px 0px 20px" }}>
                  <FrankIcon fill={"#ee6931"} height={60} />
                </div>
                <div style={{ padding: "20px 20px 0px 0px" }}>
                  <IconButton onClick={toggleDrawer(false)}>
                    <Close
                      style={{ fontSize: "28px", color: "#000" }}
                    />
                  </IconButton>
                </div>
              </div>
              <Typography
                variant="body1"
                style={{ paddingLeft: "20px", flex: 1 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                  }}
                >
                  {userDetails ? (
                    <div className="user" style={{ cursor: "pointer" }}>
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                        }}
                        onClick={() => {
                          navigate("/chat");
                        }}
                      >
                        <MailOrangeIcon />{" "}
                        <span style={{ color: "#000" }}>Mail</span>
                      </div>
                    </div>
                  ) : null}

                  {userDetails ? (
                    <div
                      className="compte"
                      style={{
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      <div className="user" style={{}}>
                        <div
                          onClick={handleClick}
                          style={{
                            display: "flex",
                            gap: "20px",
                            fontFamily: "Raleway-Variable",
                            fontWeight: "600",
                          }}
                        >
                          <UserOrangeIcon /> <span>Account</span>
                        </div>
                      </div>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            width: "200px",
                            borderRadius: "12px",
                            overflow: "visible",
                            filter:
                              "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform:
                                "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          className="dropdown-item"
                          style={{ fontFamily: "Raleway-Variable" }}
                          onClick={() => {
                            navigate("/profile");
                          }}
                        >
                          <Avatar /> Profil
                        </MenuItem>

                        <Divider />

                        <MenuItem
                          className="dropdown-item"
                          style={{ fontFamily: "Raleway-Variable" }}
                          // onClick={handleClose}
                        >
                          <ListItemIcon>
                            <Settings fontSize="small" />
                          </ListItemIcon>
                          Settings
                        </MenuItem>
                        <MenuItem
                          className="dropdown-item"
                          style={{ fontFamily: "Raleway-Variable" }}
                          // onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <Logout fontSize="small" />
                          </ListItemIcon>
                          Logout
                        </MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    <div style={{ gap: "20px", marginTop: "20px" }}>
                      <div
                        onClick={() => {
                          navigate("/login");
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <span
                          className="particulier"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                          }}
                        >
                          S'inscrire
                        </span>
                      </div>

                      <div
                        onClick={() => {
                          navigate("/login");
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "20px",
                        }}
                      >
                        <span
                          className="particulier  "
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          Se connecter
                        </span>
                      </div>
                    </div>
                  )}

                  <div
                    className="compte"
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <div className="user">
                      <div
                        className="particulier"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={handleClickForLang}
                      >
                        {selector.lang?.toUpperCase()} <ArrowDropDown />
                      </div>
                    </div>
                  </div>
                </div>
              </Typography>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <img
                    src={drawerBoxIcon}
                    height={"60%"}
                    alt="box-icon"
                  />
                </div>
              </Typography>
            </Drawer>
            {/*Drawer ends here*/}

            <div className="login_area">
              <div className="right">
                <div className="font_resp">
                  <div
                    className="menu-component menu-container"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="particulier font_resp"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Suivre mon colis
                    </span>
                  </div>
                </div>

                {userDetails ? (
                  <div
                    className="tracker"
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className="line-mail"
                      onClick={() => {
                        navigate("/chat");
                      }}
                    >
                      <MailOrangeIcon />
                    </div>
                  </div>
                ) : null}

                {userDetails ? (
                  <div
                    className="compte"
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <div className="user" style={{}}>
                      <div onClick={handleClick}>
                        <UserOrangeIcon />
                      </div>
                    </div>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          width: "200px",
                          borderRadius: "12px",
                          overflow: "visible",
                          filter:
                            "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      <MenuItem
                        className="dropdown-item"
                        style={{ fontFamily: "Raleway-Variable" }}
                        onClick={() => {
                          navigate("/profile");
                        }}
                      >
                        <Avatar /> Profil
                      </MenuItem>
                      <MenuItem
                        className="dropdown-item"
                        style={{
                          fontFamily: "Raleway-Variable",
                          gap: 10,
                        }}
                        onClick={() => {
                          navigate("/my-jobs");
                        }}
                      >
                        <AnouncementIcon
                          height={25}
                          width={25}
                          fill={"#bdbdbd"}
                        />
                        {"Transactions"}
                      </MenuItem>
                      <MenuItem
                        className="dropdown-item"
                        style={{
                          fontFamily: "Raleway-Variable",
                          gap: 10,
                        }}
                        onClick={() => {
                          navigate("/my-anounces");
                        }}
                      >
                        <AnouncementIcon
                          height={25}
                          width={25}
                          fill={"#bdbdbd"}
                        />{" "}
                        {strings?.my_parcels}
                      </MenuItem>
                      <Divider />

                      <MenuItem
                        className="dropdown-item"
                        style={{ fontFamily: "Raleway-Variable" }}
                        // onClick={handleClose}
                      >
                        <ListItemIcon>
                          <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                      </MenuItem>
                      <MenuItem
                        className="dropdown-item"
                        style={{ fontFamily: "Raleway-Variable" }}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #F4C7B8 ",
                      borderRadius: 4,
                      padding: 5,
                      color: "#ee6931",
                      gap: 10,
                    }}
                  >
                    <div
                      onClick={() => {
                        navigate("/login");
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="particulier font_resp"
                        style={{
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      >
                        S'inscrire
                      </span>
                    </div>
                    <div
                      style={{
                        width: "2px",
                        height: "10px",
                        background: "#F4C7B8",
                      }}
                    ></div>
                    <div
                      onClick={() => {
                        navigate("/login");
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="particulier font_resp"
                        style={{
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      >
                        Se connecter
                      </span>
                    </div>
                  </div>
                )}

                <div
                  className="compte"
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <div className="user">
                    <div
                      className="particulier font_resp"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={handleClickForLang}
                    >
                      {selector.lang?.toUpperCase()} <ArrowDropDown />
                    </div>

                    <Menu
                      id="account-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEN}
                      open={openForLang}
                      onClose={handleCloseForLang}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          // width: "200px",
                          borderRadius: "12px",
                          overflow: "visible",
                          filter:
                            "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          dispatch(setUpdateLanguage("fr"));
                          handleCloseForLang();
                        }}
                      >
                        <div
                          className="display-flex"
                          style={{ gap: 10 }}
                        >
                          <img
                            src={frIcon}
                            height={30}
                            width={30}
                            alt="fr-icon"
                          />
                          FR
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          dispatch(setUpdateLanguage("en"));
                          handleCloseForLang();
                        }}
                      >
                        <div
                          className="display-flex"
                          style={{ gap: 10 }}
                        >
                          <img
                            src={enIcon}
                            height={20}
                            width={30}
                            alt="en-icon"
                          />
                          EN
                        </div>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderTop: "1px solid lightgray",
            padding: 10,
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <div style={{ flex: 1 }}>
            <InputField
              placeHolder={"Rechercher des produits"}
              input_value={state?.searched_prod}
              input_name={"searched_prod"}
              input_style={{
                background: "#F4F3F6",
                minWidth: "200px",
                width: "600px",
                maxWidth: "772px",
                borderRadius: "12px",
              }}
              readonly={true}
              startIcon={<Search style={{ opacity: "50%" }} />}
            />
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            <Button 
              variant="contained"
              startIcon={
                <AddBoxOutlined style={{ fontSize: "26px" }} />
              }
              onClick={()=>{navigate("/add-product-individual")}}
              sx={{
                backgroundColor: defaultLabelColors.main_orange, // Same as the blue color in the image
                color: "#fff",
                borderRadius: "12px",
                padding: "10px 20px",
                textTransform: "none",
                fontSize: "16px",
                fontFamily: "Raleway-Variable",
                fontWeight: "bold",
                width: "300px",
                "&:hover": {
                  backgroundColor: defaultLabelColors.main_orange,
                },
              }}
            >
              Vendre un article
            </Button>
            <Button
              variant="contained"
              onClick={handleClickforCart}
              sx={{
                backgroundColor: defaultLabelColors.main_blue,
                color: "#fff",
                borderRadius: "12px",
                textTransform: "none",
                fontSize: "16px",
                textAlign: "center",
                fontFamily: "Raleway-Variable",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: defaultLabelColors.main_blue,
                },
              }}
            >
              <IconButton aria-describedby={id}>
                <Badge badgeContent={cartItems.length} color="primary">
                  <CartIconMarketPlace
                    fill={defaultLabelColors.white}
                  />
                </Badge>
              </IconButton>
            </Button>

            <Popover
              id={id}
              open={openForCart}
              anchorEl={anchorEM}
              onClose={handleCloseforCart}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "16px", // Apply border radius here
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for effect
                  padding: "10px",
                },
              }}
            >
              <List>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Variable",
                      fontWeight: 700,
                      color: defaultLabelColors.main_blue,
                      fontSize: "22px",
                    }}
                  >
                    Votre panier
                  </div>
                  <div>
                    <CartIconMarketPlace
                      fill={defaultLabelColors.main_blue}
                    />
                  </div>
                </div>
                {cartItems.length > 0 ? (
                  <div
                    className="card-container"
                    style={{ marginTop: "5px", width: "400px" }}
                  >
                    <img
                      alt="prod-img"
                      src={
                        "https://media.istockphoto.com/id/1411619454/photo/end-table-round-coffee-table-isolated-on-white.jpg?s=1024x1024&w=is&k=20&c=1RTn3iimqMcYoGZu_io2END5yxZVOw0d6jV-nrlTPWE="
                      }
                      className="card-image"
                    />
                    <div className="card-details">
                      <div
                        className="card-title"
                        style={{ fontSize: 17 }}
                      >
                        {/* {handleLocalize({
                       fr: i?.packageType?.item_name_fr,
                       en: i?.packageType?.item_name_en,
                     }) ?? ""} */}
                        Table
                      </div>
                      <div className="card-dimensions">
                        {/* {i?.productSize[0]?.size ?? ""} */}
                        large
                      </div>
                    </div>
                    <div className="card-quantity">
                      x2
                      {/* {i?.prod_quantity} */}
                    </div>
                  </div>
                ) : (
                  <ListItem>
                    <Typography>No items in the cart.</Typography>
                  </ListItem>
                )}
              </List>
            </Popover>
          </div>
        </div>
      </div>
    </Grid>
  </Grid>
  )
}

export default HeaderForMarketPlace
