import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import noImage from "../../assets/images/FrankIcons/noImageAvailable.png";
import React, { useEffect, useState } from "react";
import NewHeader from "../../UiComponent/NewHeader";
import InputField from "../../UiComponent/TextFeild";
import apiServices from "../../services/RequestHandler";
import noChatimage from "../../assets/images/FrankIcons/noChatimage.png";
import moment from "moment";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import { defaultLabelColors } from "../../utils/constants";
import SenderChat from "./SenderChat";
import AccepterChat from "./AccepterChat";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { RootState } from "../../Store";
import { useSelector } from "react-redux";
import { strings } from "../../Store/Localization";
import { Helmet } from "react-helmet";
import { makeStyles } from "@mui/styles";
import {
  checkForPermission,
  requestPermission,
} from "../../utils/notificationListner";
import { useLocation, useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  scrollableArea: {
    height: "600px", // Give the area a height to enable scrolling
    width: "100%",
    overflowY: "auto", // Enable vertical scrolling
    // padding: "10px",

    /* Custom scrollbar for WebKit browsers */
    "&::-webkit-scrollbar": {
      width: "12px", // Width of the scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ee6931", // Color of the scrollbar thumb
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5", // Background of the scrollbar track
    },

    /* Custom scrollbar for Firefox */
    scrollbarWidth: "thin", // Thin scrollbar for Firefox
    scrollbarColor: "#ee6931 #f5f5f5", // Thumb and track colors for Firefox
  },
}));

function SenderInbox(props: any) {
  const classes = useStyles();
  const [chatHeads, setChatHeads] = useState<Array<any>>([]);
  const selector = useSelector((state: RootState) => state.mainApp);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      user: getDataFromLocalStorage("user"),
      loading_screen: true,
      showCustomerChat: false,
      showAccepterChat: false,
    }
  );

  useEffect(() => {
    handleGetAllChats();
  }, []);

  useEffect(() => {}, [selector]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const screen = queryParams.get("screen"); // "messaging"
  const resId = queryParams.get("res_id"); // "391302"

  // useEffect(() => {
  //   setState({
  //     chat_type: screen === "messaging" ? "sender" : "transporter",
  //   });
  // }, []);

  const handleGetAllChats = async () => {
    try {
      let resp = await apiServices.getFromApi("chat/announcement-chats", "");
      setChatHeads(resp?.data);
    } catch (error) {}
  };

  const handleGetShipmentRates = async (item: any, val?: boolean) => {
    try {
      const resp = await apiServices.getFromApi(
        `c2c-shipments/${item?.ship_id}`,
        ""
      );
      if (val) {
        setState({
          chat_details: resp?.data,
        });
        return;
      }

      setState({
        chat_details: resp?.data,
        chat_type:
          `${item?.customer_id}` === `${state?.user?.id}`
            ? "sender"
            : "transporter",
        selected_item: item,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetShipmentRatesWhenNOtification = async (
    item: any,
    val?: boolean
  ) => {
    try {
      const resp = await apiServices.getFromApi(
        `c2c-shipments/${item?.ship_id}`,
        ""
      );
      if (val) {
        setState({
          chat_details: resp?.data,
        });
        return;
      }

      setState({
        chat_details: resp?.data,
        chat_type:
          `${item?.customer_id}` === `${state?.user?.id}`
            ? "sender"
            : "transporter",
        selected_item: item,
      });
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (selector.messaging) {
      const message = JSON.parse(selector.messaging ?? "{}");
      if (message.navigation_id) {
        if (state.selected_item) {
          handleGetShipmentRates(state.selected_item);
        }
      }
    }
  }, [selector.messaging]);

  const renderAnnounceCard = () => (
    <>
    {state?.chat_details?.shipment_items?.length > 1 ? 
      <div className="card-container" style={{marginTop:5}}>
         <img
              src={state?.chat_details?.shipment_items?.[0]?.prod_images[0]}
              className="card-image"
              alt="prod-img"
            />
            <div className="card-details">
              <div className="card-title" style={{ fontSize: 18 }}>
                 
                { `Lot de (${state?.chat_details?.shipment_items?.length}) colis` }
              </div>
              <p
            style={{
              color: "#080626",
              font: "14px",
              fontWeight: "500",
              fontFamily:'Raleway-Semi-Bold'
            }}
          >
            {" "}
            Postée le{" "}
            {moment(state?.chat_details?.created_at).format("DD/MM/YYYY")}{" "}
          </p>
             </div>
             <div
              className="card-quantity"
              style={{
                paddingRight: "20px",
                color: defaultLabelColors.main_orange,
                fontSize: "26px",
              }}
            >
              
              {numberFormatWithoutCurrency(state?.chat_details?.ship_price ?? 0.0)}
              &nbsp; €
              
            </div>
      </div> :
       <div className="card-container">
      <img
        src={
          state?.chat_details?.shipment_items?.[0]?.prod_images?.[0] ??
          state?.chat_details?.images?.[0] ??
          noImage
        }
        className="card-image"
        alt="prod-img"
      />
      <div className="card-details" style={{ flex: 1 }}>
        <div className="card-title" style={{ fontSize: 20 }}>
          {handleLocalize({
            en: state?.chat_details?.shipment_items?.[0]?.item_name_en,
            fr: state?.chat_details?.shipment_items?.[0]?.item_name_fr,
          }) ??
            state?.chat_details?.shipment_items?.[0]?.prod_name ??
            ""}
        </div>

        <div
          className="card-quantity"
          style={{
            color: defaultLabelColors.main_orange,
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          <p
            style={{
              color: "#080626",
              font: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            Postée le{" "}
            {moment(state?.chat_details?.created_at).format("DD/MM/YYYY")}{" "}
          </p>
        </div>
      </div>
      <div
        style={{
          fontFamily: "Raleway-Variable",
          fontWeight: "700",
          fontSize: "22px",
          color: defaultLabelColors.main_orange,
        }}
      >
        {numberFormatWithoutCurrency(state?.chat_details?.ship_price)} €
      </div>
    </div>}
    </>
  );

  return (
    <>
      <Helmet>
        <title>Le Chat | Discutez avec la Communauté Frank</title>
        <link rel="canonical" href="https://www.v2internal.frankme.com/chat" />
        <meta
          name="description"
          content="Discutez en temps réel avec d'autres membres de la communauté Frank. Partagez vos expériences et restez connecté avec des transporteurs et clients."
        />
      </Helmet>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              padding: "60px 160px 70px 150px",
              gap: 10,
              paddingBottom: "30px",
            }}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                background: "#fff",
                borderRadius: "16px",
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                height: "90vh",
              }}
              className={classes?.scrollableArea}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1 }}>
                  <h2
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: "22px",
                      fontWeight: 500,
                    }}
                  >
                    {strings?.inbox}
                  </h2>
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    fontFamily: "Raleway-Bold",
                    color: defaultLabelColors.main_blue,
                  }}
                  onClick={checkForPermission}
                >
                  {" "}
                  Enable notification
                </div>
              </div>

              <Box
                sx={{
                  flex: 1,
                  backgroundColor: "white",
                  overflowY: "scroll",
                  maxHeight: "90vh",
                }}
              >
                {chatHeads?.map((item: any, index: any) => {
                  let item_name = "";
                  if (item?.shipment_items?.length > 1) {
                    item_name = item?.shipment_items?.[0]?.item_name_fr;
                  } else {
                    item_name = item?.shipment_items?.[0]?.item_name_en;
                  }

                  return (
                    <>
                      <Card
                        elevation={0}
                        key={`CHAT_HEAD_${index + 1}`}
                        onClick={() => handleGetShipmentRates(item)}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: 1.5,
                          cursor: "pointer",
                        }}
                      >
                        <Avatar
                          src={
                            item.shipment_items?.[0]?.prod_images?.[0] ??
                            state?.chat_details?.images?.[0] ??
                            noImage
                          }
                          sx={{
                            height: 60,
                            width: 60,
                            borderRadius: 1,
                            marginRight: 2,
                            backgroundColor: "#f0f0f0",
                            objectFit: "contain",
                          }}
                          alt="Product Image"
                        />
                        <CardContent sx={{ flex: 1 }}>
                          <Typography
                            variant="h6"
                            color="textPrimary"
                            style={{
                              fontFamily: "Raleway-Bold",
                              fontSize: "14px",
                            }}
                          >
                            {
                            item?.shipment_items?.length > 1 ? `Lot de (${item?.shipment_items?.length}) colis`:
                            handleLocalize({
                              en: item?.shipment_items?.[0]?.item_name_en,
                              fr: item?.shipment_items?.[0]?.item_name_fr,
                            }) ??
                              item?.shipment_items?.[0]?.prod_name ??
                              handleLocalize({
                                en: item?.product_title?.en,
                                fr: item?.product_title?.fr,
                              }) ??
                              ""}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {item.customer_id === String(state?.user?.id)
                              ? item.is_agree === false
                                ? `Vous avez refusé l'offre de ${item.fullname}`
                                : item.is_agree === true
                                ? `vous avez accepté l’offre de ${item.fullname}`
                                : `${
                                    item.added_by ?? "Someone"
                                  } fait une offre de ${
                                    item.ship_price ?? item?.price
                                  }€ à votre annonce`
                              : `${"votre"} fait une offre de ${
                                  item.ship_price ?? item?.price
                                }€`}

                            {item?.product_title ? (
                              <>
                                 <br />
                                <Typography
                                  variant="caption"
                                  color="primary"
                                  style={{
                                    fontFamily: "Raleway-Bold",
                                  }}
                                >
                                  {"Market Place Item"}
                                </Typography>
                              </>
                            ) : null}
                          </Typography>
                          {item?.customer_id === String(state?.user?.id) ? (
                            <Typography
                              variant="caption"
                              color="primary"
                              style={{
                                fontFamily: "Raleway-Bold",
                              }}
                            >
                              {item?.product_title
                                ? "Market Place Item"
                                : "Your Announcement"}
                            </Typography>
                          ) : null}
                        </CardContent>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontFamily: "Raleway-Variable" }}
                        >
                          {moment(item.created_at_tz).format("HH:mm")}
                        </Typography>
                      </Card>
                      <Divider />
                    </>
                  );
                })}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={7.5}
              sx={{
                background: "#fff",
                borderRadius: "16px",
                padding: "16px",
                height: "90vh",
              }}
            >
              <div
                style={{
                  height: "90vh",
                }}
              >
                {state?.chat_type === "sender" ? (
                  <>
                    {renderAnnounceCard()}
                    <SenderChat
                      handleGetShipmentRates={handleGetShipmentRates}
                      {...props}
                      {...state}
                      handleSwitChat={() => {
                        setState({
                          chat_type: "transporter",
                        });
                      }}
                    />
                  </>
                ) : state?.chat_type === "transporter" ? (
                  <>
                    {renderAnnounceCard()}
                    <AccepterChat
                      handleGetShipmentRates={handleGetShipmentRates}
                      {...props}
                      {...state}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={noChatimage}
                        alt="no-chat-img"
                        style={{ opacity: "0.7" }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default SenderInbox;
