import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    border: "1px solid #eee",
    borderRadius: "16px",
    borderLeft:'3px solid #ee6931',
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    // maxWidth: "600px
    margin: "0 auto",
    // [theme.breakpoints.down("sm")]: {
    //   padding: "12px",
    // },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flag: {
    width: "40px",
    height: "auto",
  },
  details: {
    marginTop: "16px",
  },
  detailRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
  },
  orangeLine: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "4px",
    backgroundColor: "#ee6931", // Matches the orange color
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px",
  },
}));

const BankCard = (props: any) => {
  const classes = useStyles();
  const { cardNumber, AcountName, BankName } = props;

  return (
    <Box className={classes.cardContainer}>
      <Box className={classes.orangeLine} />
      <Box className={classes.header}>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          style={{ fontFamily: "Raleway-Variable" }}
        >
          Account Holder Name
        </Typography>
        <img
          className={classes.flag}
          src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
          alt="French flag"
        />
      </Box>
      <Box
        className={classes.details}
        style={{ fontFamily: "Raleway-Variable" }}
      >
        <Box className={classes.detailRow}>
          <Typography variant="body1">{AcountName ?? "---"}</Typography>
        </Box>
        <Box className={classes.detailRow}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ fontFamily: "Raleway-Variable" }}
          >
            Bank Name
          </Typography>
          <Typography
            variant="body1"
            fontWeight="bold"
            style={{ fontFamily: "Raleway-Variable" }}
          >
            {BankName ?? "LA BANQUE POSTALE"}
          </Typography>
        </Box>
        <Box className={classes.detailRow}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ fontFamily: "Raleway-Variable" }}
          >
            Account No.
          </Typography>
          <Typography variant="body1">
            { `•••• •••• •••• ${cardNumber ?? 2289}`}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Typography
          variant="body1"
          fontWeight="bold"
          style={{ fontFamily: "Raleway-Variable" }}
        >
          €
        </Typography>
      </Box>
    </Box>
  );
};
BankCard.propTypes = {
  cardNumber: PropTypes.any,
  AcountName: PropTypes.any,
  BankName: PropTypes.any,
};
export default BankCard;
