import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixed,
} from "../../utils/helper";
import { defaultLabelColors } from "../../utils/constants";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import { strings } from "../../Store/Localization";
import CustomButton from "../../UiComponent/CustomButton";
import apiServices from "../../services/RequestHandler";
import {
  Box,
  Button,
  DialogContent,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Toast from "../../UiComponent/Toast/Toast";
import axios from "axios";
import { BASE_URL } from "../../services/HttpProvider";
import InputField from "../../UiComponent/TextFeild";
import { CameraAlt, Collections, Send } from "@mui/icons-material";
import paymentImage from "../../assets/images/paymentImage.png";
import { decrypt } from "../../utils/Encrypt";
import { loadStripe } from "@stripe/stripe-js";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckout from "../MyAnnounces/StripeCheckout";
import OfferAcceptedCard from "../../UiComponent/OfferAcceptedCard";
import OfferAcceptedCarForSender from "../../UiComponent/OfferAcceptedCard/OfferAcceptedCarForSender";
import { makeStyles } from "@mui/styles";
import { CameraGreyIcon, ImageGreyIcon } from "../../assets/images/FrankIcons";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  scrollableArea: {
    height: "600px", // Give the area a height to enable scrolling
    width: "100%",
    overflowY: "auto", // Enable vertical scrolling

    /* Custom scrollbar for WebKit browsers */
    "&::-webkit-scrollbar": {
      width: "12px", // Width of the scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ee6931", // Color of the scrollbar thumb
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5", // Background of the scrollbar track
    },

    /* Custom scrollbar for Firefox */
    scrollbarWidth: "thin", // Thin scrollbar for Firefox
    scrollbarColor: "#ee6931 #f5f5f5", // Thumb and track colors for Firefox
  },
}));

export function MessageComponent({ item, user, chatMessages, index }: any) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [viewFile, setViewFile] = useState(false);

  const [fileURI, setFileUri] = useState("");
  const status = item?.sender_id !== `${user?.id ?? ""}`;
  const inputDate = item?.created_at;
  let prev_msg_date =
    index === 0
      ? chatMessages?.[index]?.created_at
      : chatMessages?.[index - 1]?.created_at;
  const prev_msg_date_moment = moment(prev_msg_date);
  const renderDateHeader = useMemo(() => {
    return (
      <div style={{ alignItems: "center", margin: "10px 0px" }}>
        <span style={{ color: "gray", fontSize: 12 }}>
          {prev_msg_date_moment.format("MMM D, YYYY")}
        </span>
      </div>
    );
  }, [prev_msg_date_moment]);

  const handleToggleAudio = async (value?: boolean) => {
    try {
      // const url = item?.audio_url;
      // if (value) {
      //   // @ts-ignore
      //   let whoosh = new Sound(url, null, (error) => {
      //     if (error) {
      //       console.log("failed to load the sound", error);
      //       return;
      //     }
      //     whoosh.stop();
      //     setIsPlaying(false);
      //   });
      // } else {
      //   // @ts-ignore
      //   let whoosh = new Sound(url, null, (error) => {
      //     if (error) {
      //       console.log("failed to load the sound", error);
      //       return;
      //     }
      //     setIsPlaying(true);
      //     // Play the sound with an onEnd callback
      //     whoosh.play((success) => {
      //       if (success) {
      //         setIsPlaying(false);
      //       } else {
      //         console.log("playback failed due to audio decoding errors");
      //       }
      //     });
      //   });
      // }
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  };
  const handleStopAudio = async (value?: boolean) => {
    try {
      // const url = item?.audio_url;
      // // @ts-ignore
      // let whoosh = new Sound(url, null, (error) => {
      //   if (error) {
      //     console.log("failed to load the sound", error);
      //     return;
      //   }
      //   whoosh.pause();
      //   setIsPlaying(false);
      // });
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  };

  let loc: any = null;
  if (item?.file_type === "location" && typeof item?.location === "string") {
    loc = JSON.parse(item?.location);
  } else {
    loc = item?.location;
  }
  return (
    <div key={`ITEM_MESSAGE_01_${index + 1}`}>
      {item?.is_negotiate || item?.order_details
        ? null
        : index === 0
        ? renderDateHeader
        : prev_msg_date_moment.format("YYYY-MM-DD") ===
          prev_msg_date_moment.format("YYYY-MM-DD")
        ? null
        : renderDateHeader}
      <div
        style={{
          borderRadius: 10,
          alignItems:
            item?.is_negotiate || item?.order_details
              ? "center"
              : status
              ? "flex-start"
              : "flex-end",
          justifyContent:
            item?.is_negotiate || item?.order_details
              ? "center"
              : status
              ? "flex-start"
              : "flex-end",
          marginBottom: 10,
          display: item?.is_negotiate || item?.order_details ? "none" : "flex",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 10,
            display: "flex",
            justifyContent:
              item?.is_negotiate || item?.order_details
                ? "center"
                : status
                ? "flex-start"
                : "flex-end",
          }}
        >
          <div
            style={{
              overflowWrap: "anywhere",
              fontFamily: "Raleway-Variable",
              padding: "7px 10px",
              paddingBottom: 13,
              maxWidth:
                item?.is_negotiate || item?.order_details
                  ? "100%"
                  : `calc(100% / 1.5)`,
              minWidth: 90,
              position: "relative",
              backgroundColor: item?.order_details
                ? color_theme.card_background
                : item?.is_negotiate
                ? color_theme.card_background
                : status
                ? color_theme.main_orange
                : "lightgrey",
              borderRadius: 10,
            }}
          >
            {item?.order_details ||
            item?.is_negotiate ? null : item?.audio_url ? null : item?.file_url ? (
              <div>
                {item?.file_type === "doc" ? (
                  <div
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h1>DOCICON</h1>
                    {/* <Ionicons
                      name="document-attach-outline"
                      size={20}
                      color={status ? color_theme.white : color_theme.black}
                    /> */}
                    <span
                      style={{
                        color: status ? color_theme.white : color_theme.black,
                        fontSize: 12,
                        fontFamily: "Epilogue",
                        lineHeight: 16,
                        paddingLeft: 10,
                      }}
                      // numberOfLines={1}
                    >
                      {item?.file_name ?? "Document"}{" "}
                    </span>
                  </div>
                ) : (
                  <div>
                    <div
                    // onPress={() => {
                    //   setViewFile(true);
                    //   setFileUri(item?.file_url);
                    // }}
                    >
                      <img
                        style={{
                          height: 200,
                          width: 200,
                          objectFit: "contain",
                        }}
                        src={item?.file_url}
                        alt="imge"
                      />
                      {/* <Image
                        source={{
                          uri: item?.file_url,
                        }}
                      /> */}
                    </div>
                  </div>
                )}
              </div>
            ) : item?.message ? (
              <span
                style={{
                  color: status ? color_theme.white : color_theme.black,
                  fontSize: 16,
                  fontFamily: "Raleway-Variable",
                }}
              >
                {item?.message ?? ""}
              </span>
            ) : item?.file_type === "location" ? (
              <div
                style={{
                  height: 150,
                  width: "100%",
                  background: "red",
                }}
                // onPress={async () => {
                //   const lat = loc.coordinates[0];
                //   const lng = loc.coordinates[1];
                //   const scheme = Platform.select({
                //     ios: `maps://${loc.coordinates[0]},${loc.coordinates[1]}?q=`,
                //     android: `geo:${loc.coordinates[0]},${loc.coordinates[1]}?q=`,
                //   });
                //   const latLng = `${lat},${lng}`;
                //   const label = `${item?.sender_name}'s location`;
                //   const url = Platform.select({
                //     ios: `${scheme}${label}@${latLng}`,
                //     android: `${scheme}${latLng}(${label})`,
                //   });
                //   Linking.openURL(url as string);
                // }}
              >
                {/* <MapView
                  scrollEnabled={true}
                  rotateEnabled={true}
                  zoomEnabled={true}
                  provider={PROVIDER_GOOGLE}
                  style={StyleSheet.absoluteFillObject}
                  region={{
                    latitude: loc.coordinates[0] ?? 48.85806249896775,
                    longitude: loc.coordinates[1] ?? 2.3535707423777468,
                    latitudeDelta: LATITUDE_DELTA,
                    longitudeDelta: LONGITUDE_DELTA,
                  }}
                >
                  <Marker
                    coordinate={{
                      latitude: loc?.coordinates[0] ?? 48.85806249896775,
                      longitude: loc?.coordinates[1] ?? 2.3535707423777468,
                    }}
                  >
                    <Image
                      source={MapMarker}
                      style={{
                        height: 20,
                        width: 20,
                        resizeMode: "contain",
                      }}
                    />
                  </Marker>
                </MapView> */}
              </div>
            ) : null}
          </div>
        </div>
        {!item?.is_negotiate && !item?.order_details && (
          <div
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <span
              style={{
                marginLeft: 5,
                paddingRight: 5,
                color: color_theme.black,
                fontSize: 12,
                fontFamily: "Raleway-Variable",
                marginTop: 2,
              }}
            >
              {moment(
                item?.created_at
                  ? `${item?.created_at}`.endsWith("Z")
                    ? item?.created_at
                    : item?.created_at + "Z"
                  : new Date()
              ).format("HH:mm")}
            </span>
          </div>
        )}
      </div>
      {/* {viewFile && (
        <ViewFile
          onClose={() => {
            setViewFile(false);
            setFileUri("");
          }}
          open={viewFile}
          url={fileURI}
        />
      )} */}
    </div>
  );
}

export const color_theme = {
  white: "#FFFFFF",
  pastel: "#FAFAFA",
  main_orange: "#EE6931",
  light_orange: "#F9C9B3",
  dark_blue: "#191847",
  main_blue: "#3026F1",
  black: "#1E1E1E",
  sand: "#F9D9B6",
  disabled: "#F1EFF1",
  black_10: "rgba(0, 0, 0, 0.1)", // black with 10% Opacity
  black_20: "rgba(0, 0, 0, 0.2)", // black with 20% Opacity
  black_40: "rgba(0, 0, 0, 0.4)", // black with 40% Opacity
  green: "#6FCF00",
  red: "red",
  red_2: "#790000",
  red_500: "red.500",
  card_background: "#FAFAFA",
  card_border: "#EEEEEE",
  card_active_background: "#FFEBE2",
  card_active_border: "#EE6931",
};

function AccepterChat(props: any) {
  const classes = useStyles();
  const selector = useSelector((state: RootState) => state.mainApp);
  const scrollRef = useRef<any>(null);
  const [open, setOpen] = useState(false);


  

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      user: getDataFromLocalStorage("user"),
      messages: [],
      activity:false
    }
  );
  useEffect(() => {}, [selector.lang]);

  
   
  const handleSendMessage = () => {
    try {
      setState({
        disable_btn: true,
      });
      if (state?.message === " " || !state?.message) {
        setState({
          disable_btn: false,
        });
        return;
      } else {
        if (!_last_message) {
          if (Number(state?.message) < Number(state?.chat_details.ship_price)) {
            Toast.Error(
              selector.lang === "en"
                ? "You cannot propose amount less than delivery cost"
                : `Vous ne pouvez pas proposer un montant plus bas que le montant actuel`
            );
            setState({
              disable_btn: false,
            });
            return;
          }
 
          handleSentFirstMessage();
          return;
        }
        if (_last_message?.is_agree === false) {
          if (Number(state?.message) < Number(state?.chat_details.ship_price)) {
            Toast.Error(
              selector.lang === "en"
                ? "You cannot propose amount less than delivery cost"
                : `Vous ne pouvez pas proposer un montant plus bas que le montant actuel`
            );
            setState({
              disable_btn: false,
            });
            return;
          }
          handleNewMessageWithNegotiate();
        } else {
          handleNewMessage(null, null);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    setState(
      {
        chat_details: props?.chat_details,
      }[JSON.stringify(props?.chat_details)]
    );
    let total_amt = 0;
    if (props?.chat_details?.handling) {
      total_amt =
        props?.chat_details?.order_amount_detail?.sub_total > 0
          ? Number(props?.chat_details?.order_amount_detail?.sub_total ?? 0)
          : Number(
              props?.chat_details?.agree_ship_price ??
                props?.chat_details?.ship_price ??
                0
            ) +
            Number(
              (Number(
                props?.chat_details?.agree_ship_price ??
                  props?.chat_details?.ship_price ??
                  0
              ) /
                100) *
                Number(props?.chat_details?.handling?.handling_charges_percent)
            ) +
            Number(props?.chat_details?.service_charges ?? 0);
    } else {
      total_amt =
        props?.chat_details?.order_amount_detail?.sub_total > 0
          ? Number(props?.chat_details?.order_amount_detail?.sub_total ?? 0) +
            Number(props?.chat_details?.service_charges ?? 0)
          : Number(
              props?.chat_details?.agree_ship_price ??
                props?.chat_details?.ship_price ??
                0
            ) + Number(props?.chat_details?.service_charges ?? 0);
    }
    setState({
      total_amt,
    });
    getMessages();
    handleGetUserDetails(props?.chat_details?.acc_id)

    console.log("chat_details",state?.chat_details);
    
  }, [JSON.stringify(props?.chat_details)]);

  React.useEffect(() => {
    if (selector.messaging) {
      getMessages();
    }
  }, [selector.messaging]);

  
  const handleGetUserDetails = async (item: any) => {
    try {
      const resp = await apiServices.getFromApi(
        `c2c/get-user-details/${item}`,
        ""
      );
      console.log("resp hun", resp);

      let avg_rating_ = resp.data?.ratings2?.reduce((a: any, b: any) => {
        return a + b.rating;
      }, 0);
      let avg_rating = isNaN(avg_rating_ / resp?.data?.ratings2?.length)
        ? 0
        : avg_rating_ / resp?.data?.ratings2?.length;
      setState({
 transDetails:resp?.data,
        details: {
          ...resp.data,
          avg_rating,
        },
      });
    } catch (error) {}
  };

  const stripePromise = loadStripe(
    "pk_test_51PHLwERrtK56w4VJovEUBRsfDb0K90Mo3tt7QrypEedKpj9HuTszcu8bnI8SnXsmA7EWSwZ87LorkcfAHi7zXz4l00kogJUBL5"
    // "pk_live_51PHLwERrtK56w4VJcMQLW2zbn7LTZPNZnBLUmFdMIakX5v5HKsPRllmYUMmWJXvmPVJaptcEN8uxmQdzDNVJYFUC0050uiD5gE"
  );



  useEffect(() => {
    if (
      props?.chat_details?.ship_status_level === 2 &&
      props?.chat_details?.payment_status !== "Paid"
    ) {
      initPayment();
    }
  }, [JSON.stringify(props?.chat_details)]);

  const initPayment = async () => {
    try {
      let total_amt = 0;
      if (props?.chat_details?.handling) {
        total_amt =
          props?.chat_details?.order_amount_detail?.sub_total > 0
            ? Number(props?.chat_details?.order_amount_detail?.sub_total ?? 0)
            : Number(
                props?.chat_details?.agree_ship_price ??
                  props?.chat_details?.ship_price ??
                  0
              ) +
              Number(
                (Number(
                  props?.chat_details?.agree_ship_price ??
                    props?.chat_details?.ship_price ??
                    0
                ) /
                  100) *
                  Number(
                    props?.chat_details?.handling?.handling_charges_percent
                  )
              ) +
              Number(props?.chat_details?.service_charges ?? 0);
      } else {
        total_amt =
          props?.chat_details?.order_amount_detail?.sub_total > 0
            ? Number(props?.chat_details?.order_amount_detail?.sub_total ?? 0) +
              Number(props?.chat_details?.service_charges ?? 0)
            : Number(
                props?.chat_details?.agree_ship_price ??
                  props?.chat_details?.ship_price ??
                  0
              ) + Number(props?.chat_details?.service_charges ?? 0);
      }

      const response = await apiServices.postFromApi(
        "stripe/payment-sheet-with-card",
        {
          ship_id: Number(props?.chat_details?.id),
          amount: Number(total_amt.toFixed(2)),
        },
        ""
      );
      let decryptData = JSON.parse(decrypt(response.pi_key));
      if (typeof decryptData === "string") {
        decryptData = JSON.parse(decryptData);
      }
      const { setupIntent, paymentIntent, ephemeralKey, customer } =
        decryptData ?? {};

      const options = {
        theme: "stripe",

        clientSecret: paymentIntent,
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily: '"Raleway-Variable", sans-serif',
          },
          rules: {
            ".Label": {
              fontFamily: '"Raleway-Variable", sans-serif',
              fontSize: "14px", // Adjust the size as needed
              color: "#000000", // Change the color if needed
            },
          },
        },
      };
      setState({ options });
    } catch (error) {
      console.log(error);
    }
  };

  const getMessages = async () => {
    try {
      const { chat_details } = props;

      const resp = await apiServices.postFromApi(
        "/chat/get-messages-by-user",
        {
          receiver_id:
            `${chat_details?.customer_id}` === `${state?.user.id}`
              ? `${chat_details?.acc_id}`
              : `${chat_details?.customer_id}`,
          order_id: chat_details?.id
            ? `${chat_details?.id}`
            : `${chat_details?.id ?? chat_details?.ship_id}`,
        },
        ""
      );
      if (chat_details?.id || chat_details?.id || chat_details?.ship_id) {
        const resp2 = await apiServices.getFromApi(
          `/c2c-shipments/${
            chat_details?.id
              ? `${chat_details?.id}`
              : `${chat_details?.id ?? chat_details?.ship_id}`
          }`,
          ""
        );
        setState({
          chat_details: resp2?.data,
          loading_screen: false,
        });
      }
      if (resp?.data?.length > 0) {
        setState({ messages: resp?.data });
        // setChatMessages(resp?.data ?? []);
      }

      setState({
        loading_screen: false,
        refreshing: false,
      });
    } catch (error) {
      setState({
        loading_screen: false,
        refreshing: false,
      });

      console.log(error);
    }
  };
  console.log(
    "state.chat_details?.userdetails",
    state.chat_details?.userdetails
  );

  useEffect(() => {
    const scrollIntoView = () => {
      if (scrollRef?.current) {
        scrollRef.current.scrollTop = scrollRef?.current?.scrollHeight + 100;
      }
    };
    return scrollIntoView;
  }, [JSON.stringify(state.messages)]);

  const handleNewMessage = async (path?: any, _document?: any) => {
    setTimeout(() => {
      setState({
        disable_btn: false,
      });
    }, 3000);

    let new_msg: any = {
      message: state.message ?? null,
      message_status: "sent",
      order_id: `${state.chat_details?.id}`,
      is_negotiate: false,
      order_details: null,
      sender_id: `${user?.id}`,
    };
    if (`${state.chat_details?.customer_id}` === `${user?.id}`) {
      new_msg.receiver_id = `${state.chat_details?.acc_id}`;
    } else {
      new_msg.receiver_id = `${state.chat_details?.customer_id}`;
    }
    if (state.show_preview && state.upload_type === "location") {
      new_msg.location = {
        type: "Point",
        coordinates: [state.latitude ?? 0, state.longitude ?? 0],
      };
      new_msg.file_type = "location";
    }
    setState({
      show_preview: false,
    });

    if (path) {
      let formData = new FormData();

      const resp = await axios({
        baseURL: `${BASE_URL}upload/upload-file`,
        headers: {
          "Content-Type": "multipart/form-data", // this is important
        },
        method: "post",
        data: formData,
      });
      if (resp.data?.url) {
        new_msg.audio_url = resp.data?.url;
        await apiServices.postFromApi("chat/send-new-message", new_msg, "");
        getMessages();
        // setMessage("");
      }
    } else if (_document) {
      let formData = new FormData();
      formData.append("file", _document);
      new_msg.file_type = state.upload_type;
      new_msg.file_name = _document?.name ?? _document?.fileName;
      new_msg.file_size = `${_document?.size}`;
      new_msg.file_url = _document?.uri;
      let { messages } = state;
      messages.push(new_msg);
      setState({
        messages,
      });
      const resp = await axios({
        baseURL: `${BASE_URL}upload/upload-file`,
        headers: {
          "Content-Type": "multipart/form-data", // this is important
        },
        method: "post",
        data: formData,
      });
      if (resp.data?.url) {
        new_msg.file_url = resp.data?.url;
        await apiServices.postFromApi("chat/send-new-message", new_msg, "");
        getMessages();
        setState({ message: "" });
      }
    } else {
      await apiServices.postFromApi("chat/send-new-message", new_msg, "");
      getMessages();
      setState({ message: "" });
    }
    setState({
      temp_document: null,
      upload_type: null,
      temp_gallery: null,
    });
  };
  // NEW FUNCTIONS WITH NEW CHAT
  const handleSentFirstMessage = async () => {
    setState({
      disable_btn: true,
    });
    if (!state?.message || state?.message === " ") {
      return;
    }
    let text = state.message
      .replaceAll("،", ".")
      .replaceAll(".", ".")
      .replaceAll(",", ".");
    if (isNaN(Number(text))) {
      Toast.Error(
        selector.lang === "fr"
          ? `Veuillez entrer un montant valide.`
          : "Please enter a valid amount"
      );
      return;
    }
    const name =
      state?.user?.first_name + state?.user?.surname
        ? ` ${state?.user?.surname}`
        : "";
    let first_msg: any = {
      message: ``,
      message_status: "sent",
      order_id: state.chat_details?.id,
      is_negotiate: true,
      order_details: state.chat_details,
      sender_id: `${user?.id}`,
      receiver_id: `${state.chat_details?.customer_id}`,
      sender_name: name,
    };
    let new_msg: any = {
      message: `${name} has sent you a message. neg_amt=${text}`,
      message_status: "sent",
      order_id: state.chat_details?.id,
      is_negotiate: true,
      order_details: null,
      sender_id: `${user?.id}`,
      receiver_id: `${state.chat_details?.customer_id}`,
      sender_name: name,
    };
    let obj = {
      ship_id: Number(
        state.chat_details?.id
          ? state.chat_details?.id
          : state?.chat_details?.id ?? state?.chat_details?.ship_id
      ),
      ship_price: Number(text ?? 0),
    };
    const resp = await apiServices.postFromApi("/c2c-shipment-rates", obj, "");
    if (resp.status === 201) {
      Toast.showSuccess(strings.offer_request_sent);
      const resp = await apiServices.postFromApi(
        "/chat/create-first-message",
        {
          first_msg,
          new_msg,
        },
        ""
      );
      if (resp.status === 201) {
        getMessages();
        setState({
          message: "",
          temp_document: null,
          upload_type: null,
          temp_gallery: null,
        });
      }
    } else {
      Toast.Error(
        resp.error?.message ??
          resp?.message ??
          resp?.error?.error ??
          "Internal Server Error"
        // item_sizes.globalErrorMessage(selector.lang),
      );
    }
    setState({ disable_btn: false });
  };

  const handleNewMessageWithNegotiate = async () => {
    setTimeout(() => {
      setState({
        disable_btn: false,
      });
    }, 3000);
    let text = state.message
      .replaceAll("،", ".")
      .replaceAll(".", ".")
      .replaceAll(",", ".");
    if (isNaN(Number(text))) {
      Toast.Error(
        selector.lang === "fr"
          ? `Veuillez entrer un montant valide.`
          : "Please enter a valid amount"
      );
      setState({
        disable_btn: false,
      });
      return;
    }
    const last_message = state?.messages[state?.messages?.length - 1];
    const first_message = state?.messages[0];
    // const name = user_?.first_name + user_?.surname ? ` ${user_?.surname}` : '';
    let new_msg: any = {
      message: `has sent you a message. neg_amt=${text}`,
      message_status: "sent",
      order_id: last_message?.order_id,
      is_negotiate: true,
      order_details: null,
      sender_id: `${user?.id}`,
      receiver_id: `${first_message?.order_details?.customer_id}`,
    };
    let obj = {
      ship_id: Number(state.chat_details?.id ?? state.chat_details?.ship_id),
      ship_price: Number(text ?? 0),
    };
    const resp = await apiServices.postFromApi("/c2c-shipment-rates", obj, "");
    if (resp.status === 201) {
      Toast.showSuccess(strings.offer_request_sent);
      await apiServices.postFromApi("chat/send-new-message", new_msg, "");
      getMessages();
      setState({
        message: "",
        temp_document: null,
        upload_type: null,
        temp_gallery: null,
      });
      setState({
        disable_btn: false,
      });
    } else {
      Toast.Error(
        resp.error?.message ??
          resp?.message ??
          resp?.error?.error ??
          "Internal Server Error::"
      );
      setState({
        disable_btn: false,
      });
    }
  };

  const handleSendOtpAfterPayment = async () => {
    try {
      const res = await apiServices.getFromApi(
        `c2c-shipments/generate-pickup-otp/${state?.chat_details?.id}`,
        ""
      );
      if (res?.status === 200 || res?.status === 201) {
        Toast.showSuccess("OTP has to you phone no");
        getMessages();
        setState({ show_stripe: false });
      }
    } catch (error) {}
  };

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;

      if (value[0] === " ") {
        return;
      }
      if (!_last_message || _last_message?.is_agree === false) {
        if (value > 10000) {
          return;
        }
       }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      handleNewMessage(null, selectedFile);
    }
  };

  const { user } = state;

  const _last_message = state?.messages?.[state?.messages?.length - 1];

  return (
    <div>
      <div
        style={{
          height: "calc(90vh - 200px)",
          overflowY: "auto",
          padding: 5,
        }}
        className={classes?.scrollableArea}
        ref={scrollRef}
      >
        <div style={{ gap: 10, marginTop: 10, marginBottom: 10 }}>
          {(state?.chat_details
            ? state?.chat_details?.requester
            : state?.selected_shipment?.requester
          )?.map((item: any, index: number) => {
            return (
              (item?.accepter_id === state?.chat_details?.acc_id ||
                item?.accepter_id === user?.id) &&
              (Number(user?.id) === Number(item.accepter_id) ||
                Number(user?.id) === Number(item.customer_id)) && (
                <div key={`REQUESTER_ITEM_${index + 1}`}>
                  <div
                    style={{
                      borderRadius: 16,
                      backgroundColor: color_theme.card_background,
                      paddingTop: 15,
                      // paddingHorizontal: 15,
                      paddingBottom:
                        state?.chat_details?.ship_status_level < 2 ? 10 : 15,
                      borderWidth: 1,
                      borderColor: color_theme.card_border,
                      marginTop: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        justifyContent:
                          item?.is_agree === false
                            ? "space-between"
                            : "space-between",
                        alignContent: "center",
                        flexWrap: "wrap",
                        display: "flex",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Raleway-Bold",
                          color:
                            item?.is_agree === false
                              ? "#D9534F"
                              : color_theme.black,
                          textAlign: "center",
                          marginTop: item?.is_agree === false ? 5 : 0,
                        }}
                      >
                        {item?.accepter_id === user?.id
                          ? selector.lang === "fr"
                            ? <>
                             
                             {`Vous avez fait une offre à ${numberFormatWithoutCurrencyFixed(
                                item?.ship_price,
                                selector.lang === "fr" ? "en-FR" : "en-US"
                              )}€`}</>
                            : `You make an offer of  ${numberFormatWithoutCurrencyFixed(
                                item?.ship_price,
                                selector.lang === "fr" ? "en-FR" : "en-US"
                              )}€`
                          : selector.lang === "fr"
                          ? <>
                           {`${
                              item?.accepter_name
                            } Vous avez fait une offre à ${numberFormatWithoutCurrencyFixed(
                              item?.ship_price,
                              selector.lang === "fr" ? "en-FR" : "en-US"
                            )}€`}
                            </>
                          : `${
                              item?.accepter_name
                            } make an offer of  ${numberFormatWithoutCurrencyFixed(
                              item?.ship_price,
                              selector.lang === "fr" ? "en-FR" : "en-US"
                            )}€`}
                      </span>
                      {item?.is_agree === false && (
                        <div
                          style={{
                            backgroundColor: "#d9534f1f",
                            borderRadius: 7,
                            borderWidth: 1,
                            borderColor: color_theme.card_background,
                            padding: 5,
                            fontFamily: "Raleway-Bold",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 12,
                              color: "#D9534F",
                            }}
                          >
                            {strings.declined}
                          </span>
                        </div>
                      )}
                    </div>
                    <span
                      style={{
                        textAlign: "right",
                        paddingRight: 10,
                        fontFamily: "Raleway-Bold",
                        fontSize: "12px",
                      }}
                    >
                      {moment().format("DD/MM/YYYY") !=
                      moment(item?.created_at).format("DD/MM/YYYY")
                        ? moment(
                            `${item?.created_at}`.endsWith("Z")
                              ? item?.created_at
                              : item?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : moment(
                            `${item?.created_at}`.endsWith("Z")
                              ? item?.created_at
                              : item?.created_at + "Z"
                          ).format("HH:mm")}
                    </span>
                  </div>
                </div>
              )
            );
          })}
          {state?.chat_details?.ship_status_level > 1 && (
            <div
              style={{
                display:
                  Number(state?.chat_details?.acc_id) === Number(user?.id) ||
                  Number(state?.chat_details?.customer_id) === Number(user?.id)
                    ? "flex"
                    : "none",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 16,
                  // backgroundColor: "#34A8531f",
                  // padding: 15,
                  borderWidth: 1,
                  borderColor: color_theme.card_border,
                  flexDirection: "column",
                  columnGap: 20,
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    color: "#34A853",
                    fontFamily: "Raleway-Bold",
                  }}
                >
                  {state?.chat_details?.accepter_id === user?.id ? (
                    selector.lang === "fr" ? (
                      `${state.chat_details?.user_details?.cus_name} a accepté votre annonce `
                    ) : (
                      `${
                        state.chat_details?.user_details?.cus_name
                      } accepted your offer at € ${numberFormatWithoutCurrencyFixed(
                        state?.chat_details?.ship_price,
                        selector.lang === "fr" ? "en-FR" : "en-US"
                      )}`
                    )
                  ) : selector.lang === "fr" ? (
                    state?.chat_details?.user_details?.id ===
                    state?.user?.id ? (
                      <OfferAcceptedCarForSender />
                    ) : (
                      <OfferAcceptedCard
                        headingOne={"Votre offre a été accepté !"}
                        // headingTwo={"Vous recevrez votre paiement d'ici peu"}
                        amountAccepted={`${numberFormatWithoutCurrencyFixed(
                          Number(state.chat_details?.agree_ship_price),
                          selector.lang === "fr" ? "en-FR" : "en-US"
                        )}`}
                      />
                    )
                  ) : state?.chat_details?.user_details?.id ===
                    state?.user?.id ? (
                    <OfferAcceptedCarForSender />
                  ) : (
                    <OfferAcceptedCard
                      headingOne={"Your offer is accepted!"}
                      // headingTwo={"You will receive your payment shortly"}
                      amountAccepted={`${numberFormatWithoutCurrencyFixed(
                        Number(state.chat_details?.agree_ship_price),
                        selector.lang === "fr" ? "en-FR" : "en-US"
                      )}`}
                    />
                  )}
                </span>
                <div
                  style={{
                    textAlign: "right",
                    paddingRight: 10,
                    fontFamily: "Raleway-Bold",
                    fontSize: "12px",
                    paddingTop: "5px",
                  }}
                >
                  {moment().format("DD/MM/YYYY") !=
                  moment(
                    state?.chat_details?.requester?.[
                      state?.chat_details?.requester?.length - 1
                    ]?.created_at
                  ).format("DD/MM/YYYY")
                    ? moment(
                        `${
                          state?.chat_details?.requester?.[
                            state?.chat_details?.requester?.length - 1
                          ]?.created_at
                        }`.endsWith("Z")
                          ? state?.chat_details?.requester?.[
                              state?.chat_details?.requester?.length - 1
                            ]?.created_at
                          : state?.chat_details?.requester?.[
                              state?.chat_details?.requester?.length - 1
                            ]?.created_at + "Z"
                      ).format("DD/MM/YYYY HH:mm")
                    : moment(
                        `${
                          state?.chat_details?.requester?.[
                            state?.chat_details?.requester?.length - 1
                          ]?.created_at
                        }`.endsWith("Z")
                          ? state?.chat_details?.requester?.[
                              state?.chat_details?.requester?.length - 1
                            ]?.created_at
                          : state?.chat_details?.requester?.[
                              state?.chat_details?.requester?.length - 1
                            ]?.created_at + "Z"
                      ).format("HH:mm")}
                </div>{" "}
              </div>
            </div>
          )}
          {state?.chat_details?.ship_status_level === 2 &&
            state?.chat_details?.payment_status !== "Paid" && (
              <div
                style={{
                  width: "100%",
                  maxWidth: "1000px",
                  // padding: "10px",
                  position: "relative",
                  marginTop: "20px",
                  paddingBottom: "20px",
                  display:
                    Number(state?.chat_details?.customer_id) !==
                    Number(user?.id)
                      ? "none"
                      : "flex",
                }}
              >
                <img
                  src={paymentImage}
                  alt="payment-img"
                  style={{
                    position: "relative",
                    height: "160px",
                    width: "99%",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    gap: 20,
                    top: 25,

                    left: "calc(100% - 60%)",
                  }}
                >
                  <span
                    style={{
                      position: "relative",
                      color: "#fff",
                      fontSize: "18px",
                      fontFamily: "Raleway-Extra-Bold",
                    }}
                  >
                    L’offre a été accepté !
                    <br />
                    <span style={{ fontWeight: "500" }}>
                      Veuillez procéder au paiement
                    </span>
                  </span>
                  <br />
                  <CustomButton
                    style={{
                      position: "absolute",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#fff",
                      fontFamily: "Raleway-Variable",
                      background: "#ee6931",
                      border: "none",
                      borderRadius: "12px",
                      padding: "10px 30px 10px 30px",
                      minWidth: "200px",
                      maxWidth: "300px",
                      width: "260px",
                      marginTop: "10px",
                    }}
                    value={
                      selector.lang === "en"
                        ? "Continue to Payment"
                        : `Continuer vers le paiement`
                    }
                    onClick={() => {
                      setState({ show_stripe: true });
                    }}

                    // onPress={() =>
                    // navigation.navigate("transaction_details_modal", {
                    //   item: item,
                    //   from_chat: true,
                    // })
                    // }
                  />
                </div>
              </div>
            )}
        </div>

        <div style={{}}>
          {state.messages?.map((i: any, index: number) => (
            <MessageComponent
              item={i}
              user={state.user}
              index={index}
              chatMessages={state.messages}
            />
            // <div>{i.message ?? ""}</div>
          ))}
        </div>
        {/* <div ref={scrollRef} /> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {_last_message?.is_agree === null ? (
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: 16,
              textAlign: "center",
              color: color_theme.black_40,
            }}
          >
            {selector.lang === "fr"
              ? `Vous ne pouvez pas échanger avec l'expéditeur jusqu'à ce qu'il accepte ou refuse votre offre.`
              : `You can't reply to this conversation anymore until sender accepts or declined your request.`}
          </p>
        ) : (
          <>
            {!_last_message ? null : _last_message?.is_agree ===
              false ? null : (
              <>
                {" "}
                <IconButton
                  onClick={handleButtonClick}
                  color="primary"
                  aria-label="upload picture"
                >
                  <CameraGreyIcon />
                </IconButton>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />{" "}
              </>
            )}

            {/* <Modal open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  p: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 12,
                }}
              >
                <>
                  <IconButton
                    onClick={handleButtonClick}
                    color="primary"
                    aria-label="upload picture"
                    style={{ padding: 30 }}
                  >
                    <CameraAlt style={{ fontSize: 42 }} />
                  </IconButton>
                  <input
                    type="file"
                    accept="image/*"
                    capture="user" // Request front-facing camera or "environment" for back camera
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <IconButton
                    onClick={handleButtonClick}
                    color="primary"
                    aria-label="upload picture"
                    style={{ padding: 30 }}
                  >
                    <Collections style={{ fontSize: 42 }} />
                  </IconButton>
                </>
              </Box>
            </Modal> */}

            <InputField
              placeHolder={"Type here"}
              input_value={state.message}
              input_name={"message"}
              onchange={onInputChange}
              onKeyDown={(e: any) => {
                if (e.key === "Enter" && !state?.disable_btn) {
                  handleSendMessage(); // Call the function to send the message
                }
              }}
              input_style={{
                background: "#f4f3f6",
                width: "97%",
                borderRadius: "32px",
              }}
              input_type={
                !_last_message
                  ? "number"
                  : _last_message?.is_agree === false
                  ? "number"
                  : "text"
              }
            />
            <IconButton
              disabled={state?.disable_btn}
              onClick={handleSendMessage}
              style={{
                background: defaultLabelColors.main_orange,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Send
                style={{ color: defaultLabelColors.white, fontSize: "32px" }}
              />
            </IconButton>
          </>
        )}
      </div>

      <DraggableDialog
        maxWidth={state?.show_stripe ? "sm" : "md"}
        open={state?.show_stripe}
        handleClose={() => {
          setState({ show_stripe: false });
        }}
        dialog_title={`${
          state?.showStripeForm ? strings.payments : "Transporter un colis"
        }`}
        dialogBody={
          <DialogContent dividers>
            <div>
              <div>
                {
                props?.chat_details?.shipment_items?.length > 1 ? 
                <div className="card-container" style={{marginTop:5}}>
                   <img
                        src={props?.chat_details?.shipment_items?.[0]?.prod_images[0]}
                        className="card-image"
                        alt="prod-img"
                      />
                      <div className="card-details">
                        <div className="card-title" style={{ fontSize: 17 }}>
                           
                          { `Lot de (${props?.chat_details?.shipment_items?.length}) colis` }
                        </div>

                       </div>
                       <div
                        className="card-quantity"
                        style={{
                          paddingRight: "20px",
                          color: defaultLabelColors.main_orange,
                          fontSize: "22px",
                        }}
                      >
                        <span style={{fontSize:16,color:'#000',paddingRight:5}}> 
                        Total à payer
                        </span>
                        {numberFormatWithoutCurrency(state?.total_amt ?? 0.0)}
                        &nbsp; €
                      </div>
                </div>
                
                :
                props?.chat_details?.shipment_items?.map((i: any) => {
                  return (
                    <div className="card-container" style={{marginTop:5}}>
                      <img
                        src={i?.prod_images[0]}
                        className="card-image"
                        alt="prod-img"
                      />
                      <div className="card-details">
                        <div className="card-title" style={{ fontSize: 17 }}>
                          {i?.prod_name ?? ""}
                        </div>

                        <div className="card-quantity">x{i?.prod_quantity}</div>
                      </div>
                      <div
                        className="card-quantity"
                        style={{
                          paddingRight: "20px",
                          color: defaultLabelColors.main_orange,
                          fontSize: "22px",
                        }}
                      >
                        {numberFormatWithoutCurrency(state?.total_amt ?? 0.0)}
                        &nbsp; €
                      </div>
                    </div>
                  );
                })}
              </div>

              <div style={{ marginTop: "10px" }}>
                <Elements stripe={stripePromise} options={state.options}>
                  <StripeCheckout handleOtpSend={handleSendOtpAfterPayment} {...state} />
                </Elements>
              </div>
            </div>
          </DialogContent>
        }
      />
    </div>
  );
}

export default AccepterChat;
