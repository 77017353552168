import React, { useState } from "react";
import { FrankIcon } from "../../assets/images/FrankIcons";
import {
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Close,
  ExpandLess,
  ExpandMore,
  Language,
  PersonOutlineOutlined,
  Search,
} from "@mui/icons-material";
import drawerBoxIcon from "../../assets/mobileImages/drawer-box-icon.png";
import { useDispatch, useSelector } from "react-redux";
import frIcon from "../../assets/images/FrankIcons/fr-icon.png";
import enIcon from "../../assets/images/FrankIcons/en-icon.png";
import { RootState } from "../../Store";
import { setUpdateLanguage } from "../../Store/MainAppReducer";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";

function CustomHeaderMobile(props: any) {
  const selector = useSelector((state: RootState) => state.mainApp);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null); // Track the currently expanded menu
  const [anchorEN, setAnchorEN] = React.useState<null | HTMLElement>(null);
  const openForLang = Boolean(anchorEN);
  let user = getDataFromLocalStorage("user");
  const handleClickForLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEN(event.currentTarget);
  };
  const handleCloseForLang = () => {
    setAnchorEN(null);
  };
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        (event.type === "keydown" &&
          (event as React.KeyboardEvent).key === "Tab") ||
        (event as React.KeyboardEvent).key === "Shift"
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const handleMenuClick = (menuId: string) => () => {
    setExpandedMenu(expandedMenu === menuId ? null : menuId);
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="header-body-top">
        <div className="header-wrapper">
          <div className="menu-mobile">
            <div className="frank-logo">
              <a href="/">
                <FrankIcon fill={"#ee6931"} height={50} />
              </a>
            </div>
            <div className="line-menu-line">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  {!drawerOpen ? (
                    <span style={{ paddingBottom: "20px" }}>
                      <svg
                        width="43px"
                        height="42px"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginTop: "-10px" }}
                      >
                        <path
                          d="M4 16H28M4 8H28M4 24H20"
                          stroke="#281208"
                          stroke-width="2.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  ) : (
                    <Close />
                  )}
                </IconButton>

                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                  style={{
                    borderRadius: "0px",
                    background: "none",
                    height: "100vh",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, padding: "20px 0px 0px 20px" }}>
                      <FrankIcon fill={"#ee6931"} height={60} />
                    </div>
                    <div style={{ padding: "20px 20px 0px 0px" }}>
                      <IconButton onClick={toggleDrawer(false)}>
                        <Close style={{ fontSize: "28px", color: "#000" }} />
                      </IconButton>
                    </div>
                  </div>
                  <div role="presentation">
                    <List style={{ paddingTop: "40px", fontSize: "23px" }}>
                      <ListItem onClick={handleMenuClick("particulier")}>
                        <ListItemText primary="Particulier" />
                        {expandedMenu === "particulier" ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={expandedMenu === "particulier"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          style={{ paddingLeft: "20px" }}
                          disablePadding
                        >
                          <ListItem
                            button
                            component="a"
                            onClick={() => {
                              navigate("/send-package");
                            }}
                          >
                            <ListItemText primary="Envoyer un colis" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Suivre mon colis" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText
                              primary="Devenir transporteur"
                              onClick={() => {
                                navigate("/transporter-un-colis ");
                              }}
                            />
                          </ListItem>
                        </List>
                      </Collapse>

                      <ListItem
                        button
                        onClick={handleMenuClick("transporteur")}
                      >
                        <ListItemText
                          primary="Transporteur"
                          style={{ fontSize: "43px" }}
                        />
                        {expandedMenu === "transporteur" ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={expandedMenu === "transporteur"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          style={{ paddingLeft: "20px" }}
                          disablePadding
                        >
                          <ListItem
                            button
                            component="a"
                            onClick={() => {
                              navigate("/transporter-un-colis");
                            }}
                          >
                            <ListItemText primary="Devenir Transporteur" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Voir les annonces pour une livraison" />
                          </ListItem>
                        </List>
                      </Collapse>

                      {/* <ListItem
                        button
                        onClick={handleMenuClick("demenagement")}
                      >
                        <ListItemText primary="Déménagement" />
                        {expandedMenu === "demenagement" ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={expandedMenu === "demenagement"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          style={{ paddingLeft: "20px" }}
                          disablePadding
                        >
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Déménageur particulier" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Déménageur professionnel" />
                          </ListItem>
                        </List>
                      </Collapse> */}

                      <ListItem component="a" href="#" button>
                        <ListItemText primary="Marketplace" />
                      </ListItem>

                      {/* <ListItem button onClick={handleMenuClick("stockage")}>
                        <ListItemText primary="Stockage colis" />
                        {expandedMenu === "stockage" ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={expandedMenu === "stockage"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          style={{ paddingLeft: "20px" }}
                          disablePadding
                        >
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Trouver un point relai particulier" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Proposer un espace de point relai" />
                          </ListItem>
                          <ListItem button component="a" href="#">
                            <ListItemText primary="Consigne bagage" />
                          </ListItem>
                        </List>
                      </Collapse> */}

                      {/* <ListItem button component="a" href="#">
                        <ListItemText primary="Blog" />
                      </ListItem> */}
                    </List>
                  </div>

                  <div style={{ padding: "50px 0px 0px 0px" }}>
                    <List component="div">
                      <ListItem
                        button
                        component="a"
                        href="#"
                        style={{
                          gap: "20px",
                          fontSize: "20px",
                          fontWeight: "700",
                          fontFamily: "Raleway-Variable",
                        }}
                      >
                        <Search /> Tracker mon colis
                      </ListItem>

                      {user ? (
                        <ListItem
                          button
                          component="a"
                          href="#"
                          style={{
                            gap: "20px",
                            fontSize: "20px",
                            fontWeight: "700",
                            fontFamily: "Raleway-Variable",
                          }}
                          onClick={() => {
                            navigate("/profile");
                          }}
                        >
                          <PersonOutlineOutlined /> Profil
                        </ListItem>
                      ) : (
                        <ListItem
                          button
                          component="a"
                          href="#"
                          style={{
                            gap: "20px",
                            fontSize: "20px",
                            fontWeight: "700",
                            fontFamily: "Raleway-Variable",
                          }}
                          onClick={() => {
                            navigate("/login");
                          }}
                        >
                          <PersonOutlineOutlined /> Mon compte
                        </ListItem>
                      )}

                      <ListItem
                        button
                        component="a"
                        style={{
                          gap: "20px",
                          fontSize: "20px",
                          fontWeight: "700",
                          fontFamily: "Raleway-Variable",
                        }}
                        onClick={handleClickForLang}
                      >
                        <Language /> {selector.lang?.toUpperCase()}
                      </ListItem>
                    </List>

                    <Menu
                      id="account-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEN}
                      open={openForLang}
                      onClose={handleCloseForLang}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          // width: "200px",
                          borderRadius: "12px",
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        onClick={() => {
                          dispatch(setUpdateLanguage("fr"));
                          handleCloseForLang();
                        }}
                      >
                        <div className="display-flex" style={{ gap: 10 }}>
                          <img src={frIcon} height={30} alt={"fr-icon"} width={30} />
                          FR
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          dispatch(setUpdateLanguage("en"));
                          handleCloseForLang();
                        }}
                      >
                        <div className="display-flex" style={{ gap: 10 }}>
                          <img src={enIcon} alt="en-icon" height={20} width={30} />
                          EN
                        </div>
                      </MenuItem>
                    </Menu>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <img src={drawerBoxIcon}  height={"60%"} alt="box-img" />
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomHeaderMobile;
