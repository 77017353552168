import React from "react";
import "./quest.css"
import { FrankIcon } from "../../assets/images/FrankIcons";
import { defaultLabelColors } from "../../utils/constants";

function Quest() {
  return (
    <div className="quest-container">
      <div className="rectangle">
        <div className="default"></div>
      </div>
      <div className="rectangle-1"></div>
      <div className="frame-2">
        <div className="frame-3">
          <span className="qu-est-ce">Qu’est-ce</span>
          <div className="frank-logo">
            <FrankIcon fill={defaultLabelColors.main_orange} />
          </div>
        </div>
        <div className="frame-4">
          <div className="frame-5">
            <span className="text-2">1</span>
            <div className="section-3">
              <span className="text-3">
                Rentabiliser
                <br />
              </span>
              <span className="text-4">l’adresse d’envoi</span>
            </div>
          </div>
          <div className="group-2">
            <span className="find-driver">2</span>
            <div className="find-driver-6">
              <span className="find-driver-7">
                Trouvez
                <br />
              </span>
              <span className="find-driver-8">un conducteur</span>
            </div>
          </div>
          <div className="frame-9">
            <span className="number-3">3</span>
            <div className="send-package">
              <span className="send" style={{fontWeight:600}}>
                Envoyez
                <br />
              </span>
              <span className="your-package">votre colis</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quest;
