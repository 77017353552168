import {
  Box,
  Card,
  CardContent,
  CardMedia,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NewHeader from "../../UiComponent/NewHeader";
import { ArrowBackIosNew } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import apiServices from "../../services/RequestHandler";
import noImageFile from "../../assets/images/FrankIcons/noImageAvailable.png";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixed,
} from "../../utils/helper";
import { defaultLabelColors } from "../../utils/constants";
import { strings } from "../../Store/Localization";
import { Rating } from "react-simple-star-rating";
import CustomFooter from "../../UiComponent/CustomFooter";
import { LocationPinIcon, MapHomeIcon } from "../../assets/images/FrankIcons";
import moment from "moment";
import OTPInput from "react-otp-input";
import Toast from "../../UiComponent/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";
import Loader from "../../UiComponent/Loader";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import { getProfileInfo } from "../../Store/ProfileReducer";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import InputField from "../../UiComponent/TextFeild";
import CustomButton from "../../UiComponent/CustomButton";

function JobDetails(props: any) {
  const [otp, setOtp] = React.useState("");
  const selector = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");
  const param = useParams();
  const navigate = useNavigate();
  const [isShaking, setIsShaking] = useState(false);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      showPickupOtpScreen: false,
      loadingLoader: true,
      ratingsCount: 1,
 
    }
  );

   

  useEffect(() => {
    handleGetProdInfo(param?.id);
  }, []);

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleGetProdInfo = async (job_id: any) => {
    try {
      const resp = await apiServices.getFromApi(`c2c-shipments/${job_id}`, "");

      setState({
        job_details: resp?.data,
      });

      setSelectedImage(
        resp?.data?.shipment_items[0]?.prod_images[0] ?? noImageFile
      );
      //   handleGetShopInfo(job_details?.shop_id);
      setState({ loadingLoader: false });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddFeedback = async () => {
    try {
      let obj = [{
        order_id: Number(state.job_details?.id),
        rating_from_id: `${state.job_details?.acc_id}`,
        rating_to_id: `${state.job_details?.user_details?.id}`,
        rating: state?.ratingsCount ?? 1,
        feedback: state?.feedback, 
      }];
       let resp = await apiServices.postFromApi("c2c-feedbacks", {data:obj}, "");

      if (resp?.status === 200 || resp?.status === 201) {
        handleGetProdInfo(state.job_details?.id)
        setState({
          feedbackDialog: false,
        });
      }
    } catch (error) {}
  };
  const triggerShake = () => {
    setIsShaking(true);
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };
  const handleComplete = (otpValue: any) => {
    if (otpValue?.length === 4) {
      if (state?.job_details?.ship_status === "Accepted") {
        handlePickOrder(otpValue);
        return;
      }
      if (state?.job_details?.ship_status === "Picked") {
        handleDeliverOrder(otpValue);
        return;
      }
    }
  };
  const handlePickOrder = async (otp: any) => {
    try {
      let obj = {
        ship_id: Number(state?.job_details.id),
        ship_status: "Picked",
        ship_pickup_otp: Number(otp),
      };

      let resp = await apiServices.postFromApi(
        "c2c-shipments/update-status",
        obj,
        ""
      );
      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess(
          selector.mainApp.lang === "en"
            ? `Your delivery moves on to the next step :package:`
            : "Votre livraison passe à l'étape suivante :package:"
        );
        setState({ showPickupOtpScreen: false });
        handleGetProdInfo(param?.id);
        setOtp("");
        return;
      } else {
        Toast.Error(strings?.pickup_otp_for_transporter ?? resp?.error);
 
      }
    } catch (error) {}
  };
  const handleDeliverOrder = async (otp: any) => {
    try {
      let obj = {
        ship_id: Number(state?.job_details?.id),
        ship_status: "Deliver",
        ship_deliver_otp: Number(otp),
      };

      let resp = await apiServices.postFromApi(
        "c2c-shipments/update-status",
        obj,
        ""
      );
      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess(
          selector.mainApp.lang === "en"
            ? `Your delivery moves on to the next step :package:`
            : "Votre livraison passe à l'étape suivante :package:"
        );
        setState({ showPickupOtpScreen: false });
        handleGetProdInfo(param?.id);
        setOtp("");
        return;
      } else {
        Toast.Error(
          resp?.data?.error ??
            resp?.error?.error ??
            resp?.data?.isEmail ??
            resp?.data?.isNotEmpty ??
            resp?.message ??
            "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
        );
      }
    } catch (error) {}
  };

  const { job_details, user } = state;
  return (
    <div className="web-screen">
      <Loader loading={state?.loadingLoader} />

      <div style={{ background: "#f9c9b3", minHeight: "100vh" }}>
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#F4C9B3",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <NewHeader />
          </Grid>
        </Grid>
        <div style={{ padding: "30px 80px 50px 80px" }}>
          <Grid container spacing={2} style={{ paddingBottom: "40px" }}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                fontFamily: "Raleway-Variable",
                fontWeight: "500",
                fontSize: "26px",
                color: "#4B4A61",
                gap: 10,
                alignItems: "center",
              }}
            >
              <ArrowBackIosNew
                onClick={() => {
                  navigate(-1);
                }}
                style={{ cursor: "pointer" }}
              />{" "}
              Récapitulatif de la commande .
              <span
                style={{
                  fontFamily: "Raleway-Bold",
                  color: defaultLabelColors.main_orange,
                }}
              >
                {" "}
                {"À transporter "}{" "}
              </span>
              .{" "}
              <h1 style={{ fontSize: "26px" }}>
                {" "}
                {
                  job_details?.shipment_items?.length > 1 ?
                  <> Lot de {`(${job_details?.shipment_items?.length ?? 0})`}{" "}
                  colis </> :
                  handleLocalize({
                      en: job_details?.shipment_items[0]?.item_name_en?.en,
                      fr: job_details?.shipment_items[0]?.item_name_fr?.en,
                    }) ?? job_details?.shipment_items[0]?.prod_name
                  }
              </h1>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              background: "#fff",
              borderRadius: "16px",
              padding: "20px",
              gap: 20,
            }}
          >
            <Grid
              xs={12}
              md={5.8}
              item
              style={{
                borderRadius: "16px",
                height: "100%",
                padding: 0,
              }}
            >
              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                }}
              >
                <Box
                  position="relative"
                  sx={{ borderRadius: "32px 32px 0px 0px" }}
                >
                  <div style={{ height: "480px" }} className="display-flex">
                    <CardMedia
                      component="img"
                      image={
                        selectedImage ?? "https://via.placeholder.com/300x200"
                      }
                      alt="Product Image"
                      sx={{
                        objectFit: "contain",
                        width: "98%",
                        height: "460px",
                        borderRadius: "32px 32px 0px 0px",
                      }}
                    />
                  </div>
                </Box>

                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "30px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Typography
                        variant="h6"
                        color="#3B3A3A"
                        sx={{
                          fontFamily: "Raleway-Bold",
                        }}
                      >
                        {handleLocalize({
                          en: job_details?.shipment_items[0]?.item_name_en?.en,
                          fr: job_details?.shipment_items[0]?.item_name_fr?.en,
                        }) ??
                          job_details?.shipment_items[0]?.prod_name ??
                          ""}
                        <span> </span>
                      </Typography>
                    </div>

                    <div
                      style={{
                        color: defaultLabelColors.main_orange,
                        fontFamily: "Raleway-Extra-Bold",
                        fontSize: "22px",
                        gap: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "24px",
                          fontFamily: "Raleway-Bold",
                          color: "#000",
                        }}
                      >
                        Tarif accepté &nbsp;
                      </span>
                      {numberFormatWithoutCurrency(
                        job_details?.agree_ship_price
                          ? job_details?.agree_ship_price
                          : job_details?.ship_price
                      ) ?? ""}
                      &nbsp;€
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    {job_details?.shipment_items[0]?.prod_images?.map(
                      (item: any) => {
                        return (
                          <div
                            className="display-flex"
                            style={{
                              height: "116px",
                              width: "116px",
                              cursor: "pointer",
                              borderRadius: "12px",
                              border: `2px solid ${
                                selectedImage === item
                                  ? defaultLabelColors.main_orange
                                  : "grey"
                              } `,
                            }}
                            onClick={() => {
                              setSelectedImage(item);
                            }}
                          >
                            <img
                              src={item}
                              alt="prod-img"
                              style={{ objectFit: "contain", height: "110px" }}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              xs={12}
              md={5.8}
              item
              style={{
                borderRadius: "16px",
                height: "100%",
                padding: 0,
              }}
            >
              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {strings?.seller_details}
                </p>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: 75,
                      height: 75,
                      borderRadius: "50%",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: 3,
                      border: "1px solid #ee6931",
                    }}
                  >
                    <img
                      src={
                        job_details?.userDetails?.profile_pic ??
                        "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                      }
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>{" "}
                  <p />
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontWeight: 500,
                    }}
                  >
                    {job_details?.user_details?.cus_name ?? ""}
                    <br />
                    <Rating
                      initialValue={job_details?.user_details?.cus_ratings}
                      size={30}
                      allowHover={false}
                      readonly={true}
                    />
                  </p>
                </p>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div
                    style={{
                      borderRadius: 100,
                      background: "#f2f2f2",
                      padding: 15,
                      height: "100%",
                    }}
                    className="display-flex"
                  >
                    <MapHomeIcon
                      height={20}
                      width={20}
                      fill={defaultLabelColors.main_blue}
                    />
                  </div>
                  <div style={{ flex: 1, width: "90%" }}>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Lieu de départ
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Raleway-Bold",
                      }}
                    >
                      {job_details?.pickup?.address}
                    </p>
                  </div>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  marginTop: "10px",
                  paddingLeft: "15px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div
                    style={{
                      borderRadius: 100,
                      background: "#f2f2f2",
                      padding: 13,
                      height: "100%",
                    }}
                    className="display-flex"
                  >
                    <LocationPinIcon
                      height={23}
                      width={23}
                      fill={defaultLabelColors.main_orange}
                    />
                  </div>
                  <div style={{ flex: 1, width: "90%" }}>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Lieu d’arrivé
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Raleway-Bold",
                      }}
                    >
                      {job_details?.dropoff?.address ?? ""}
                    </p>
                  </div>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Raleway-Extra-Bold",
                    }}
                  >
                    Destinataire
                  </p>

                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: "18px",
                    }}
                  >
                    <a
                      href={`tel:${job_details?.recipient_phone_code}${job_details?.recipient_phone}`}
                    >
                      {job_details?.recipient_phone_code}{" "}
                      {job_details?.recipient_phone}
                    </a>
                  </p>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Raleway-Extra-Bold",
                    }}
                  >
                    Taille de mon colis
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: "16px",
                    }}
                  >
                    <span style={{ color: "#000" }}>
                      {job_details?.shipment_items[0]?.cat_name ?? ""}
                    </span>{" "}
                  </p>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Raleway-Extra-Bold",
                    }}
                  >
                    Description
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {job_details?.shipment_items[0]?.prod_desc}
                    {job_details?.shipment_items[0]?.prod_desc ? <br /> : null}
                    {job_details?.shipment_items[0]?.prod_length ?? 0} x{" "}
                    {job_details?.shipment_items[0]?.prod_height ?? 0} x{" "}
                    {job_details?.shipment_items[0]?.prod_width ?? 0}
                    <br />
                    Très bon état
                  </p>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                  paddingBottom: "10px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Raleway-Extra-Bold",
                    }}
                  >
                    Votre trajet
                  </p>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, fontFamily: "Raleway-Variable" }}>
                      {strings?.delivery_cost}
                    </div>
                    <div style={{ fontFamily: "Raleway-Variable", flex: 1 }}>
                      {numberFormatWithoutCurrency(job_details?.agree_ship_price ?? job_details?.ship_price)} €
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, fontFamily: "Raleway-Variable" }}>
                      Commission
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        color: "red",
                        flex: 1,
                      }}
                    >
                      -
                      {numberFormatWithoutCurrency(
                        job_details?.order_amount_detail?.commission_amount
                      )}{" "}
                      €
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, fontFamily: "Raleway-Variable" }}>
                      {strings?.tax_on_commission}
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        color: "red",
                        flex: 1,
                      }}
                    >
                      -
                      {numberFormatWithoutCurrency(
                        job_details?.order_amount_detail?.commission_tax_amount
                      )}{" "}
                      €
                    </div>
                  </div>

                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Bold",
                      }}
                    >
                      {strings?.total}
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Bold",
                        flex: 1,
                      }}
                    >
                      {numberFormatWithoutCurrency(
                        job_details?.agree_ship_price  -
                          job_details?.order_amount_detail?.commission_amount -
                          job_details?.order_amount_detail
                            ?.commission_tax_amount
                      )}{" "}
                      €
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>

            <Grid xs={12} item>
              <Grid
                container
                style={{
                  background: defaultLabelColors.main_orange,
                  borderRadius: "24px",
                  padding: 10,
                }}
              >
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      color: "#fff",
                      fontSize: "22px",
                    }}
                  >
                    01
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Mise en ligne <br />
                      {job_details?.order_statuses[0]?.created_at
                        ? moment(
                            `${job_details?.order_statuses[0]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? job_details?.order_statuses[0]?.created_at
                              : job_details?.order_statuses[0]?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      color: "#fff",
                      fontSize: "22px",
                    }}
                  >
                    02
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Accepté <br />
                      {job_details?.order_statuses[1]?.created_at
                        ? moment(
                            `${job_details?.order_statuses[1]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? job_details?.order_statuses[1]?.created_at
                              : job_details?.order_statuses[1]?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      color: "#fff",
                      fontFamily: "Raleway-Bold",
                      fontSize: "22px",
                    }}
                  >
                    03
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Expédié <br />
                      {job_details?.order_statuses[2]?.created_at
                        ? moment(
                            `${job_details?.order_statuses[2]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? job_details?.order_statuses[2]?.created_at
                              : job_details?.order_statuses[2]?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      color: "#fff",
                      fontSize: "22px",
                    }}
                  >
                    04
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Livré
                      <br />
                      {job_details?.order_statuses[3]?.created_at
                        ? moment(
                            `${job_details?.order_statuses[3]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? job_details?.order_statuses[3]?.created_at
                              : job_details?.order_statuses[3]?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={3} item className="display-flex">
                  {job_details?.ship_status === "Accepted" &&
                  !job_details?.ship_pickup_otp ? (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "12px",
                        color: "#000",
                        fontSize: "16px",
                        fontFamily: "Raleway-Bold",
                        padding: 20,
                        textAlign: "center",
                      }}
                    >
                      En attente du paiements
                    </div>
                  ) : null}

                  {state?.showPickupOtpScreen ? (
                    <div>
                      <OTPInput
                        inputStyle={{
                          fontFamily: "Raleway-Extra-Bold",
                          fontSize: "22px",
                          color: "#000",
                          border: "none",
                          background: "#F4F3F6",
                          borderRadius: "12px",
                          padding: "16px",
                        }}
                        inputType="number"
                        value={otp}
                        onChange={(otpValue) => {
                          setOtp(otpValue);
                          handleComplete(otpValue);
                        }}
                        numInputs={4}
                        renderSeparator={
                          <span style={{ padding: "5px" }}></span>
                        }
                        renderInput={(props) => <input {...props} />}
                      />
                      <p
                        style={{
                          fontFamily: "Raleway-Bold",
                          fontSize: "20px",
                          color: "#fff",
                          margin: 0,
                        }}
                      >
                        {job_details?.ship_status === "Accepted" ? (
                          <span>
                            Code à demander à <br />
                            l’envoyeur{" "}
                          </span>
                        ) : job_details?.ship_status === "Picked" ? (
                          <span>
                            {" "}
                            Code à demander au <br /> destinataire{" "}
                          </span>
                        ) : null}
                      </p>
                    </div>
                  ) : job_details?.ship_status === "Accepted" &&
                    job_details?.ship_pickup_otp ? (
                    <>
                      {selector?.profileInfo?.profile_data?.idenfy_verification === true &&
                      selector?.profileInfo?.profile_data?.idenfy_verification_status === "APPROVED" ? (
                        <div
                          style={{
                            background: "#fff",
                            borderRadius: "12px",
                            color: "#000",
                            fontSize: "16px",
                            fontFamily: "Raleway-Bold",
                            padding: 20,
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setState({ showPickupOtpScreen: true });
                          }}
                        >
                          Récuperer le colis
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              background: "lightgrey",
                              borderRadius: "12px",
                              color: "gray",
                              fontSize: "16px",
                              fontFamily: "Raleway-Bold",
                              padding: 20,
                              textAlign: "center",
                            }}
                          >
                            Récuperer le colis
                            <p style={{ fontSize: "12px" }}>
                              Veuillez vérifier votre compte via votre{" "}
                              <span
                                onClick={() => {
                                  navigate("/profile");
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: defaultLabelColors.main_orange,
                                }}
                              >
                                profil{" "}
                              </span>
                              avant de recevoir une annonce
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  ) : job_details?.ship_status === "Picked" ? (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "12px",
                        color: "#000",
                        fontSize: "16px",
                        fontFamily: "Raleway-Bold",
                        padding: 20,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setState({ showPickupOtpScreen: true });
                      }}
                    >
                     Livraison de colis
                    </div>
                  ) : job_details?.ship_status === "Deliver" ? (
                   
                    job_details?.feedback ? null :
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "16px",
                        color: "#000",
                        fontSize: "18px",
                        fontFamily: "Raleway-Bold",
                        padding: 20,
                        textAlign: "center",
                        cursor: "pointer",
                        width: "231px",
                      }}
                      onClick={() => {
                        setState({ feedbackDialog: true });
                      }}
                    >
                      Laisser un commentaire
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div
          className="main-container"
          style={{ background: "#fff", paddingTop: "90px" }}
        >
          <CustomFooter />
        </div>

        <DraggableDialog
          maxWidth={"sm"}
          open={state?.feedbackDialog}
          handleClose={() => {
            setState({
              feedbackDialog: false,
            });
          }}
          dialog_title={"Laisser un commentaire"}
          dialogBody={
            <DialogContent style={{}} dividers>
              <>
                <div
                  style={{
                    padding: 10,
                    borderBottom: "1px solid #000",
                    gap: 10,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "20%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: 120,
                          height: 100,
                          borderRadius: 100,
                          overflow: "hidden",
                          display: "flex",

                          boxShadow: 3,
                        }}
                      >
                        <img
                          src={
                            job_details?.user_details?.profile_pic ??
                            "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                          } // replace with your image path
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 60,
                        paddingLeft: 10,
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "22px",
                          fontWeight: 600,
                        }}
                      >
                        {job_details?.user_details?.cus_name ?? "Customer Name"}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Rating
                          initialValue={state?.ratingsCount}
                          allowHover={true}
                          size={40}
                          onClick={(value: any) => {
                            setState({ ratingsCount: value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ padding: 0 }}>
                  <p
                    style={{
                      fontFamily: "Raleway-Semi-Bold",
                      color: "#000",
                      marginBottom: 0,
                    }}
                  >
                    Écrivez votre commentaire
                  </p>
                  <InputField
                    placeHolder={"Exemple : Parfait ! Rapide et efficace"}
                    isMultiLine={true}
                    input_rows={3}
                    input_type={"text"}
                    input_value={state.feedback ?? ""}
                    onchange={onInputChange}
                    input_name={"feedback"}
                    input_style={{
                      background: "#F4F3F6",
                      width: "100%",
                      borderRadius: "16px",
                      border: "none",
                      fontSize: "16px",
                      margin:"0px",
                      marginBottom:10
                    }}
                  />

                  <CustomButton
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#fff",
                      fontFamily: "Raleway-Variable",
                      background: state?.feedback ? "#ee6931" : "lightgrey",
                      border: "none",
                      borderRadius: "12px",
                      padding: "10px 40px 10px 40px",
                      width: "100%",
                    }}
                    disable={state?.feedback ? false : true}
                    onClick={handleAddFeedback}
                    value={"Envoyer"}
                  />
                </div>
              </>
            </DialogContent>
          }
        />
      </div>
    </div>
  );
}

export default JobDetails;
