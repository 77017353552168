import PropTypes from "prop-types";
import LandingPage from "../views/LandingPage";
import LoginPage from "../views/Auth";
import SignUp from "../views/SignUp";
import Profile from "../views/Profile";
import Blogs from "../views/Blogs";
import SendParcel from "../views/SendParcel";
import SendPackage from "../views/SendPackage";
import TranspoprterUnColis from "../views/TransporterUnColis";
import MarketPlace from "../views/MarketPlace";
import DevenirTransporteur from "../views/DevenirTransporteur";
import TransporterJobs from "../views/TransporterUnColis/TransporterJobs";
import SenderInbox from "../views/SenderInbox.tsx";
import AllAnounces from "../views/AllAnounces";
import MyAnnounces from "../views/MyAnnounces";
import ProductDetails from "../views/MarketPlace/ProductDetails";
import AllProductByCateogry from "../views/MarketPlace/AllProductByCateogry";
import JobDetails from "../views/TransporterUnColis/JobDetails";
import AnounceDetails from "../views/MyAnnounces/AnounceDetails";
import { PrivateRoute } from "./AuthenticationRoutes";
import AddProductIndividual from "../views/MarketPlace/Individual/AddProductIndividual";
import AboutPage from "../views/AboutPage";

// ==============================|| MAIN ROUTING ||============================== //

export function MainAppRoutes({ classes }: any) {
  const MainRoutesBefore = {
    path: "/",
    children: [
      {
        path: "",
        element: <LandingPage classes={classes} />,
      },
      {
        path: "login",
        element: <LoginPage classes={classes} />,
      },
      {
        path: "sign-up",
        element: <SignUp classes={classes} />,
      },
      {
        path: "profile",
        element: <PrivateRoute element={<Profile classes={classes} />} />,
      },
      {
        path: "blogs",
        element: <Blogs classes={classes} />,
      },
      {
        path: "send-parcel",
        element: <SendParcel classes={classes} />,
      },
      {
        path: "send-package",
        element: <SendPackage classes={classes} />,
      },
      {
        path: "transporter-un-colis",
        element: <TranspoprterUnColis classes={classes} />,
      },
      {
        path: "devenir-transporter",
        element: <DevenirTransporteur classes={classes} />,
      },

      {
        path: "marketplace",
        element: <MarketPlace classes={classes} />,
      },

      {
        path: "my-jobs",
        element: (
          <PrivateRoute element={<TransporterJobs classes={classes} />} />
        ),
      },
      {
        path: "chat",
        element: <PrivateRoute element={<SenderInbox classes={classes} />} />,
      },
      {
        path: "announces",
        element: <AllAnounces classes={classes} />,
      },
      {
        path: "my-anounces",
        element: <PrivateRoute element={<MyAnnounces classes={classes} />} />,

      },
      {
        path: "about-us",
        element: <AboutPage classes={classes} />,

      },
      {
        path: "job/:id?",
        element: <PrivateRoute element={<JobDetails classes={classes} />} />,

      },
      {
        path: "anounce/:id?",
        element: <PrivateRoute element={<AnounceDetails classes={classes} />} />,

      },
      {
        path: "product/:id?",
        element: <ProductDetails classes={classes} />,
      },
      {
        path: "all-products/:id?",
        element: <AllProductByCateogry classes={classes} />,
      },
      {
        path: "add-product-individual",
        element: <AddProductIndividual classes={classes} />,
      },
    ],
  };

  return MainRoutesBefore;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
