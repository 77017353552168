/*
  Created By Muhammad Hassaan
  Date 15 April 2022
*/
import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/system";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      fontFamily: "Raleway-Variable",
    },
  },
});
export default function InputField(props: any) {
  const {
    startIcon,
    input_label,
    input_value,
    input_name,
    input_style,
    placeHolder,
    onchange,
    input_type,
    endIicon,
    input_rows,
    required,
    isMultiLine,
    readonly,
    isDisable,
    onKeyDown,

  } = props;

  return (
    <form
      style={{ width: "100%" }}
      autoComplete="off"
      onSubmit={(e) => e.preventDefault()}
    >
      {input_label && (
        <label
          className={classNames("Raleway-Variable")}
          style={{ fontSize: "18px", fontWeight: "600", display: "flex" }}
        >
          {input_label}
          {required ? <span style={{ color: "red" }}> *</span> : ""}
        </label>
      )}
      <CustomTextField
      disabled={isDisable}
      multiline={isMultiLine}
        type={input_type}
        rows={input_rows}
        placeholder={placeHolder}
        onChange={onchange}
        margin="none"
        name={input_name}
        style={input_style}
        onKeyDown={onKeyDown}
        id="outlined-start-adornment"
        sx={{
          '& .MuiInputBase-input::placeholder': {
            color: '#808080', // Change this to your desired color
            opacity: 1, // Ensure the opacity is 1 to fully apply the color
          },
          m: 1,
          border: "none",
          outline: "",
          fontFamily: "Raleway-Italic",
          "& .MuiInputBase-input": {
            fontFamily: "Raleway-Italic",
          },
          "& .MuiInputLabel-root": {
            fontFamily: "Raleway-Italic",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              fontFamily: "Raleway-Italic",
            },
          },
        }}
        value={input_value}
        InputProps={{
          readOnly:readonly,
          startAdornment: (
            
            <InputAdornment   position="start">{startIcon ?? ""}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{endIicon ?? ""}</InputAdornment>
          ),
        }}
      />
    </form>
  );
}
InputField.defaultProps = {
  startIcon: "",
  endIicon: "",
  input_label: "",
  input_value: "",
  input_name: "",
  placeHolder: "",
  onchange: {},
  input_type: "",
  input_style: {
    fontFamily: "Raleway-Variable",
  },
  input_rows: 1,
  isMultiLine:false,
  readonly:false,
  isDisable:false

};

InputField.propTypes = {
  startIcon: PropTypes.any,
  endIicon: PropTypes.any,
  input_label: PropTypes.string,
  input_value: PropTypes.any,
  input_name: PropTypes.any,
  input_style: PropTypes.any,
  placeHolder: PropTypes.any,
  onchange: PropTypes.func,
  input_type: PropTypes.string,
  input_rows: PropTypes.number,
  isMultiLine:PropTypes.bool,
  readonly:PropTypes.bool,
  isDisable:PropTypes.bool,
  onKeyDown:PropTypes.any

};
