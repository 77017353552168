import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import apiServices from "../services/RequestHandler";
import Toast from "../UiComponent/Toast/Toast";
import { firebaseConfig } from "./constants";
import { getDataFromLocalStorage } from "./LocalStore.util";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestPermission = async (): Promise<boolean> => {
  try {
    console.log("Requesting notification permission...");

    // Request permission from the user
    const isNotifAvailable = "Notification" in window;
    if (isNotifAvailable) {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("Notification permission granted.");
       return true; // Return true when granted
    } else {
      console.log("Notification permission not granted.");
      console.log("permission", permission);

      return false; // Return false when not granted
    }
  }else{
    return false
  }
  } catch (error) {
    console.error("Error requesting notification permission:", error);

    return false; // Return false in case of an error
  }
};

export const checkForPermission = async () => {
  try {
    const isNotifAvailable = "Notification" in window;
    if (isNotifAvailable) {
    const permissionGranted = await requestPermission();

    if (permissionGranted) {
      const token = await getDeviceToken();
      const user = getDataFromLocalStorage("user");
      if (token && user?.id) {
        await apiServices.postFromApi(
          "c2c/u-token",
          {
            device_token: token,
          },
          ""
        );
        console.log("Token saved successfully.");
      } else if (!token) {
        console.warn("Failed to retrieve device token.");
      }
    } else {
      console.log(
        "Notification permission denied. Please enable notifications in your browser settings to receive updates."
      );
      Toast.showInfo(
        "Activer les notifications à partir des paramètres du navigateur"
      );
    }
  }
  } catch (error) {
    console.error(
      "An error occurred while checking notification permission:",
      error
    );
  }
};

export const getDeviceToken = () => {
  console.log("Notification" in window);
  return getToken(messaging, {
    vapidKey:
      "BA0NbUKT40AdGUe30tGiZ4joEiTP59nuSi1xSEss1ylHtSyLkDC2SWA3M_gyJXwhEt8fUzowZgMDaeTNWOsCb8g",
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

interface Payload {
  notification: {
    title: string;
    image?: string; // Optional field
  };
}

const payload: Payload = {
  notification: {
    title: "Notification Title",
    image: "path_to_image", // Optional
  },
};

onMessage(messaging, (payload: any) => {
  navigator.serviceWorker
    .getRegistration("/firebase-cloud-messaging-push-scope")
    .then((registration: ServiceWorkerRegistration | undefined) => {
      // if (registration && payload.notification) {
      //   registration.showNotification(payload.notification.title, {
      //     body: "WASSUUPPPPP",
      //     icon: payload.notification.image || "", // Provide a fallback for image
      //   });
      // }
    });
});
